import {LiveAudioVisualizer} from "react-audio-visualize";
import {MutableRefObject} from "react";

interface AudioVisualizerProps {
    isRecording: boolean;
    mediaRecorderRef: MutableRefObject<MediaRecorder | null>;
    containerRef: MutableRefObject<HTMLDivElement | null>;
}

const CustomLiveAudioVisualizer = ({isRecording, mediaRecorderRef, containerRef}: AudioVisualizerProps) => {
    if (!isRecording || !mediaRecorderRef.current) return null;

    return (
        <div
            className="flex-1 grow w-full px-4 pt-1 max-w-full overflow-hidden">
            <LiveAudioVisualizer
                mediaRecorder={mediaRecorderRef.current}
                barWidth={4}
                barColor={"#cec4ff"}
                height={40}
                width={containerRef.current?.offsetWidth! + 28}
                gap={2}
                smoothingTimeConstant={0.6}
                fftSize={512}
            />
        </div>
    );
};

export default CustomLiveAudioVisualizer;
