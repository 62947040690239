import React, {useEffect, useMemo, useRef} from "react";
import TitleSection from "../../core/components/title-section";
import NumberFormField from "../../common/components/forms/number-form-field";
import Tooltip from "../../common/components/tooltip";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";
import SubscriptionMarker from "./ui/subscription-marker";
import CounterFormField from "../../core/components/forms/counter-form-field";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import CheckboxFormField from "../../common/components/forms/checkbox-form-field";
import {ReactComponent as LikeIcon} from "@assets/icons/like.svg";
import {ReactComponent as StatsIcon} from "@assets/icons/stats.svg";
import DiscountTypeSwitcher from "../../core/components/discount-type-switcher";
import SubscriptionEarningsStatsCard from "../../statistics/components/ui/subscription-earnings-stats-card";
import SubscriptionRenewableStatsCard from "../../statistics/components/ui/subscription-renewable-stats.card";
import WarningMessage from "../../common/components/warning-message";
import {useFormSubscriptionOffer} from "../hooks/use-form-subscription-offer.hook";
import FormSubscriptionActions from "./form-subscription-actions";
import {DateHelper} from "../../../utils/date-helper";
import {getNextEditableTime} from "../helpers/get-next-editable-time";
import ConfirmationUpdateSubscriptionModal from "./confirmation-update-subscription-modal";
import {useModal} from "../../common/hooks/use-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {FormProvider} from "react-hook-form";

const FormSubscriptionCreatorTools = () => {
    const {
        methods,
        calculateSubscriptionEarning,
        calculateDiscountPrice,
        isEnabledSubOnInit,
        handleSubmitOffer,
        offerSubscription,
        shortStatsSubscription,
        loading,
    } = useFormSubscriptionOffer();
    const values = methods.watch();
    const containerRef = useRef(null);
    const {closeModal} = useModal();

    const handleDiscountTypeChange = (newType: TypeDiscount) => {
        methods.setValue("yearlyDiscountType", newType,{shouldDirty: true});
        methods.setValue("yearlyDiscount", 0,{shouldDirty: true});
    };

    const canEdit = useMemo(() => {
            if (offerSubscription?.edited === offerSubscription?.created) return true;
            return offerSubscription?.edited
                ? DateHelper.hasElapsedTimePassed(offerSubscription?.edited!, 24, "hours")
                : true
        }, [offerSubscription]
    )

    const handleCancelSaveChanges = () => closeModal(ModalIds.CONFIRMATION_UPDATE_SUBSCRIPTION);
    const handleConfirmSaveChanges = async () => {
        try {
            await handleSubmitOffer();
            closeModal(ModalIds.CONFIRMATION_UPDATE_SUBSCRIPTION);
        } catch (error: any) {
            throw error;
        }
    }

    return (
        <>
            {isEnabledSubOnInit && (
                <section className="flex flex-col gap-3 mb-8">
                    <div className="flex flex-col mt-1 md:mt-0">
                        <TitleSection titleClassName="font-semibold" Icon={StatsIcon} title="Statistics"/>
                    </div>
                    <div className="flex flex-wrap sm:flex-nowrap gap-2">
                        <SubscriptionEarningsStatsCard
                            monthlySubscriptionEarnings={shortStatsSubscription?.dollarsFromRenewableMonthlySubscriptions ?? 0}
                            yearlySubscriptionEarnings={shortStatsSubscription?.dollarsFromRenewableYearlySubscriptions ?? 0}
                        />
                        <SubscriptionRenewableStatsCard
                            monthlySubscriptions={shortStatsSubscription?.renewableMonthlySubscriptions ?? 0}
                            yearlySubscriptions={shortStatsSubscription?.renewableYearlySubscriptions ?? 0}
                        />
                    </div>
                </section>
            )}

            <section>
                {!canEdit && (
                    <div className="mb-3">
                        <WarningMessage
                            disableToggleShow={true}
                            shownClassName="flex items-center !h-[32px] !w-full !bg-semantic-warning-pastel px-[0.625rem] !mx-0"
                            initStateShow={true}
                            text={getNextEditableTime(offerSubscription?.edited!, 24)}
                        />
                    </div>
                )}
                <FormProvider {...methods}>
                    <form ref={containerRef} className="relative flex flex-col mx-auto pb-4 pt-2 md:pt-0">
                        {!canEdit && (
                            <>
                                {/* Overlay */}
                                <div className="absolute inset-0 z-10 opacity-60 bg-white pointer-events-none"></div>
                                {/* Blokowanie interakcji */}
                                <div className="absolute inset-0 z-20 "></div>
                            </>
                        )}
                        <div className="flex gap-2 items-center justify-between flex-wrap">
                            <TitleSection
                                Icon={SubscriptionMarker}
                                title="Subscription"
                                description={
                                    <span className="text-special-gray text-xs leading-[0.9rem]">
                                            <Tooltip classNameIcon="relative top-[2px]"/>
                                            You can edit subscription data on time per 24h
                                        </span>
                                }
                            />
                            <div className="flex gap-2 items-center justify-between">
                                {isEnabledSubOnInit && (
                                    <WarningMessage
                                        shownClassName="!w-full"
                                        text={
                                            <span>
                                                Turning off will have <span className="underline">consequences</span> for fans
                                            </span>
                                        }
                                    />
                                )}
                                <SwitcherFormField disabled={loading || methods.formState.isSubmitting} name="subscriptionEnabled"/>
                            </div>
                        </div>

                        {values.subscriptionEnabled && (
                            <div className="flex flex-col gap-y-3 mx-auto w-full">
                                <div className="mt-4">
                                    <NumberFormField
                                        min={1}
                                        label="Monthly Price"
                                        placeholder="Enter price"
                                        name="monthlyPrice"
                                        showCoin={true}
                                        minFractionDigits={2}
                                        maxFractionDigits={2}
                                        isLoading={loading}
                                    />
                                    <div className="flex flex-wrap items-center mt-2 gap-1">
                                        {isEnabledSubOnInit && (
                                            <WarningMessage
                                                text="Remember! Increasing the price will cancel current fan subscriptions"/>
                                        )}
                                        <span className="text-special-gray text-xs leading-[0.9rem]">
                                            <Tooltip classNameIcon="relative top-[2px]"/>
                                            {`You'll earn $${calculateSubscriptionEarning()}`}
                                        </span>
                                    </div>
                                </div>

                                <CheckboxFormField disabled={loading || methods.formState.isSubmitting} name="yearlyDiscountEnabled"
                                                   label="Activate yearly discount"/>

                                {values.yearlyDiscountEnabled && (
                                    <div>
                                        <NumberFormField
                                            min={0}
                                            label="Yearly discount"
                                            placeholder="Enter discount"
                                            name="yearlyDiscount"
                                            isLoading={loading}
                                            minFractionDigits={methods.getValues("yearlyDiscountType") === TypeDiscount.PRICE ? 2 : 0}
                                            maxFractionDigits={methods.getValues("yearlyDiscountType") === TypeDiscount.PRICE ? 2 : 0}
                                            suffix={
                                                <DiscountTypeSwitcher
                                                    disabled={methods.formState.isSubmitting}
                                                    value={values.yearlyDiscountType!}
                                                    onChange={handleDiscountTypeChange}/>
                                            }
                                        />
                                        <div className="flex flex-wrap items-center mt-2 gap-1">
                                            {isEnabledSubOnInit && (
                                                <WarningMessage
                                                    text="Remember! Increasing the price will cancel the current yearly fan subscriptions"/>
                                            )}
                                            <span className="text-special-gray text-xs leading-[0.9rem]">
                                                <Tooltip classNameIcon="relative top-[2px]"/>
                                                {`Price after discount: ${calculateDiscountPrice()}`}
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <div
                                    className="w-[calc(100%+2rem)] -translate-x-[1rem] md:-translate-x-0 md:w-full mb-2 border-b border-special-gray"></div>

                                <div className="flex flex-col gap-y-2 mx-auto w-full">
                                    <div className="flex justify-between flex-wrap items-center">
                                        <TitleSection
                                            Icon={LikeIcon}
                                            title="Subscribers free messages"
                                            className="mb-3 w-fit"
                                            description="Provide your subscribers with a set amount of free communication options included in their subscription."
                                        />
                                        {isEnabledSubOnInit && (
                                            <WarningMessage
                                                shownClassName="mb-1"
                                                text="Remember! Decreasing the quantity below will cancel the current fan subscriptions"
                                            />
                                        )}
                                    </div>

                                    <CounterFormField
                                        disabled={loading}
                                        name="textFreeMessagesCount"
                                        label="Text message block"
                                        min={0}
                                        max={999}
                                    />
                                    {/*<CounterFormField*/}
                                    {/*    disabled={loading}*/}
                                    {/*    name="voiceFreeMessagesCount"*/}
                                    {/*    label="Voice messages / min"*/}
                                    {/*    min={0}*/}
                                    {/*    max={999}*/}
                                    {/*/>*/}
                                    {/*<CounterFormField*/}
                                    {/*    disabled={loading}*/}
                                    {/*    name="videoFreeMessagesCount"*/}
                                    {/*    label="Video messages / min"*/}
                                    {/*    min={0}*/}
                                    {/*    max={999}*/}
                                    {/*/>*/}
                                </div>
                            </div>
                        )}
                        <div className="mb-3">
                            <FormSubscriptionActions parentRef={containerRef}/>
                            <ConfirmationUpdateSubscriptionModal
                                onConfirm={handleConfirmSaveChanges}
                                onCancel={handleCancelSaveChanges}
                            />
                        </div>
                    </form>
                </FormProvider>
            </section>
        </>
    );
};

export default FormSubscriptionCreatorTools;
