import React, {createContext, useEffect, useMemo, useState} from "react";
import {DefaultDigitalProductCategory} from "../../core/models/enums/digital-product-category.enum";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {filterProductsByCategory} from "../utils/filter-products-by-category";

interface DigitalProductFilterContextType {
    selectedCategory: DefaultDigitalProductCategory | string;
    setSelectedCategory: (category: DefaultDigitalProductCategory | string) => void;
    queryTyped: string;
    setQueryTyped: (queryTyped: string) => void;
    filteredProducts: DigitalProduct[];
}

interface ProductFilterProviderProps {
    children: React.ReactNode;
    products: DigitalProduct[];
}

export const DigitalProductFilterContext = createContext<DigitalProductFilterContextType | undefined>(undefined);

export const DigitalProductFilterProvider: React.FC<ProductFilterProviderProps> = ({children, products}) => {
    const [selectedCategory, setSelectedCategory] = useState<DefaultDigitalProductCategory | string>(DefaultDigitalProductCategory.ALL);
    const [queryTyped, setQueryTyped] = useState<string>("");

    const filteredProducts: DigitalProduct[] = useMemo(() => {
        const filteredByCategory = filterProductsByCategory(products, selectedCategory as any);

        return queryTyped.trim().length === 0
            ? filteredByCategory
            : filteredByCategory.filter(product =>
                product.name.toLowerCase().includes(queryTyped.toLowerCase())
            );
    }, [products, selectedCategory, queryTyped]);

    return (
        <DigitalProductFilterContext.Provider value={{
            selectedCategory,
            setSelectedCategory,
            filteredProducts,
            queryTyped,
            setQueryTyped,
        }}>
            {children}
        </DigitalProductFilterContext.Provider>
    );
};
