import {LocalStorageKeys} from "../features/common/models/enums/local-storage-keys.enum";
import LocalStorageService from "../features/common/services/local-storage.service";

class RequestedNotificationTokenService {
    private static readonly STORAGE_KEY: LocalStorageKeys = LocalStorageKeys.MAP_NOTIFICATION_TOKEN_REQUESTED;

    static getNotificationRequested(userId: string): boolean {
        const storedData = LocalStorageService.get(this.STORAGE_KEY);
        if (!storedData) return false;

        try {
            const parsedData: Record<string, boolean> = JSON.parse(storedData);
            return parsedData[userId] || false;
        } catch (error) {
            console.error("Error parsing notification token storage:", error);
            return false;
        }
    }

    static setNotificationRequested(userId: string): void {
        const storedData = LocalStorageService.get(this.STORAGE_KEY);
        let parsedData: Record<string, boolean> = {};

        if (storedData) {
            try {
                parsedData = JSON.parse(storedData);
            } catch (error) {
                console.error("Error parsing notification token storage:", error);
            }
        }

        parsedData[userId] = true;
        LocalStorageService.save(this.STORAGE_KEY, JSON.stringify(parsedData));
    }

    static removeNotificationRequested(userId: string): void {
        const storedData = LocalStorageService.get(this.STORAGE_KEY);
        if (!storedData) return;

        try {
            const parsedData: Record<string, boolean> = JSON.parse(storedData);
            delete parsedData[userId];
            LocalStorageService.save(this.STORAGE_KEY, JSON.stringify(parsedData));
        } catch (error) {
            console.error("Error parsing notification token storage:", error);
        }
    }

    static clearAllRequests(): void {
        LocalStorageService.remove(this.STORAGE_KEY);
    }
}

export default RequestedNotificationTokenService;
