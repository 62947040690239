import React from "react";
import {TypeDiscount} from "../models/enums/type-discount.enum";
import {classNames} from "../../../utils/class-names";
import Coin from "./ui/coin";

interface DiscountTypeSwitcherProps {
    value: TypeDiscount;
    onChange: (type: TypeDiscount) => void;
    disabled?: boolean;
}

const DiscountTypeSwitcher = (props: DiscountTypeSwitcherProps) => {
    const {
        value,
        onChange,
        disabled = false,
    } = props;
    return (
        <div
            className={classNames(
                "flex items-center justify-between w-fit rounded-2xl p-1 gap-1 bg-gray-light",
                disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
            )}
        >
            <button
                type="button"
                className={classNames(
                    "p-2 w-8 h-8 rounded-xl text-black text-sm font-semibold text-[13px] transition-colors",
                    value === TypeDiscount.PERCENTAGE
                        ? "bg-white shadow-discount"
                        : "bg-transparent"
                )}
                onClick={() => !disabled && onChange(TypeDiscount.PERCENTAGE)}
                disabled={disabled}
            >
                %
            </button>
            <button
                type="button"
                className={classNames(
                    "flex items-center justify-center p-2 w-8 h-8 rounded-xl text-black text-sm font-medium transition-colors",
                    value === TypeDiscount.PRICE
                        ? "bg-white shadow-discount"
                        : "bg-transparent"
                )}
                onClick={() => !disabled && onChange(TypeDiscount.PRICE)}
                disabled={disabled}
            >
                <Coin className="min-w-[20px] min-h-[20px]"/>
            </button>
        </div>
    );
};

export default DiscountTypeSwitcher;
