import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {DefaultDigitalProductCategory} from "../../core/models/enums/digital-product-category.enum";

export function mapCategoriesWithBadges(
    categories: DigitalProductCategory[],
    products: DigitalProduct[],
    queryTyped: string = "",
): DigitalProductCategory[] {
    return categories.map((category) => {
        let counter: number = 0;
        switch (category.value) {
            case DefaultDigitalProductCategory.ALL:
                counter = products
                    .filter(product => product.name.toLowerCase().includes(queryTyped.toLowerCase()))
                    .filter((product) => !product.deleted)
                    .length;
                break;
            case DefaultDigitalProductCategory.DELETED:
                counter = products
                    .filter(product => product.name.toLowerCase().includes(queryTyped.toLowerCase()))
                    .filter((product) => product.deleted)
                    .length;
                break;
            case DefaultDigitalProductCategory.UNCATEGORIZED:
                counter = products
                    .filter(product => product.name.toLowerCase().includes(queryTyped.toLowerCase()))
                    .filter((product) => !product.deleted && !product.category.id)
                    .length;
                break;
            default:
                counter = products
                    .filter(product => product.name.toLowerCase().includes(queryTyped.toLowerCase()))
                    .filter(product => product.category.id === category.value && !product.deleted)
                    .length
                break;
        }

        return {
            ...category,
            badge: counter,
        };
    });
}
