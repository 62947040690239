import WrapperCard from "../../common/components/wrapper-card";
import SubscriptionMarker from "./ui/subscription-marker";
import SubscribeButton from "./ui/subscribe-button";
import Coin from "../../core/components/ui/coin";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {SubscriptionModalData} from "../../purchases/models/interfaces/subscription-modal-data.interface";
import {GetSubscriptionSellerData} from "../../core/models/interfaces/get-subscription-seller-data.interface";
import YDollarsConversionService from "../../purchases/services/ydollars-conversion.service";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";
import React, {useContext, useEffect} from "react";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import useRequiredFollow from "../../core/hooks/use-required-follow";
import {DataFollowRequiredModal} from "../../core/components/follow-required-modal";
import {useModal} from "../../common/hooks/use-modal";
import {UserProfileContext, UserProfileContextProps} from "../../profile/contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";

interface SubscriptionCardProps {
    subscription: GetSubscriptionSellerData,
    sellerUsername: string,
    sellerFullName: string,
    creatorId: string,
    isDisabled?: boolean,
}

const BuySubscriptionCard = (props: SubscriptionCardProps) => {
    const {
        subscription,
        sellerUsername,
        sellerFullName,
        isDisabled = false
    } = props
    const { perPeriod, total } = YDollarsConversionService.calculateDiscountedPrice(
        subscription.priceMonthly,
        subscription.yearlyDiscountActive
            ? (subscription.discountType === TypeDiscount.PERCENTAGE
                ? (subscription.yearlyDiscountPercentage ?? 0)
                : (subscription.yearlyDiscountPrice ?? 0))
            : 0,
        subscription.discountType ?? TypeDiscount.PRICE,
        12
    );

    const {changeStateFollowed} = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const {openModal} = useModal();
    const {showModal: showSubscriptionModal} = useModalWithData<SubscriptionModalData>(ModalIds.PURCHASE_SUBSCRIPTION);
    const {showModal: showFollowModal} = useModalWithData<DataFollowRequiredModal>(ModalIds.FOLLOW_REQUIRED);
    const checkLogin = useRequiredLogin({onLoginRequired: openModal.bind(this, ModalIds.LOGIN_REQUIRED)});
    const checkFollow = useRequiredFollow(props.creatorId, {
        onFollowRequired: showFollowModal.bind(this, {
            creatorId: props.creatorId,
            onSuccessFollow: () => changeStateFollowed(true),
            creatorUsername: props.sellerFullName,
        }),
    });

    const openSubscriptionModalHandle = async (event?: React.MouseEvent) => {
        if (isDisabled) return;

        const isLogged: boolean = checkLogin();
        const isFollowed: boolean = await checkFollow(event);
        if (!isLogged || !isFollowed) return;

        showSubscriptionModal({
            ...subscription,
            sellerUsername: sellerUsername,
            sellerFullName: sellerFullName,
        })
    }

    return (
        <WrapperCard className="bg-secondary-pastel w-full border-0 flex gap-3">
            <div className="flex flex-col gap-[0.625rem] w-full">
                <header className="flex gap-2 items-center">
                    <SubscriptionMarker size="small"/>
                    <h3 className="uppercase font-semibold text-[0.625rem] leading-[0.75rem] tracking-[1px]">
                        Subscription
                    </h3>
                </header>
                <div className="flex flex-col">
                    <div className="flex flex-wrap gap-1 items-center">
                        <span className="flex flex-wrap gap-1 font-bold text-[1.375rem] items-center">
                            {perPeriod.yDollars}
                            <Coin/>
                        </span>
                        <span className="font-medium text-special-gray leading-[0.9rem] text-xs">
                            / month {subscription.yearlyDiscountActive && '(pay yearly)'}
                        </span>
                    </div>
                    {subscription.yearlyDiscountActive && (
                        <span className="font-medium text-special-gray leading-[0.975rem] text-xs">
                            Or {subscription.priceMonthly} $ / month
                        </span>
                    )}
                </div>
            </div>
            <div className="flex items-center justify-end text-right">
                <SubscribeButton onClick={openSubscriptionModalHandle}/>
            </div>
        </WrapperCard>
    )
}

export default BuySubscriptionCard
