export enum LocalStorageKeys {
    AUTHENTICATION = 'authentication',
    EMAIL_OR_USERNAME_RESET_PASSWORD = "email_or_username_reset_password",
    TOKEN_RESET_PASSWORD = "token_reset_password",
    LAST_STEP_ONBOARDING = "last_step_onboarding",
    ACTIVE_CHAT_DATA = "active_chat_data",
    PWA_IS_INSTALLED = "pwa_is_installed",
    TIMER_MAP = "timer_map",
    ALIAS_NAME_EDITED_PRODUCT = "alias_name_edited-product",
    REGISTRATION_SELECTED_TYPE_ACC = "registration_selected_type_acc",
    MAP_NOTIFICATION_TOKEN_REQUESTED = "map_notification_token_requested",
}
