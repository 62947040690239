import {CurrencyDollarIcon} from "@heroicons/react/24/solid";
import {useModal} from "../../../common/hooks/use-modal";
import {ModalIds} from "../../../common/models/enums/modal-ids.enum";
import useAuth from "../../../auth/hooks/use-auth";

const FreeDollar = () => {
    const {openModal} = useModal();
    const {currentUser} = useAuth();

    if (currentUser && currentUser.gotFreeDollarsRegistration) return null

    return (
        <div onClick={() => openModal(ModalIds.GET_DOLLAR_FOR_ADDITIONAL_ACCOUNT_INFO)}
             className="fixed right-0 top-1/2 -translate-y-1/2 bg-primary-pastel text-primary-darken px-4 py-3 rounded-l-xl font-semibold shadow-md flex items-center text-sm z-50 transition-all duration-300 ease-in-out cursor-pointer hover:pr-5 hover:bg-primary hover:text-white">
            <span className="mr-2"><CurrencyDollarIcon className="h-7 w-7"/></span>
            Free
        </div>
    )
}

export default FreeDollar
