import { UseFormReturn } from "react-hook-form";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import { AppFanFormValues, NotificationType } from "../views/notifications-view";

interface NotificationCardProps {
    icon: React.ElementType,
    type: string,
    name: string,
    onToggle: (checked: boolean, name? : any) => void;
    methods: UseFormReturn<any, any, undefined>
}

const NotificationsFanCard = ({methods, onToggle, type, icon: Icon, name}: NotificationCardProps) => {

    return (
        <>
        <form>
            <div className="md:w-[300px]">
            
                <div className="grid gap-y-4">
                    <div className="relative flex justify-between text-base font-bold gap-x-4 ">
                        
                        <div className="flex mb-4">
                            <Icon className="w-6 h-6 mr-2"/>
                            <span className='text-lg'>{name} </span>
                        </div>

                        <div className="w-fit">
                            <SwitcherFormField name={"isNotifications" + type} disabled={false} className="right-1 " onToggle={onToggle}/>
                        </div>
                    </div>

                    <div className="relative flex justify-between gap-x-4">
                        <span className={`${
                                methods.watch("isNotifications" + type) && methods.watch("isTextMessages" + type) ? 
                                    ""
                                    :
                                    "text-gray"
                            } 
                            
                            `}>New text message</span>
                        <div className="w-fit">
                            <SwitcherFormField name={"isTextMessages" + type} disabled={!methods.watch("isNotifications" + type)} className="right-1 " onToggle={onToggle}/>
                        </div>
                    </div>

                    <div className="relative flex justify-between gap-x-4">
                        <span className={` ${
                                methods.watch("isNotifications" + type) && methods.watch("isVoiceMessages" + type) ? 
                                    ""
                                    :
                                    "text-gray"
                            } 
                            
                            `}>New voice message</span>
                        <div className="w-fit ">
                            <SwitcherFormField name={"isVoiceMessages" + type} disabled={!methods.watch("isNotifications" + type)} className="right-1 " onToggle={onToggle} />
                        </div>
                    </div>

                    <div className="relative flex justify-between gap-x-4">
                        <span className={` ${
                                methods.watch("isNotifications" + type) && methods.watch("isVideoMessages" + type) ? 
                                    ""
                                    :
                                    "text-gray"
                            } 
                            
                            `}>New video message</span>
                        <div className="w-fit ">
                            <SwitcherFormField name={"isVideoMessages" + type} disabled={!methods.watch("isNotifications" + type)} className="right-1 " onToggle={onToggle}/>
                        </div>
                    </div>

                    <div className="relative flex justify-between gap-x-4">
                        <span className={` ${
                                methods.watch("isNotifications" + type) && methods.watch("isYCredits" + type) ? 
                                    ""
                                    :
                                    "text-gray"
                            } 
                            
                            `}>Small amount of $</span>
                        <div className="w-fit">
                            <SwitcherFormField name={"isYCredits" + type} disabled={!methods.watch("isNotifications" + type)} className="right-1 " onToggle={onToggle}/>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        </>
    )
}

export default NotificationsFanCard;
