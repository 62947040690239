import ApiService from "../../../axios/api.service";
import {
    PostCreateDigitalProductCategoryBodyRequest
} from "../models/interfaces/post-digital-product-category-body-request.interface";
import {GetAllDigitalCategories} from "../models/interfaces/get-all-digital-categories.interface";
import {PutEditDigitalProductCategory} from "../models/interfaces/put-edit-digital-product-category.interface";
import {PutChangeOrdinalNumberRequestBody} from "../models/interfaces/put-change-ordinal-number-request-body.interface";
import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";

class DigitalProductCategoryApiService extends ApiService {

    public async createCategory(data: PostCreateDigitalProductCategoryBodyRequest): Promise<string> {
        return await this.post<string>("/category", data, {
            successMessage: "Category created successfully!",
            errorMessage: "Error creating category",
        }).catch((error) => {
            throw error;
        });
    }

    public async getAllCategories(): Promise<GetAllDigitalCategories> {
        return await this.get<GetAllDigitalCategories>("/category/all", {
            errorMessage: "Error loading categories",
            skipDefault404Handling: true,
        }).catch((error) => {
            throw error;
        });
    }

    public async editCategory(data: PutEditDigitalProductCategory): Promise<void> {
        return await this.put<void>("/category", data, {
            successMessage: "Category updated successfully!",
            errorMessage: "Error updating category",
        }).catch((error) => {
            throw error;
        });
    }

    public async deleteCategory(id: string): Promise<void> {
        return await this.delete<void>(`/category?id=${id}`, {
            successMessage: "Category deleted successfully!",
            errorMessage: "Error deleting category",
        }).catch((error) => {
            throw error;
        });
    }

    public async changeOrdinalNumber(data: PutChangeOrdinalNumberRequestBody): Promise<void> {
        return await this.put<void>(`/category/changeOrder`, data, {
            errorMessage: "Error reorder category",
        }).catch((error) => {
            throw error;
        });
    }

    public async editAll(data: Array<DigitalProductCategory>): Promise<void> {
        return await this.put<void>(`/category/editAll`, {categories: data}, {
            successMessage: "Categories has been updated!",
            errorMessage: "Error reorder category",
        }).catch((error) => {
            throw error;
        });
    }

    public async initCategories(): Promise<void> {
        return await this.get<void>('/category/init', {})
        .catch((error) => {
            throw error;
        })
    }
}

export default new DigitalProductCategoryApiService();
