import React from "react";
import WrapperCard from "../../common/components/wrapper-card";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import Coin from "../../core/components/ui/coin";
import {PhotoIcon} from "@heroicons/react/24/outline";
import {ReactComponent as PinFilledIcon} from "@assets/icons/pin-filled.svg";
import {classNames} from "../../../utils/class-names";
import {useNavigate} from "react-router-dom";
import {Base64Prefix} from "../../common/constats/base64-prefix";
import BadgeDaysToDelete from "./ui/badge-days-to-delete";
import {DateHelper} from "../../../utils/date-helper";

interface CardDigitalProductProps {
    product: DigitalProduct;
    isPinned?: boolean;
    isDisabled?: boolean;
}

const CardDigitalProduct = (props: CardDigitalProductProps) => {
    const {
        product,
        isPinned = false,
        isDisabled = false,
    } = props;
    const {name, price} = product;
    const navigate = useNavigate();
    const goToEditProductHandler = (aliasName: string) => !isDisabled && navigate(`../edit-product/${aliasName}`)
    const labelToDeleteProduct: string = product.deleted ? DateHelper.formatDifferenceInLargestUnit(new Date(), new Date(product.deleted), "days") : "";
    const differenceDays: number = product.deleted ? DateHelper.differenceInDays(new Date(), new Date(product.deleted)) : 0;

    return (
        <WrapperCard
            onClick={goToEditProductHandler.bind(this, product.aliasName)}
            className={classNames(
                "w-full flex justify-between gap-3 !p-[7px] border rounded-2xl shadow-sm relative ",
                "overflow-ellipsis hover:shadow-md hover:shadow-primary-pastel focus:shadow-primary-pastel",
                isPinned && "!border-black",
                !isDisabled && "cursor-pointer"
            )}>
            {isPinned && (
                <div
                    className={classNames("absolute top-[-1px] right-[-1px] bg-black rounded-bl-xl rounded-tr-xl p-2",
                        !isDisabled && "cursor-pointer")}>
                    <PinFilledIcon className="w-[10px] h-[10px] text-white"/>
                </div>
            )}

            <div className='flex items-center gap-3 truncate w-full'>
                <div
                    className='max-w-[72px] min-w-[72px] w-[72px] h-[72px] flex-grow border border-special-gray rounded-2xl bg-gray-light flex justify-center items-center'>
                    {product.productPicture ?
                        <img
                            src={`${Base64Prefix}${product.productPicture}`}
                            alt="Avatar"
                            className="w-full h-full object-cover rounded-2xl"
                        />
                        : <PhotoIcon className='w-5 h-5 text-gray'/>}
                </div>
                <div className="flex flex-col justify-center truncate mt-1 w-full">
                    <div className="text-primary  text-[10px] font-semibold uppercase ">
                        {product.category.name ?? "uncategorized"}
                    </div>
                    <span className="text-primary font-semibold text-[0.625rem] uppercase tracking-wide truncate">
                        {/*{DigitalProductCategoryService.getCategoryName(type)}*/}
                    </span>
                    <h3 className="font-semibold text-base max-w-full truncate">{name}</h3>
                    <div className="flex items-center gap-1 text-lg font-bold text-gray-700">
                        {price}
                        <Coin/>
                    </div>
                </div>
                <div className="h-full">
                    {product.deleted && <BadgeDaysToDelete label={labelToDeleteProduct} differenceDays={differenceDays}/>}
                </div>
            </div>
        </WrapperCard>
    );
};

export default CardDigitalProduct;
