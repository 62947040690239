import React from "react";
import {PurchasedSubscription} from "../../../core/models/interfaces/purchased-subscription.interface";
import Avatar from "../../../chat/components/ui/avatar";
import {ShortDataImage} from "../../../common/models/interfaces/short-data-image.interface";
import Base64Helper from "../../../../utils/base64-helper";
import {ReactComponent as ChatsIcon} from "@assets/icons/chats.svg";
import {ReactComponent as VideoIcon} from "@assets/icons/video.svg";
import {ReactComponent as AudioIcon} from "@assets/icons/audio.svg";
import {useNavigate} from "react-router-dom";

const PurchasedSubscriptionCardUI = ({subscription}: { subscription: PurchasedSubscription }) => {
    const fullName: string = subscription.firstname + " " + subscription.lastname
    const avatar: ShortDataImage = {
        name: fullName,
        contentBase64: Base64Helper.addBase64Prefix(subscription.avatar)
    }
    const navigate = useNavigate();
    const handleGoToProfileCC = async () => navigate(`/${subscription.username}`)

    return (
        <section className="rounded-md gap-3 flex items-center">
            <div onClick={handleGoToProfileCC} className="cursor-pointer">
                <Avatar image={avatar} isOnline={subscription.isOnline}/>
            </div>
            <div>
                <h3 className="text-base font-semibold text-black">{fullName}</h3>
                <div className="font-medium text-xs text-special-gray flex gap-2">
                    <span className="flex items-center gap-1">
                        <ChatsIcon className="inline w-[16px] h-[15px] min-w-[16px]"/>
                        {subscription.textMessageBlockUsed} / {subscription.textMessageBlock}
                    </span>
                    <span className="flex items-center gap-1">
                        <AudioIcon className="inline w-[15.79px] h-[14px] min-w-[15.79px]"/>
                        {subscription.voiceMessagesUsed} / {subscription.voiceMessages}
                    </span>
                    <span className="flex items-center gap-1">
                        <VideoIcon className="inline w-[14px] h-[10.18px] min-w-[14px]"/>
                        {subscription.videoMessagesUsed} / {subscription.videoMessages}
                    </span>
                </div>
            </div>
        </section>
    );
};

export default PurchasedSubscriptionCardUI;
