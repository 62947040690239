import {createContext, ReactNode, useEffect, useState} from 'react';
import {mapTransactionsToSections} from "../helpers/map-transation-to-sections";
import {TransactionSection} from "../models/interfaces/transaction-section.interface";
import {StripePrice} from "../../core/models/interfaces/stripe-price.interface";
import {forkJoin} from 'rxjs';
import {useWalletApi} from "../../core/hooks/use-wallet-api";
import {mergeTransactionSections} from "../helpers/merge-transaction-sections";
import {useAnalytics} from "../../core/hooks/use-analytics";
import {useStripeApi} from "../../core/hooks/use-stripe-api";

interface WalletContextProps {
    transactions: TransactionSection[];
    balance: number | null;
    prices: StripePrice[];
    error: string | null;
    loading: boolean;
    initLoading: boolean;
    showMoreTransactions: boolean;
    fetchBalance: () => Promise<void>;
    fetchPrices: () => Promise<void>;
    fetchTransactions: (page: number) => Promise<void>;
    loadMoreTransactions: (page: number) => Promise<void>;
}

interface WalletProviderProps {
    children?: ReactNode;
    initFetch?: boolean;
}

export const WalletContext = createContext<WalletContextProps | undefined>(undefined);

export const WalletProvider = ({children, initFetch = false}: WalletProviderProps) => {
    const [transactions, setTransactions] = useState<TransactionSection[]>([]);
    const [showMoreTransactions, setShowMoreTransactions] = useState<boolean>(true);
    const [balance, setBalance] = useState<number | null>(null);
    const [prices, setPrices] = useState<StripePrice[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [initLoading, setInitLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const {sendYCreditsListViewInfo} = useAnalytics();
    const {getUserBalance, getUserTransactions} = useWalletApi();
    const {getPrices} = useStripeApi();

    const fetchBalance = async () => {
        setLoading(true);
        try {
            const balanceResponse = await getUserBalance();
            setBalance(balanceResponse);
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchPrices = async () => {
        setLoading(true);
        try {
            const pricesResponse = await getPrices();
            setPrices(pricesResponse);
            sendYCreditsListViewInfo(pricesResponse);
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchTransactions = async (page: number) => {
        setLoading(true);
        try {
            const transactionsResponse = await getUserTransactions(page);
            const groupedTransactions = mapTransactionsToSections(transactionsResponse);
            setTransactions(groupedTransactions);
            //TODO change this "5"
            if (transactionsResponse.length < 5) setShowMoreTransactions(false)
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const loadMoreTransactions = async (page: number) => {
        setLoading(true);
        try {
            if (showMoreTransactions) {
                const transactionsResponse = await getUserTransactions(page);
                const groupedTransactions = mapTransactionsToSections(transactionsResponse);
                if (groupedTransactions.length) setTransactions(prev => mergeTransactionSections(prev, groupedTransactions));

                //TODO change this "5"
                if (transactionsResponse.length < 5) setShowMoreTransactions(false)
            }
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const loadData = async () => {
        setInitLoading(true);
        setError(null);
        try {
            await forkJoin({
                balance: fetchBalance(),
                prices: fetchPrices(),
                transactions: fetchTransactions(0)
            }).toPromise();
        } catch (err: any) {
            setError(err.message);
        } finally {
            setInitLoading(false);
        }
    };

    useEffect(() => {
        if (initFetch) {
            loadData();
        }
    }, [initFetch]);

    return (
        <WalletContext.Provider
            value={{
                transactions,
                balance,
                prices,
                error,
                loading,
                initLoading,
                showMoreTransactions,
                fetchBalance,
                fetchPrices,
                fetchTransactions,
                loadMoreTransactions
            }}
        >
            {children}
        </WalletContext.Provider>
    );
};
