import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import {orderTypes} from "../constants/order-types";
import {Orders} from "../models/enums/order-settings.enum";

interface SelectorOrderTypeProps {
    selectedType: Orders,
    onSelect: (role: Orders) => void;
}

const SelectorOrderType = (props: SelectorOrderTypeProps) => {
    const {selectedType, onSelect} = props;

    const handleOnSelect = (role: Orders) => {
        onSelect(role);
    }

    return (
        <div className='flex flex-col gap-3'>
            <header className='font-bold leading-[1.65rem] text-[1.375rem]'>Order</header>
            <div className='w-full p-1 flex bg-gray-light max-h-[40px] rounded-2xl h-[40px] gap-1'>
                {orderTypes.map(type =>
                    <AppButton
                        key={type.value}
                        type='button'
                        onClick={() => handleOnSelect(type.value)}
                        className={classNames(
                            'w-full border-none h-full !p-0 text-special-gray !text-[0.8135rem] focus:outline-none',
                            (selectedType === type.value) && "bg-white font-semibold !text-black drop-shadow-xl"
                        )}
                        label={type.label}/>
                )}
            </div>
        </div>
    )
}

export default SelectorOrderType

