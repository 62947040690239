import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import React, {useContext, useState} from "react";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {CancelSubscriptionModalData} from "../models/interfaces/cancel-subscription-modal-data.interface";
import {DateHelper} from "../../../utils/date-helper";
import Avatar from "../../chat/components/ui/avatar";
import BenefitsSubscriptionCard from "./ui/benefits-subscription-card";
import Tooltip from "../../common/components/tooltip";
import {ReactComponent as BlockIcon} from "@assets/icons/block.svg";
import AppButton from "../../common/components/app-button";
import {PurchasesSubscriptionsContext} from "../../purchases/contexts/purchases-subscriptions-context";
import Base64Helper from "../../../utils/base64-helper";

const CancelSubscriptionModal = () => {
    const {data, hideModal} = useModalWithData<CancelSubscriptionModalData>(ModalIds.CANCEL_SUBSCRIPTION);
    const subscription: CancelSubscriptionModalData | null = data;
    const [error, setError] = useState<string | null>(null);
    const sellerFullName: string = subscription?.firstname + " " + subscription?.lastname;
    const activeToFormatted: string = DateHelper.formatDate(new Date(subscription?.activeTo ?? 0), "DD.MM");

    const context = useContext(PurchasesSubscriptionsContext);
    if (!context) return null;
    const {cancelSubscriptionHandler} = context;

    const handleCancelSubscription = async () => {
        if (subscription) {
            await cancelSubscriptionHandler(subscription.id, sellerFullName);
            hideModal();
        }
    };

    return (
        <AppModal
            key={ModalIds.CANCEL_SUBSCRIPTION}
            id={ModalIds.CANCEL_SUBSCRIPTION}
            disableScroll={true}
            zIndex={40}
            classModal="md:min-w-[524px]"
        >
            <div className="flex flex-col w-full gap-3 bg-white">
                <div className="mb-4">
                    <Avatar size="md" image={{name: sellerFullName, contentBase64: Base64Helper.addBase64Prefix(subscription?.avatar) ?? ""}}/>
                </div>

                <div className="text-[0.625rem] text-primary font-semibold uppercase tracking-wider flex items-center">
                    <BlockIcon className="w-[16px] h-[16px] min-w-[16px] inline mr-2 "/>
                    <span>Cancel Subscription</span>
                </div>

                <h2 className="text-[1.375rem] leading-[1.65rem] font-bold mb-2 max-w-[420px]">
                    Do you really want to cancel subscription for {sellerFullName}?
                </h2>

                <div className="space-y-3">
                    <p className="text-sm text-special-gray pr-10 inline-block max-w-[280px]">
                        If you cancel subscription you’ll lose free messages & calls:
                    </p>
                    <BenefitsSubscriptionCard
                        freeMessageBlock={subscription?.textMessageBlock || 0}
                        freeVoiceCall={subscription?.voiceMessages || 0}
                        freeVideoCall={subscription?.videoMessages || 0}
                    />
                </div>

                <div className="text-xs text-special-gray leading-[100%] flex">
                    <Tooltip/>
                    You still can use the current free messages & calls until {activeToFormatted} under your
                    active subscription
                </div>

                <div className="flex justify-center mt-4">
                    <AppButton
                        className="bg-primary text-white w-full h-[40px] rounded-2xl font-semibold hover:bg-primary-darken focus:bg-primary-darken transition"
                        onClick={hideModal}
                        label="No"/>
                    <AppButton
                        disabled={!!error}
                        className="w-full h-[40px] rounded-2xl font-semibold hover:text-primary-darken focus:text-primary-darken transition"
                        loaderColor="!text-black"
                        onClick={handleCancelSubscription}
                        label="Yes"/>
                </div>
            </div>
        </AppModal>
    );
};

export default CancelSubscriptionModal;
