import MessageWrapper from "../containers/message-wrapper";
import {ReactionsList, useMessageContext} from "stream-chat-react";
import {ReactComponent as ProductsIcon} from "@assets/icons/products.svg";
import DeliveredProductCard from "../../../digital-products/components/ui/delivered-product-card";
import {MessageTimestamp} from "../ui/message-time-stamp";
import {useNavigate} from "react-router-dom";
import {useDeliveredProductMessage} from "../../hooks/use-delivered-product-message";
import {ExtendedMessageByDeliveredProduct} from "../../models/interfaces/extended-message-delivered-product.interface";
import {useDigitalProductApi} from "../../../core/hooks/use-digital-product-api";
import React, {useEffect, useState} from "react";
import {ShortDataImage} from "../../../common/models/interfaces/short-data-image.interface";

const CustomMessageDeliveredProducts = () => {
    const {message, isMyMessage} = useMessageContext();
    const navigate = useNavigate();
    const [loadingImage, setLoadingImage] = useState<boolean>(false);
    const [imageProduct, setImageProduct] = useState<ShortDataImage | undefined>(undefined);
    const {getDigitalProductImageById} = useDigitalProductApi();
    const {
        name,
        aliasName,
        category,
        price,
        ownerUsername,
        productId,
    } = useDeliveredProductMessage(message as unknown as ExtendedMessageByDeliveredProduct);
    // const handleGoDetailsProduct = () => navigate(`/purchases/${productId}`)
    const handleGoDetailsProduct = () => {
        !isMyMessage() && navigate(`/${ownerUsername}/${aliasName}`);
    }

    const fetchProductImage = async () => {
        if (productId) {
            setLoadingImage(true)
            await getDigitalProductImageById(productId)
                .then(res => setImageProduct({
                    name: res.imageName,
                    contentBase64: res.imageContent
                }))
                .finally(() => setLoadingImage(false))
        }
    }

    useEffect(() => {
        fetchProductImage();
    }, []);

    return (
        <MessageWrapper additionalUpperInfo={`You ${isMyMessage() ? "received" : "used"} $${price}`}>
            <div className='dp-message'>
                <div className='dp-message-action-info'>
                    <ProductsIcon className='dp-message-icon'/>
                    {isMyMessage() ? (
                        <span>You sold product</span>
                    ) : (
                        <span>You bought product</span>
                    )}
                </div>
                <DeliveredProductCard
                    onClick={handleGoDetailsProduct}
                    loadingImage={loadingImage}
                    imageProduct={imageProduct}
                    category={category}
                    name={name}/>
                <div className="flex justify-end gap-2 items-center w-full">
                    {(message.latest_reactions?.length || 0) > 0 && (
                        <ReactionsList reactions={message.latest_reactions} reverse/>
                    )}
                    <MessageTimestamp timestamp={message.created_at!}/>
                </div>
            </div>
        </MessageWrapper>
    )
}

export default CustomMessageDeliveredProducts
