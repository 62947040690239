import React, {createContext, useContext, useEffect, useState} from 'react';
import {useForm, UseFormReturn} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    SubscriptionOfferFormBody
} from "../../digital-products/models/interfaces/subscription-offer-form-body.interface";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";
import {UpdateSubscriptionOfferRequest} from "../../core/models/interfaces/update-subscription-offer-request.interface";
import {OwnSubscriptionOffer} from "../../core/models/interfaces/own-subscription-offer.interface";
import {ShortStatisticsSubscription} from "../../core/models/interfaces/short-statistics-subscription.interface";
import {schemaSubscriptionManager} from "../constants/schema-subscription-manager";
import YDollarsConversionService from "../../purchases/services/ydollars-conversion.service";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {SessionCreatorToolsContext} from "../../digital-products/contexts/session-creator-tools-context";
import {useSubscriptionInfoEarnings} from "../hooks/use-subscription-info-earnings.hook";
import {useSubscriptionInfoYearlyDiscountPrice} from "../hooks/use-subscription-infor-discount-price.hook";

export interface FormSubscriptionOfferContextType {
    methods: UseFormReturn<SubscriptionOfferFormBody>;
    handleSubmitOffer: (onSuccess?: () => void) => Promise<void>;
    loading: boolean;
    isSubmittingSuccessful: boolean;
    calculateSubscriptionEarning: () => string;
    calculateDiscountPrice: () => string;
    isEnabledSubOnInit: boolean;
    offerSubscription: OwnSubscriptionOffer | null;
    shortStatsSubscription: ShortStatisticsSubscription | null;
}

interface FormSubscriptionOfferProviderProps {
    children: React.ReactNode;
    callbackOnSuccessSubmit?: UniversalClickHandler;
}

export const FormSubscriptionOfferContext = createContext<FormSubscriptionOfferContextType | undefined>(undefined);

export const FormSubscriptionOfferProvider: React.FC<FormSubscriptionOfferProviderProps> = (props: FormSubscriptionOfferProviderProps) => {
    const [isSubmittingSuccessful, setIsSubmittingSuccessful] = useState(false);
    const [isEnabledSubOnInit, setIsEnabledSubOnInit] = useState<boolean>(false);
    const {
        offerSubscription,
        updateSubscription,
        initLoading,
        shortStatsSubscription,
    } = useContext(SessionCreatorToolsContext)!

    const methods = useForm<SubscriptionOfferFormBody>({
        defaultValues: {
            subscriptionEnabled: false,
            monthlyPrice: 10,
            yearlyDiscountEnabled: false,
            yearlyDiscountType: TypeDiscount.PERCENTAGE,
            yearlyDiscount: 0,
            yearlyPrice: 120,
            textFreeMessagesCount: 0,
            voiceFreeMessagesCount: 0,
            videoFreeMessagesCount: 0,
        },
        // @ts-ignore
        resolver: yupResolver(schemaSubscriptionManager),
        mode: 'onChange',
    });

    const values = methods.watch();

    const calculateSubscriptionEarning = useSubscriptionInfoEarnings(values.monthlyPrice || 0)
    const calculateDiscountPrice = useSubscriptionInfoYearlyDiscountPrice(values.monthlyPrice, values.yearlyDiscount, values.yearlyDiscountType);

    const handleSubmitOffer = async (onSuccess?: () => void) => {
        await methods.handleSubmit(async (data) => {
            try {
                const requestBody: UpdateSubscriptionOfferRequest = {
                    active: data.subscriptionEnabled,
                    monthlyPrice: data.monthlyPrice,
                    yearlyDiscountActive: data.yearlyDiscountEnabled,
                    yearlyDiscountPrice: data.yearlyDiscountType === TypeDiscount.PRICE ? data.yearlyDiscount : 0,
                    yearlyDiscountPercentage: data.yearlyDiscountType === TypeDiscount.PERCENTAGE ? data.yearlyDiscount : 0,
                    yearlyPrice: data.yearlyPrice ?? 0,
                    voiceMessages: data.voiceFreeMessagesCount,
                    textMessageBlock: data.textFreeMessagesCount,
                    videoMessages: data.videoFreeMessagesCount,
                };

                await updateSubscription(requestBody);
                setIsSubmittingSuccessful(true);

                if (props.callbackOnSuccessSubmit) {
                    props.callbackOnSuccessSubmit();
                }

                methods.reset(data);
                setIsEnabledSubOnInit(requestBody.active);

                if (onSuccess) {
                    onSuccess();
                }
            } catch (error) {
                console.error("Error while submitting subscription offer:", error);
            }
        })();
    };

    useEffect(() => {
        if (values.yearlyDiscountEnabled) {
            const monthlyPrice = parseFloat(methods.getValues("monthlyPrice") + "") || 0;
            const discount = parseFloat(methods.getValues("yearlyDiscount") + "") || 0;
            const discountType = methods.getValues("yearlyDiscountType")!;

            const {total} = YDollarsConversionService.calculateDiscountedPrice(
                monthlyPrice,
                discount,
                discountType,
                12
            );

            const discountedYearlyPrice = parseFloat(total.yDollars.replaceAll(" ", "")) ?? 0
            if (discountedYearlyPrice > monthlyPrice) {
                methods.setValue("yearlyPrice", discountedYearlyPrice, {shouldDirty: true,shouldValidate:true});
                methods.clearErrors("yearlyDiscount");
                methods.trigger("yearlyDiscount");
            } else {
                methods.setValue("yearlyPrice", 0, {shouldDirty: true,shouldValidate:true});
                methods.setError("yearlyDiscount", {
                    type: "yearly-price-gt-monthly-price",
                    message: "Yearly discount is not allowed because the yearly price is not greater than the monthly price",
                });
                methods.trigger("yearlyDiscount");
            }
        }
    }, [values.monthlyPrice, values.yearlyDiscount, values.yearlyDiscountType, values.yearlyDiscountEnabled]);

    useEffect(() => {
        if (offerSubscription) {
            setIsEnabledSubOnInit(offerSubscription.active);
            methods.reset({
                subscriptionEnabled: offerSubscription.active,
                monthlyPrice: Number(offerSubscription.monthlyPrice) ?? 0,
                yearlyPrice: offerSubscription.yearlyPrice ?? 0,
                yearlyDiscountEnabled: offerSubscription.yearlyDiscountActive,
                yearlyDiscountType: !offerSubscription.yearlyDiscountPercentage ? TypeDiscount.PRICE : TypeDiscount.PERCENTAGE,
                yearlyDiscount: !!offerSubscription.yearlyDiscountPercentage ? Number(offerSubscription.yearlyDiscountPercentage ?? 0) : Number(offerSubscription.yearlyDiscountPrice ?? 0),
                textFreeMessagesCount: Number(offerSubscription.textMessageBlock) ?? 0,
                voiceFreeMessagesCount: Number(offerSubscription.voiceMessages) ?? 0,
                videoFreeMessagesCount: Number(offerSubscription.videoMessages) ?? 0,
            });
        }
    }, [offerSubscription]);

    return (
        <FormSubscriptionOfferContext.Provider
            value={{
                methods,
                isEnabledSubOnInit,
                handleSubmitOffer,
                loading: initLoading,
                isSubmittingSuccessful,
                calculateDiscountPrice,
                calculateSubscriptionEarning,
                offerSubscription,
                shortStatsSubscription,
            }}
        >
            {props.children}
        </FormSubscriptionOfferContext.Provider>
    );
};


