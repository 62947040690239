import {AttachmentPreviewList, useChannelStateContext, useMessageInputContext} from "stream-chat-react";
import {useMediaQuery} from "react-responsive";
import {useEffect, useRef, useState} from "react";
import MessageQuote from "./message-quote";
import useAudioProcessor from "../../../core/hooks/use-audio-processor";
import {processAttachments} from "../../helpers/process-attachments";
import {prefixNameVoiceRecorded} from "../../constants/prefix-name-voice-recorded";
import ActionButtons from "./message-input-action-buttons";
import EmojiPickerButton from "./emoji-picker-button";
import CustomLiveAudioVisualizer from "./custom-live-audio-visualizer";
import MessageInputField from "./message-input-field";

const CustomMessageInput = () => {
    const {
        text,
        handleChange,
        handleSubmit,
        numberOfUploads,
        parent,
        attachments,
    } = useMessageInputContext();
    const {quotedMessage} = useChannelStateContext();
    const [isKeyboardOut, setIsKeyboardOut] = useState(false);
    const inputRef = useRef<HTMLTextAreaElement | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const shouldShowQuotedMessage = Boolean(quotedMessage) && quotedMessage?.parent_id === parent?.id;
    const isDesktop = useMediaQuery({minWidth: 768});
    const {uploadNewFiles} = useMessageInputContext();

    const {isRecording, mediaRecorderRef, startRecording, stopRecording} = useAudioProcessor(
        (file) => uploadNewFiles([file]),
        {
            mimeType: "audio/mp4",
            fileName: prefixNameVoiceRecorded
        }
    );

    const handleOnBlur = () => setIsKeyboardOut(false)
    const handleOnFocus = () => !isDesktop && setIsKeyboardOut(true);
    const handleSendMessage = async (event: any) => {
        const updatedAttachments = await processAttachments(attachments);
        handleSubmit(event, {attachments: updatedAttachments});
    };
    const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage(event);
        }
    };

    useEffect(() => {
        if (quotedMessage && inputRef.current) {
            inputRef.current.focus();
        }
    }, [quotedMessage]);

    return (
        <div className={`${!isKeyboardOut && !isDesktop && " px-4 pb-4"}`}>
            <div
                className={`input-shadow border bg-white  focus-within:border-blue-600  w-full flex flex-col  ${isKeyboardOut ? "rounded-none bottom-0 left-0 w-full" : "rounded-3xl"} `}>
                {shouldShowQuotedMessage && <MessageQuote/>}
                {numberOfUploads > 0 && <div className="p-1"><AttachmentPreviewList/></div>}
                <div className="min-h-[48px] w-full flex "
                     onFocus={handleOnFocus}
                     onBlur={(e) => {
                         if (!e.currentTarget.contains(e.relatedTarget)) {
                             handleOnBlur();
                         }
                     }}
                >
                    <EmojiPickerButton showTrigger={!isRecording}/>
                    <div ref={containerRef} className="w-full flex overflow-hidden">
                        {isRecording
                            ? (
                                <CustomLiveAudioVisualizer
                                    isRecording={isRecording}
                                    mediaRecorderRef={mediaRecorderRef}
                                    containerRef={containerRef}
                                />
                            )
                            : (
                                <MessageInputField
                                    text={text}
                                    handleChange={handleChange}
                                    handleKeyDown={handleKeyDown}
                                    isDesktop={isDesktop}
                                />
                            )
                        }
                    </div>
                    <ActionButtons
                        text={text}
                        attachmentsLength={attachments.length}
                        isRecording={isRecording}
                        startRecording={startRecording}
                        stopRecording={stopRecording}
                        sendMessage={handleSendMessage}
                    />
                </div>
            </div>
        </div>
    );
};


export default CustomMessageInput; 
