import { useRef } from "react";

interface MessageInputFieldProps {
    text: string;
    handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
    handleKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
    isDesktop: boolean;
}

const MessageInputField = ({ text, handleChange, handleKeyDown, isDesktop }: MessageInputFieldProps) => {
    const inputRef = useRef<HTMLTextAreaElement | null>(null);

    return (
        <textarea
            ref={inputRef}
            value={text}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onInput={(e) => {
                const lineHeight = isDesktop ? 19.6 : 18;
                const maxHeight = isDesktop ? 80 : 72;
                const target = e.target as HTMLTextAreaElement;
                target.style.height = `${lineHeight}px`;
                target.style.height = `${Math.min(target.scrollHeight, maxHeight)}px`;
            }}
            rows={1}
            placeholder="Type message"
            className="self-center no-scrollbar w-full border-0 outline-none bg-transparent resize-none px-4 pt-1"
        />
    );
};

export default MessageInputField;
