import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import useAuth from '../../auth/hooks/use-auth';
import {useBecomeCreatorApi} from "../../core/hooks/use-become-creator-api";
import Base64Helper from "../../../utils/base64-helper";
import {useNavigate} from "react-router-dom";
import {ChevronLeftIcon} from "@heroicons/react/20/solid";
import AppButton from "../../common/components/app-button";
import AddFirstProductsView from "../../digital-products/views/add-first-product-view";
import authService from '../../auth/services/auth.service';
import {yupResolver} from "@hookform/resolvers/yup";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {classNames} from "../../../utils/class-names";
import ProfileImageService from "../../core/db/services/profile-image-service";
import ProfileBackgroundImageService from "../../core/db/services/profile-background-image-service";
import useEffectOnce from '../../common/hooks/use-effect-once';
import {useDigitalProductCategoryApi} from '../../core/hooks/use-digital-product-category-api';
import StepOne from "../containers/step-one";
import StepTwo from "../containers/step-two";
import StepThree from "../containers/step-three";
import StepFour from "../containers/step-four";
import StepFive from "../containers/step-five";
import {schemaBecomeCreator} from "../constants/schema-become-creator";
import {BecomeCreatorFormData} from "../models/interfaces/become-creator-form-data.interface";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";

interface BecomeCreatorFormProps {
    placeForm?: "registration" | "home";
    onEnding?: UniversalClickHandler;
    onSkipAll?: UniversalClickHandler;
}

function BecomeCreatorForm(props: BecomeCreatorFormProps) {
    const [step, setStep] = useState(1);
    const [showProductForm, setShowProductForm] = useState(false);
    const navigate = useNavigate();
    const {currentUser, updateAuthenticationField} = useAuth();
    const {sendRequestToBecomeCreator} = useBecomeCreatorApi();
    const {initCategories} = useDigitalProductCategoryApi();
    const methods = useForm<BecomeCreatorFormData>({
        defaultValues: {
            profileImage: currentUser?.profileImage || '',
            backgroundProfileImage: currentUser?.backgroundProfileImage || '',
            firstName: currentUser?.firstname || '',
            lastName: currentUser?.lastname || '',
            userName: currentUser?.username || '',
            bio: currentUser?.bio || '',
            longBio: currentUser?.longBio || '',
            isTextMessages: true,
            textMessagePrice: 2,
            isVoiceMessages: false,
            voiceMessagePrice: 0,
            isVideoMessages: false,
            videoMessagePrice: 0,
            freeTextMessageBlock: 0,
            freeVoiceMessagesMin: 0,
            freeVideoMessagesMin: 0,
            occupation: '',
            completionDate: new Date(),
            projectDetails: '',

            subscriptionEnabled: false,
            monthlyPrice: 10,
            yearlyDiscountEnabled: false,
            yearlyDiscountType: TypeDiscount.PERCENTAGE,
            yearlyDiscount: 0,
            yearlyPrice: 120,
            textFreeMessagesCount: 0,
            voiceFreeMessagesCount: 0,
            videoFreeMessagesCount: 0,

        },
        resolver: yupResolver(schemaBecomeCreator) as any,
        mode: 'onSubmit'
    });
    const {
        placeForm = "home",
        onEnding,
        onSkipAll
    } = props;


    const nextStep = () => setStep(step + 1);
    const prevStep = () => setStep(step - 1);
    const handleShowProductForm = () => setShowProductForm(true);

    const saveRequest = async () => {
        await methods.handleSubmit(async (values) => {
            const requestBody = {
                firstName: values.firstName,
                lastName: values.lastName,
                bio: values.bio,
                longBio: values.longBio,
                chatPrice: values.isTextMessages ? (values.textMessagePrice ? values.textMessagePrice : 0) : 0,
                callPrice: values.isVoiceMessages ? (values.voiceMessagePrice ? values.voiceMessagePrice : 0) : 0,
                videoCallPrice: values.isVideoMessages ? (values.videoMessagePrice ? values.videoMessagePrice : 0) : 0,
                facebookSocialHandle: values.facebookSocialHandle,
                instagramSocialHandle: values.instagramSocialHandle,
                linkedinSocialHandle: values.linkedinSocialHandle,
                twitterSocialHandle: values.twitterSocialHandle,
                youtubeSocialHandle: values.youtubeSocialHandle,
                subscriptionRequest: {
                    active: values.subscriptionEnabled,
                    monthlyPrice: values.monthlyPrice,
                    yearlyDiscountActive: values.yearlyDiscountEnabled,
                    yearlyDiscountPrice: values.yearlyDiscountType === TypeDiscount.PRICE ? values.yearlyDiscount : 0,
                    yearlyDiscountPercentage: (values.subscriptionEnabled && values.yearlyDiscountEnabled && values.yearlyDiscountType) === TypeDiscount.PERCENTAGE
                        ? values.yearlyDiscount
                        : 0,
                    yearlyPrice: values.yearlyPrice ?? 0,
                    voiceMessages: values.voiceFreeMessagesCount,
                    textMessageBlock: values.textFreeMessagesCount,
                    videoMessages: values.videoFreeMessagesCount,
                }
            };

            const formData = new FormData();
            formData.append('image', Base64Helper.base64ToBlob(values.profileImage)!);
            formData.append('backgroundProfileImage', Base64Helper.base64ToBlob(values.backgroundProfileImage)!)
            formData.append('request', new Blob([JSON.stringify(requestBody)], {type: "application/json"}));
            await sendRequestToBecomeCreator(formData)
            if (values.profileImage) {
                await ProfileImageService.saveImage(Base64Helper.removeBase64Prefix(values.profileImage))
            }
            if (values.backgroundProfileImage) {
                await ProfileBackgroundImageService.saveImage(Base64Helper.removeBase64Prefix(values.backgroundProfileImage))
            }
            updateAuthenticationField("user", {
                ...currentUser!,
                pendingPromotion: true,
                firstname: values?.firstName ? values.firstName : currentUser?.firstname!,
                lastname: values?.lastName ? values.lastName : currentUser?.lastname!,
                username: values?.userName ? values.userName : currentUser?.username!,
                bio: values?.bio ? values.bio : currentUser?.bio!,
                longBio: values?.longBio ? values.longBio : currentUser?.longBio!,
                backgroundProfileImage: Base64Helper.removeBase64Prefix(values.backgroundProfileImage),
                profileImage: Base64Helper.removeBase64Prefix(values.profileImage),
            })
            nextStep();
        })();
    };

    useEffectOnce(() => {
        const fetchData = async () => await initCategories();
        fetchData();
    });

    useEffect(() => {
        const data = authService.authenticatedUser;
        const isSent = data?.pendingPromotion;
        if (isSent) {
            setStep(4);
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}
                  className={classNames(
                      "flex justify-center w-full h-full min-h-full flex-grow",
                      placeForm === "registration" && "p-4",
                      showProductForm && "hidden"
                  )}>
                <div className="w-full">
                    {step === 1 && (
                        <StepOne
                            onSkipAll={onSkipAll}
                            showPrevButton={placeForm === "home"}
                            nextStep={nextStep}
                            prevStep={() => navigate(-1)}
                        />
                    )}
                    {step === 2 && (
                        <StepTwo
                            onSkipAll={onSkipAll}
                            prevStep={prevStep}
                            nextStep={nextStep}
                            messageDisabled={!methods.watch("isTextMessages")}
                            voiceDisabled={!methods.watch("isVoiceMessages")}
                            videoDisabled={!methods.watch("isVideoMessages")}
                            messagePrice={methods.watch("textMessagePrice")}
                            voicePrice={methods.watch("voiceMessagePrice")}
                            videoPrice={methods.watch("videoMessagePrice")}
                        />
                    )}
                    {step === 3 && (
                        <StepThree
                            onSkipAll={onSkipAll}
                            prevStep={prevStep}
                            nextStep={nextStep}
                        />
                    )}
                    {step === 4 && !showProductForm && (
                        <StepFour
                            onSkipAll={onSkipAll}
                            onAddProduct={handleShowProductForm}
                            prevStep={prevStep}
                            saveRequest={saveRequest}/>
                    )}
                    {step === 5 && (
                        <StepFive btnText={placeForm === "home" ? "Go to home" : "Next"}
                                  username={methods.getValues('userName')} onEnding={onEnding}/>
                    )}
                </div>
            </form>
            {showProductForm && (
                <div className={classNames(
                    "space-y-3",
                    placeForm === "registration" && "p-4",
                )}>
                    <AppButton
                        type="button"
                        label=""
                        className="bg-gray-light text-special-gray w-10 h-10 !rounded-full"
                        onClick={() => setShowProductForm(false)}
                    >
                        <ChevronLeftIcon className="w-5 min-w-5"/>
                    </AppButton>
                    <AddFirstProductsView onSuccess={() => {
                        saveRequest()
                        setShowProductForm(false)
                    }} hideNewCategoryButton={true}/>
                </div>
            )}
        </FormProvider>
    );
}

export default BecomeCreatorForm;
