import React from 'react';

const SkeletonPurchasedSubscriptionItem = () => {
    return (
        <div className="flex items-center gap-3 rounded-xl animate-pulse">
            <div className="h-12 w-12 min-w-12 bg-gray-300 rounded-2xl"></div>
            <div className="flex flex-col gap-2 w-full">
                <div className="h-3 bg-gray-300 rounded w-3/4"></div>
                <div className="h-3 bg-gray-300 rounded w-1/2"></div>
            </div>
            <div>
                <div className="h-9 w-9 min-w-9 bg-gray-200 rounded-xl"></div>
            </div>
        </div>
    );
};

export default SkeletonPurchasedSubscriptionItem;
