// StepFive.tsx
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import wellDoneNowYouCanShareYourCreatorLinkToFans from "@assets/images/become_creator_finish.svg";
import StepDot, {StepDotState as DotState} from '../../common/components/app-dot';
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {removeProtocol} from "../../../utils/extract-base-url";

interface StepFourProps {
    username: string;
    onEnding?: UniversalClickHandler;
    btnText?: string;
}

const StepFive: React.FC<StepFourProps> = ({username, onEnding, btnText = "Go to home"}) => {
    const urlPublicProfile = `${removeProtocol(window.location.origin)}/${username}`
    const navigate = useNavigate();

    const handleCopyLink = async (url: string) => {
        await ClipboardHelper.copyText(url,
            () => NotificationService.success('Link has been copied to clipboard!', "Success", {duration: 5000}),
            (error) => NotificationService.error('Failed to copy link', error, 'Error', {duration: 5000})
        );
    };

    const handleClick = async () => {
        if (onEnding) {
            await onEnding();
        } else {
            navigate("/");
        }
    }

    return (
        <>
            <img src={wellDoneNowYouCanShareYourCreatorLinkToFans} alt="Finish" className='h-[300px] mx-auto pb-3'/>

            <div className={classNames('space-y-5')}>
                <div>
                    <h1 className="font-bold text-4xl">Request Sent!</h1>
                </div>
                <div>
                    <h1 className="font-bold text-4xl">
                        Your application is being reviewed by our admin team.
                    </h1>
                </div>
                <div className="text-gray-500 font-bold">
                    In the meantime, feel free to explore yurs! You can follow creators, engage with profiles, and enjoy the app right away.
                </div>
                <div className="text-color-gray-500 text-xs text-gray">
                    Once your application is approved, you'll unlock all creator features, allowing you to share your link, gain followers, and grow your subscriber base.
                </div>
            </div>

            <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.ACTIVE}/>
            </div>

            <AppButton
                onClick={handleClick}
                type="button"
                className="bg-primary text-white w-full !rounded-3xl mt-4 font-semibold"
                label={btnText}
            />

            {/*<AppButton*/}
            {/*    type="button"*/}
            {/*    label=""*/}
            {/*    className="bg-primary text-white w-full !rounded-3xl mt-4 font-bold"*/}
            {/*    onClick={() => handleCopyLink(urlPublicProfile)}*/}
            {/*>*/}
            {/*    <div className="flex justify-center items-center">*/}
            {/*        <LinkIcon className="w-5 h-5 mr-1" />*/}
            {/*        <div>Copy link to profile</div>*/}
            {/*    </div>*/}
            {/*</AppButton>*/}
        </>
    );
}


export default StepFive;
