import useAuth from "./use-auth";
import {useUserApi} from "../../core/hooks/use-user-api";
import {AuthenticatedUser} from "../models/interfaces/authenticated-user.interface";
import {AllSteps} from "../../core/models/types/all-steps.type";
import {RegistrationSteps} from "../../core/models/enums/registration-steps.enum";
import {RegistrationStepsService} from "../../core/services/registration-steps.service";
import {StepConfig} from "../../core/models/interfaces/step-registration-config.interface";

const useRegistrationSteps = () => {
    const {currentUser, updateAuthenticationField} = useAuth();
    const {updateRegistrationStep} = useUserApi();

    const currentStep = RegistrationStepsService.getCurrentStep(currentUser);

    const getCurrentStepConfig = (): StepConfig | undefined => {
        return RegistrationStepsService.getStepConfig(currentStep, currentUser!.authority);
    };

    const getNextStep = (): AllSteps => {
        const stepConfig = getCurrentStepConfig();
        if (!stepConfig) {
            return RegistrationSteps.COMPLETED;
        }

        return stepConfig.getNextStep(currentUser!);
    };

    const handleNextStep = async (currentUser: AuthenticatedUser) => {
        try {
            const nextStep = getNextStep();
            await updateRegistrationStep(nextStep);

            updateAuthenticationField("user", {
                ...currentUser!,
                registrationStep: nextStep
            });
        } catch (err: any) {
            console.error(err)
        }
    };

    const canSkipStep = (): boolean => {
        const stepConfig = getCurrentStepConfig();
        return stepConfig?.skipable || false;
    };

    const getCurrentComponent = (): string => {
        const stepConfig = getCurrentStepConfig();
        return stepConfig?.component || '';
    };

    return {
        currentStep,
        nextStep: handleNextStep,
        canSkipStep,
        isLastStep: currentStep === RegistrationSteps.COMPLETED,
        component: getCurrentComponent(),
    };
};

export default useRegistrationSteps;
