import {SubscriptionPeriodMap} from "../models/interfaces/subscription-period-map";
import {PeriodSubscription} from "../../core/models/enums/period-subscription.enum";

export const subscriptionPeriodsMapEnum: SubscriptionPeriodMap[] = [
    {
        label: "Yearly",
        value: PeriodSubscription.YEARLY
    },
    {
        label: "Monthly",
        value: PeriodSubscription.MONTHLY,
    },
]
