import React, {useContext, useRef, useState} from "react";
import {ReactComponent as LinkIcon} from "@assets/icons/link.svg";
import {ReactComponent as EllipsisVerticalIcon} from "@assets/icons/more.svg";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import {ReactComponent as ProductsIcon} from "@assets/icons/products.svg";
import {classNames} from "../../../utils/class-names";
import NotificationService from "../../common/services/notification.service";
import AppButton from "../../common/components/app-button";
import ClipboardHelper from "../../../utils/clipboard-helper";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import {removeProtocol} from "../../../utils/extract-base-url";
import SubscriptionMarker from "../../subscriptions/components/ui/subscription-marker";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {SubscriptionModalData} from "../../purchases/models/interfaces/subscription-modal-data.interface";
import {getDiscountType} from "../../subscriptions/helpers/get-discount-type";
import {useNavigate} from "react-router-dom";
import {useModal} from "../../common/hooks/use-modal";
import {DataFollowRequiredModal} from "../../core/components/follow-required-modal";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import useRequiredFollow from "../../core/hooks/use-required-follow";
import useClickOutside from "../../common/hooks/use-click-outside";

interface PublicProfileSettingsButtonProps {
    creatorUserName: string;
    creatorId: string;
    isPreviewMode?: boolean;
}

const PublicProfileSettingsButton = (props: PublicProfileSettingsButtonProps) => {
    const {
        creatorUserName,
        creatorId,
        isPreviewMode,
    } = props;
    const [isOpen, setIsOpen] = useState(false);
    const urlPublicProfile = `${removeProtocol(window.location.origin)}/${creatorUserName}`
    const navigate = useNavigate();
    const {
        isFollowed,
        unfollowCreator,
        visitorHasUnlockedSubscription,
        changeStateFollowed,
        offerSubscription: sub
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;

    const {openModal} = useModal();
    const {showModal: showSubscriptionModal} = useModalWithData<SubscriptionModalData>(ModalIds.PURCHASE_SUBSCRIPTION);
    const {showModal: showFollowModal} = useModalWithData<DataFollowRequiredModal>(ModalIds.FOLLOW_REQUIRED);
    const checkLogin = useRequiredLogin({onLoginRequired: openModal.bind(this, ModalIds.LOGIN_REQUIRED)});
    const checkFollow = useRequiredFollow(creatorId, {
        onFollowRequired: showFollowModal.bind(this, {
            creatorId: creatorId,
            onSuccessFollow: () => changeStateFollowed(true),
            creatorUsername: props.creatorUserName,
        }),
    });

    const toggleMenu = () => setIsOpen(!isOpen);
    const handleViewProducts = async () => navigate("./digital-products")

    const handleSubscribe = async (event?: React.MouseEvent) => {
        const isLogged: boolean = checkLogin();
        const isFollowed: boolean = await checkFollow(event);

        if (sub && (isLogged && isFollowed)) {
            showSubscriptionModal({
                id: sub.subscriptionId,
                priceMonthly: sub.monthlyPrice ?? 0,
                priceYearly: sub.yearlyPrice ?? 0,
                yearlyDiscountActive: sub.yearlyDiscountActive,
                yearlyDiscountPercentage: sub.yearlyDiscountPercentage ?? 0,
                yearlyDiscountPrice: sub.yearlyDiscountPrice ?? 0,
                discountType: getDiscountType(sub.yearlyDiscountPrice, sub.yearlyDiscountPercentage),
                freeVideoCall: sub.videoMessages ?? 0,
                freeVoiceCall: sub.voiceMessages ?? 0,
                freeMessageBlock: sub.textMessageBlock ?? 0,
                sellerFullName: "",
                sellerUsername: "",
                stripeYearlyPriceId: sub.stripeYearlyPriceId,
                stripeMonthlyPriceId: sub.stripeMonthlyPriceId,
            })
        }
        setIsOpen(false);
    }

    const handleCopyLink = async () => {
        await ClipboardHelper.copyText(urlPublicProfile,
            () => NotificationService.success('Link has been copied to clipboard!', "Success", {duration: 5000}),
            (error) => NotificationService.error('Failed to copy link', error, 'Error', {duration: 5000})
        );
        setIsOpen(false);
    };

    const handleUnfollow = async () => {
        await unfollowCreator();
        setIsOpen(false);
    }

    const menuRef = useRef<HTMLDivElement>(null);
    useClickOutside(isOpen, () => setIsOpen(false), [menuRef]);

    return (
        <div className="relative inline-block text-left" ref={menuRef}>
            <AppButton
                onClick={toggleMenu}
                className={classNames(
                    "flex items-center justify-center w-10 h-10 bg-white text-special-gray border border-special-gray rounded-2xl",
                    isOpen && "!border-black !text-black"
                )}
                label=''>
                <EllipsisVerticalIcon className='w-fit h-[0.875rem]'/>
            </AppButton>

            {isOpen && (
                <div
                    className="min-w-[250px] absolute right-0 z-10 w-48 mt-2 bg-white rounded-2xl shadow-notification p-3 space-y-2 !font-semibold !text-sm">
                    {(!isPreviewMode && isFollowed) && (
                        <AppButton
                            onClick={handleUnfollow}
                            classNameContentWrapper="!justify-start"
                            className="block w-full !p-0 border-0 hover:text-primary "
                            label=''>
                            <div className='flex gap-2 items-center'>
                                <div className="w-8 h-8 flex items-center justify-center">
                                    <CrossIcon className='w-[0.875rem] h-[0.875rem] mr-[0.375rem] relative left-[2px]'/>
                                </div>
                                <span className=''>Unfollow</span>
                            </div>
                        </AppButton>
                    )}
                    {!visitorHasUnlockedSubscription && (
                        <AppButton
                            onClick={handleSubscribe}
                            classNameContentWrapper="!justify-end flex-row-reverse"
                            loaderColor="black"
                            className="block w-full !p-0 border-0 hover:text-primary "
                            label=''>
                            <div className='flex gap-2 items-center'>
                                <div className="w-8 h-8 flex items-center justify-center">
                                    <SubscriptionMarker size="medium"/>
                                </div>
                                <span className=''>Subscribe</span>
                            </div>
                        </AppButton>
                    )}
                    <AppButton
                        onClick={handleViewProducts}
                        classNameContentWrapper="!justify-start"
                        className="block w-full !p-0 border-0 hover:text-primary "
                        label=''>
                        <div className='flex gap-2 items-center'>
                            <div className="w-8 h-8 flex items-center justify-center">
                                <ProductsIcon className='w-[1.125rem] h-[1.125rem]'/>
                            </div>
                            <span className=''>View products</span>
                        </div>
                    </AppButton>
                    <AppButton
                        onClick={handleCopyLink}
                        classNameContentWrapper="!justify-start"
                        className="block w-full !p-0 border-0 hover:text-primary "
                        label=''>
                        <div className='flex gap-2 items-center'>
                            <div className="w-8 h-8 flex items-center justify-center">
                                <LinkIcon className='w-[1.125rem] h-[1.125rem]'/>
                            </div>
                            <span className='whitespace-nowrap'>Copy link to profile</span>
                        </div>
                    </AppButton>
                </div>
            )}
        </div>
    );
}

export default PublicProfileSettingsButton;
