import {useContext} from "react";
import {DigitalProductFilterContext} from "../contexts/digital-product-filter-context";

export const useDigitalProductFilter = () => {
    const context = useContext(DigitalProductFilterContext);
    if (!context) {
        throw new Error("useDigitalProductFilter must be used within a DigitalProductFilterContext");
    }
    return context;
};
