import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import React, {useContext, useState} from "react";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import Coin from "../../core/components/ui/coin";
import {RestoreSubscriptionModalData} from "../models/interfaces/restore-subscription-modal-data.interface";
import {DateHelper} from "../../../utils/date-helper";
import {PurchasesSubscriptionsContext} from "../../purchases/contexts/purchases-subscriptions-context";
import {ReactComponent as ReplyIcon} from "@assets/icons/reply.svg";
import BenefitsSubscriptionCard from "./ui/benefits-subscription-card";
import Tooltip from "../../common/components/tooltip";
import Avatar from "../../chat/components/ui/avatar";
import AppButton from "../../common/components/app-button";
import Base64Helper from "../../../utils/base64-helper";

const RestoreSubscriptionModal = () => {
    const {data, hideModal} = useModalWithData<RestoreSubscriptionModalData>(ModalIds.RESTORE_SUBSCRIPTION);
    const subscription: RestoreSubscriptionModalData | null = data;
    const sellerFullName: string = subscription?.firstname + " " + subscription?.lastname;
    const activeToFormatted: string = DateHelper.formatDate(new Date(subscription?.activeTo ?? 0), "DD.MM.YYYY, HH:mm");
    const [error, setError] = useState<string | null>(null);

    const context = useContext(PurchasesSubscriptionsContext)
    if (!context) return null;
    const {restoreSubscriptionHandler} = context;

    const handleRestoreSubscription = async () => {
        try {
            if (subscription) {
                await restoreSubscriptionHandler(subscription.id, sellerFullName);
                hideModal();
            }
        } catch (err: any) {
            setError(err.response.data);
        }
    };

    return (
        <AppModal
            key={ModalIds.RESTORE_SUBSCRIPTION}
            id={ModalIds.RESTORE_SUBSCRIPTION}
            disableScroll={true}
            zIndex={40}
            classModal="md:min-w-[524px]"
        >
            <div className="flex flex-col w-full gap-3 bg-white">
                <div className="mb-4">
                    <Avatar size="md" image={{name: sellerFullName, contentBase64: Base64Helper.addBase64Prefix(subscription?.avatar) ?? ""}}/>
                </div>

                <div className="text-[0.625rem] text-primary font-semibold uppercase tracking-wider flex items-center">
                    <ReplyIcon className="w-[16px] h-[13.94px] min-w-[16px] inline mr-2 "/>
                    <span>Restore Subscription</span>
                </div>
                <h2 className="text-[1.375rem] leading-[1.65rem] font-bold mb-2">Good choice!</h2>


                <div className="space-y-3">
                    <p className="text-sm text-special-gray pr-10 inline-block">
                        When you restore subscription you’ll have free messages & calls to {sellerFullName}:
                    </p>
                    <BenefitsSubscriptionCard
                        freeMessageBlock={subscription?.actualTextMessageBlock || 0}
                        freeVoiceCall={subscription?.actualVoiceMessages || 0}
                        freeVideoCall={subscription?.actualVideoMessages || 0}
                    />
                </div>

                <p className="text-xs text-special-gray leading-[100%] flex">
                    <Tooltip/>
                    <span>
                        The first fee will be charged at {activeToFormatted} with {subscription?.price} <Coin/>.
                    </span>
                </p>

                <div className="flex justify-center mt-4">
                    <AppButton
                        disabled={!!error}
                        className="bg-primary text-white w-full h-[40px] rounded-2xl font-semibold hover:bg-primary-darken focus:bg-primary-darken transition"
                        onClick={handleRestoreSubscription}
                        label="Restore subscription"/>
                </div>
            </div>
        </AppModal>
    );
};

export default RestoreSubscriptionModal;
