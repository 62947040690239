import Base64Helper from "./base64-helper";

/**
 * FileHelper
 * A utility class for performing various operations on files.
 */
class FileHelper {
    /**
     * Validates if the file size is within the allowed limit.
     * @param file - The file to check.
     * @param maxFileSizeInMb - The maximum allowed file size in megabytes.
     * @returns `true` if the file size is valid, `false` otherwise.
     */
    static isFileSizeValid(file: File, maxFileSizeInMb: number): boolean {
        return file.size <= maxFileSizeInMb * 1024 * 1024;
    }

    /**
     * Validates if the file extension is allowed.
     * @param file - The file to check.
     * @param allowedExtensions - An array of allowed file extensions (e.g., ["jpg", "png"]).
     * @returns `true` if the file extension is valid, `false` otherwise.
     */
    static isFileExtensionValid(file: File, allowedExtensions: string[]): boolean {
        const fileExtension = file.name.split('.').pop()?.toLowerCase();
        return allowedExtensions.includes(fileExtension || "");
    }

    /**
     * Creates an empty file with the specified name, MIME type based on file extension or parameter, and optional size.
     * @param fileName - The desired name for the empty file (e.g., "empty.txt").
     * @param sizeInBytes - Optional size of the empty file in bytes (default: 0).
     * @param extension - Optional file extension (e.g., "txt", "pdf"). If provided, it overrides the extension extracted from fileName.
     * @returns A `File` object representing the empty file.
     */
    static createEmptyFile(fileName: string, sizeInBytes: number = 0, extension?: string): File {
        const resolvedExtension = extension?.toLowerCase() || fileName.split(".").pop()?.toLowerCase();
        const mimeType = resolvedExtension
            ? this.getMimeTypeFromExtension(resolvedExtension)
            : "application/octet-stream";

        // Tworzymy pusty plik na podstawie określonego rozmiaru i typu MIME
        const arrayBuffer = new Uint8Array(sizeInBytes); // Pusty bufor
        const resolvedFileName = fileName.includes(".")
            ? fileName
            : `${fileName}.${resolvedExtension || "bin"}`;

        return new File([arrayBuffer], resolvedFileName, {type: mimeType});
    }

    /**
     * Converts a file to a Base64 encoded string.
     * @param file - The file to convert.
     * @returns A promise that resolves to a Base64 encoded string of the file.
     */
    static convertFileToBase64(file: File): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = () => reject(new Error("Failed to convert file to Base64."));
            reader.readAsDataURL(file);
        });
    }

    /**
     * Provides a readable representation of the file size.
     * @param fileOrSize - The file or size in bytes to be converted.
     * @returns The file size in a human-readable format (e.g., "2.5 MB", "1.2 GB").
     */
    static getReadableFileSize(fileOrSize: File | number): string {
        const units = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        let size = typeof fileOrSize === "number" ? fileOrSize : fileOrSize.size;
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        return `${size.toFixed(1)}${units[unitIndex]}`;
    }

    /**
     * Converts a Base64 encoded string to a File object.
     * @param base64 - The Base64 encoded string.
     * @param fileName - The desired name for the resulting file.
     * @param extension
     * @returns A `File` object created from the Base64 string.
     */
    static convertBase64ToFile(base64: string, fileName: string, extension?: string): File {
        const base64Parts = Base64Helper.addBase64Prefix(base64).split(",");
        if (base64Parts.length !== 2 || !base64Parts[1]) {
            throw new Error("Invalid Base64 format");
        }

        try {
            const byteString = atob(base64Parts[1]);
            const mimeType = extension ? this.getMimeTypeFromExtension(extension) : base64.split(",")[0].match(/:(.*?);/)?.[1];
            const resolvedExtension = extension || mimeType?.split("/")[1] || "bin";
            const resolvedFileName = fileName.includes(".") ? fileName : `${fileName}.${resolvedExtension}`;


            const arrayBuffer = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                arrayBuffer[i] = byteString.charCodeAt(i);
            }

            return new File([arrayBuffer], resolvedFileName, {type: mimeType || "application/octet-stream"});
        } catch (error: any) {
            throw new Error("Failed to decode Base64: " + error?.message);
        }
    }

    /**
     * Creates a preview URL for the file.
     * @param file - The file to generate a preview URL for.
     * @returns A URL that can be used to preview the file.
     */
    static createPreviewUrl(file: File): string {
        return URL.createObjectURL(file);
    }

    /**
     * Extracts the file name without its extension.
     * @param file - The file whose name is to be extracted.
     * @returns The file name without the extension.
     */
    static getFileNameWithoutExtension(file: File): string {
        const fileNameParts = file.name.split(".");
        return fileNameParts.slice(0, -1).join(".");
    }

    /**
     * Retrieves the file extension.
     * @param file - The file whose extension is to be extracted.
     * @returns The file extension as a string.
     */
    static getFileExtension(file: File): string | undefined {
        return file.name.split(".").pop()?.toLowerCase();
    }

    static validateFile(file: File, allowedFormats: string[], maxFileSizeInMb: number, totalSize: number): {
        isValid: boolean,
        message?: string
    } {
        const fileExtension = file.name.split(".").pop()?.toLowerCase();
        const isAllowed = allowedFormats.includes(fileExtension || "");
        const isUnderSizeLimit = file.size <= maxFileSizeInMb * 1024 * 1024;

        if (!isAllowed) {
            return {isValid: false, message: `Invalid format. Allowed formats: ${allowedFormats.join(", ")}.`};
        }

        if (!isUnderSizeLimit) {
            return {isValid: false, message: `Exceeds single file size limit of ${maxFileSizeInMb}MB.`};
        }

        if (totalSize + file.size > 50 * 1024 * 1024) {
            return {isValid: false, message: `Total size exceeds 50MB.`};
        }

        return {isValid: true};
    };

    /**
     * Returns the MIME type for a given file extension.
     * @param extension - The file extension (e.g., "jpg", "png", "pdf").
     * @returns The corresponding MIME type or "application/octet-stream" if not found.
     */
    static getMimeTypeFromExtension(extension: string): string {
        const mimeTypeMap: Record<string, string> = {
            // Images
            jpg: "image/jpeg",
            jpeg: "image/jpeg",
            png: "image/png",
            gif: "image/gif",
            bmp: "image/bmp",
            webp: "image/webp",
            svg: "image/svg+xml",

            // Documents
            pdf: "application/pdf",
            doc: "application/msword",
            docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            xls: "application/vnd.ms-excel",
            xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ppt: "application/vnd.ms-powerpoint",
            pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
            txt: "text/plain",
            csv: "text/csv",
            json: "application/json",
            xml: "application/xml",

            // Audio
            mp3: "audio/mpeg",
            wav: "audio/wav",
            ogg: "audio/ogg",
            m4a: "audio/mp4",

            // Video
            mp4: "video/mp4",
            mov: "video/quicktime",
            avi: "video/x-msvideo",
            mkv: "video/x-matroska",
            webm: "video/webm",

            // Archives
            zip: "application/zip",
            rar: "application/x-rar-compressed",
            tar: "application/x-tar",
            gz: "application/gzip",
            "7z": "application/x-7z-compressed",
        };

        // Normalize extension and return the MIME type
        return mimeTypeMap[extension.toLowerCase()] || "application/octet-stream";
    }

    /**
     * Returns the file extension for a given MIME type.
     * @param mimeType - The MIME type (e.g., "image/jpeg", "application/pdf").
     * @returns The corresponding file extension or "bin" if not found.
     */
    static getExtensionFromMimeType(mimeType: string): string {
        const extensionMap: Record<string, string> = {
            // Images
            "image/jpeg": "jpg",
            "image/png": "png",
            "image/gif": "gif",
            "image/bmp": "bmp",
            "image/webp": "webp",
            "image/svg+xml": "svg",

            // Documents
            "application/pdf": "pdf",
            "application/msword": "doc",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
            "application/vnd.ms-excel": "xls",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
            "application/vnd.ms-powerpoint": "ppt",
            "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
            "text/plain": "txt",
            "text/csv": "csv",
            "application/json": "json",
            "application/xml": "xml",

            // Audio
            "audio/mpeg": "mp3",
            "audio/wav": "wav",
            "audio/ogg": "ogg",
            "audio/mp4": "m4a",

            // Video
            "video/mp4": "mp4",
            "video/quicktime": "mov",
            "video/x-msvideo": "avi",
            "video/x-matroska": "mkv",
            "video/webm": "webm",

            // Archives
            "application/zip": "zip",
            "application/x-rar-compressed": "rar",
            "application/x-tar": "tar",
            "application/gzip": "gz",
            "application/x-7z-compressed": "7z",
        };

        // Normalize and return the file extension
        return extensionMap[mimeType.toLowerCase()] || "bin";
    }


    /**
     * Triggers a file download in the browser.
     * @param data - The file content as a Blob, File, or ArrayBuffer.
     * @param fileName - The name for the downloaded file.
     */
    static triggerFileDownload(data: Blob | File | ArrayBuffer, fileName: string): void {
        const blob = data instanceof Blob ? data : new Blob([data]);
        const url = URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = fileName;
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
        URL.revokeObjectURL(url);
    }
}

export default FileHelper;
