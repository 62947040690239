import {GetSubscriptionSellerData} from "../../core/models/interfaces/get-subscription-seller-data.interface";
import {SubscriptionModalData} from "../../purchases/models/interfaces/subscription-modal-data.interface";
import {PeriodSubscription} from "../../core/models/enums/period-subscription.enum";

export const getFinalPriceByPeriod = (period: PeriodSubscription, subscription: GetSubscriptionSellerData | SubscriptionModalData): number => {
    switch (period) {
        case PeriodSubscription.MONTHLY:
            return subscription?.priceMonthly!;
        case PeriodSubscription.YEARLY:
            return subscription?.priceYearly!;
    }
}
