import AppButton from "../../common/components/app-button";
import {ReactComponent as SortIcon} from "@assets/icons/sort.svg";
import {useSidebar} from "../../../layout/hooks/use-sidebar";
import {SidebarIds} from "../../../layout/types/sidebar-ids.interface";
import {useModal} from "../../common/hooks/use-modal";

const ButtonGoToSettingsProducts = () => {
    const {openSidebar} = useSidebar();
    const { } = useModal();

    return (
        <AppButton
            onClick={openSidebar.bind(this, SidebarIds.SETTINGS_DIGITAL_PRODUCTS)}
            className="border-[#E1E3E5] h-[40px] w-[40px] bg-transparent !rounded-2xl text-special-gray hover:text-primary-darken focus:text-primary-darken">
            <SortIcon className="w-[12px] h-[12px]"/>
        </AppButton>
    )
}

export default ButtonGoToSettingsProducts
