import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {DefaultDigitalProductCategory} from "../../core/models/enums/digital-product-category.enum";

export function filterProductsByCategory(
    products: DigitalProduct[],
    category: DefaultDigitalProductCategory
): DigitalProduct[] {
    switch (category) {
        case DefaultDigitalProductCategory.ALL:
            return products.filter((product) => !product.deleted);
        case DefaultDigitalProductCategory.DELETED:
            return products.filter((product) => product.deleted);
        case DefaultDigitalProductCategory.UNCATEGORIZED:
            return products.filter((product) => !product.deleted && !product.category.id);
        default:
            return products.filter((product) => product.category.id === category && !product.deleted);
    }
}
