import {GetSubscriptionSellerData} from "../../core/models/interfaces/get-subscription-seller-data.interface";
import {SubscriptionModalData} from "../../purchases/models/interfaces/subscription-modal-data.interface";
import YDollarsConversionService from "../../purchases/services/ydollars-conversion.service";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";
import {PeriodSubscription} from "../../core/models/enums/period-subscription.enum";

export const getPriceMonthlyByPeriod = (period: PeriodSubscription, subscription: GetSubscriptionSellerData | SubscriptionModalData): string => {
    switch (period) {
        case PeriodSubscription.MONTHLY:
            return subscription.priceMonthly!.toString();
        case PeriodSubscription.YEARLY:
            const {perPeriod} = YDollarsConversionService.calculateDiscountedPrice(
                subscription?.priceMonthly!,
                subscription?.discountType === TypeDiscount.PERCENTAGE ? (subscription?.yearlyDiscountPercentage ?? 0) : (subscription?.yearlyDiscountPrice ?? 0),
                subscription?.discountType ?? TypeDiscount.PRICE,
                12
            );
            return perPeriod.yDollars!;
    }
}
