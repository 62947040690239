import {CriticalSteps, RegistrationSteps} from "../models/enums/registration-steps.enum";
import {StepConfig} from "../models/interfaces/step-registration-config.interface";
import {CriticalRegistrationStep} from "../models/types/critical-registration-step.type";
import {RegistrationFlow} from "../models/types/registration-flow.type";

export const CreatorFlow: RegistrationFlow = {
    [RegistrationSteps.INITIAL_REGISTRATION]: {
        component: 'InitialRegistration',
        skipable: false,
        getNextStep: () => RegistrationSteps.EMAIL_VERIFICATION
    },
    [RegistrationSteps.EMAIL_VERIFICATION]: {
        component: 'VerifyEmailForm',
        skipable: false,
        getNextStep: () => RegistrationSteps.BECOME_CREATOR
    },
    [RegistrationSteps.BECOME_CREATOR]: {
        component: 'BecomeCreatorForm',
        skipable: false,
        getNextStep: () => RegistrationSteps.COMPLETED
    },
    [RegistrationSteps.COMPLETED]: {
        component: 'CompletedView',
        skipable: false,
        getNextStep: () => RegistrationSteps.COMPLETED
    }
};

export const FanFlow: RegistrationFlow = {
    [RegistrationSteps.INITIAL_REGISTRATION]: {
        component: 'InitialRegistration',
        skipable: false,
        getNextStep: () => RegistrationSteps.EMAIL_VERIFICATION
    },
    [RegistrationSteps.EMAIL_VERIFICATION]: {
        component: 'VerifyEmailForm',
        skipable: false,
        getNextStep: () => RegistrationSteps.COMPLETED
    },
    [RegistrationSteps.COMPLETED]: {
        component: 'CompletedView',
        skipable: false,
        getNextStep: () => RegistrationSteps.COMPLETED
    }
};

export const CriticalFlow: Record<CriticalRegistrationStep, StepConfig> = {
    [CriticalSteps.LEGACY_USERNAME_REQUIRED]: {
        component: 'LegacyUsernameForm',
        skipable: false,
        getNextStep: (user) =>
            !user.emailConfirmed ?
                CriticalSteps.LEGACY_EMAIL_VERIFICATION :
                RegistrationSteps.COMPLETED
    },
    [CriticalSteps.LEGACY_EMAIL_VERIFICATION]: {
        component: 'LegacyEmailVerification',
        skipable: false,
        getNextStep: () => RegistrationSteps.COMPLETED
    }
};
