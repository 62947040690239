import {createContext, ReactNode, useEffect, useMemo, useState} from "react";
import {SubscriptionStatus} from "../models/enums/subscription-status.enum";
import {PurchasedSubscription} from "../../core/models/interfaces/purchased-subscription.interface";
import {getSubscriptionStatus} from "../helpers/get-subscription-status";
import {useNavigate, useSearchParams} from "react-router-dom";

interface SubscriptionFilterContextProps {
    selectedStatus: SubscriptionStatus;
    setSelectedStatus: (status: SubscriptionStatus) => void;
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    filteredSubscriptions: PurchasedSubscription[];
}

export const SubscriptionFilterContext = createContext<SubscriptionFilterContextProps | undefined>(undefined);

interface SubscriptionFilterProviderProps {
    children: ReactNode;
    subscriptions: PurchasedSubscription[];
}

export const SubscriptionFilterProvider = ({children, subscriptions}: SubscriptionFilterProviderProps) => {
    const [selectedStatus, setSelectedStatus] = useState<SubscriptionStatus>(SubscriptionStatus.ACTIVE);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const filteredSubscriptions = useMemo(() => {
        return subscriptions
            .filter((sub) => {
                const data = getSubscriptionStatus(sub);
                switch (selectedStatus) {
                    case SubscriptionStatus.ACTIVE:
                        return data.isActive;
                    case SubscriptionStatus.CANCELED:
                        return data.isCanceled;
                    case SubscriptionStatus.EXPIRES:
                        return data.isExpires;
                }
            })
            .filter((sub) => {
                const fullName: string = (sub.firstname.toLowerCase() + sub.lastname.toLowerCase())
                const id: string = sub.id
                if (searchQuery === "") return true

                return fullName.includes(searchQuery.toLowerCase().replace(" ", "")) ||
                    (id.includes(searchQuery) && searchQuery.length == 36 );
            });
    }, [subscriptions, selectedStatus, searchQuery]);

    useEffect(() => {
        const statusFromURL = searchParams.get("status");
        const queryFromURL = searchParams.get("search");

        if (statusFromURL) {
            const status = Object.values(SubscriptionStatus).find(
                (status) => status.toLowerCase() === statusFromURL.toLowerCase()
            );
            if (status) {
                setSelectedStatus(status);
            }
        }

        if (queryFromURL) {
            setSearchQuery(queryFromURL);
        }

        navigate({
            pathname: window.location.pathname,
            search: "",
        }, { replace: true });
    }, [searchParams]);

    return (
        <SubscriptionFilterContext.Provider
            value={{selectedStatus, setSelectedStatus, searchQuery, setSearchQuery, filteredSubscriptions}}
        >
            {children}
        </SubscriptionFilterContext.Provider>
    );
};
