import { ReactComponent as SubFilledIcon } from "@assets/icons/sub-filled.svg";

interface SubscriptionMarkerProps {
    size?: "large" | "medium" | "small"
}

const SubscriptionMarker = ({size}: SubscriptionMarkerProps) => {
    const sizes = {
        large: {
            wrapper: "w-[28px] h-[28px]",
        },
        medium: {
            wrapper: "w-[24px] h-[24px]",
        },
        small: {
            wrapper: "w-[20px] h-[20px]",
        }
    };

    const currentSize = sizes[size ?? "large"];

    return (
        <div
            className={`flex justify-center items-center rounded-[400px] bg-secondary p-1 ${currentSize.wrapper}`}
        >
            <SubFilledIcon className={`w-fit text-black h-[10px]`} />
        </div>
    );
};

export default SubscriptionMarker;
