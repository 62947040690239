import {useState} from 'react';
import {PaymentMethodType} from "../models/enums/payment-method-type.enum";
import StripeApiService from "../services/stripe-api-service";
import {StatusPurchasedSubscription} from "../../purchases/models/enums/status-purchased-subscription.enum";

export const useStripeApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getPrices = async (): Promise<any> => {
        setLoading(true);
        setError(null);
        try {
            return await StripeApiService.getPrices();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createCheckoutSession = async (priceId: string, paymentMethod: PaymentMethodType): Promise<{
        checkoutUrl: string
    }> => {
        setLoading(true);
        setError(null);
        try {
            return await StripeApiService.createCheckoutSession(priceId, paymentMethod);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createSubscriptionCheckoutSession = async (priceId: string): Promise<{
        checkoutUrl: string
    }> => {
        setLoading(true);
        setError(null);
        try {
            const successUrl: string = `${window.location.href}${StatusPurchasedSubscription.SUCCESS}`;
            const cancelUrl: string = `${window.location.href}${StatusPurchasedSubscription.ERROR}`;
            return await StripeApiService.createSubscriptionCheckoutSession(priceId, successUrl, cancelUrl);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        getPrices,
        createCheckoutSession,
        createSubscriptionCheckoutSession
    };
};
