/**
 * Determines the next time the user can edit the data and returns a formatted string (today, tomorrow, or a specific date).
 * @param editedDate - The date when the data was last edited.
 * @param hours - The number of hours that must pass before editing is allowed.
 * @returns A string indicating when the user can edit the data again (today, tomorrow, or on a specific date).
 */
export const getNextEditableTime= (editedDate: string | Date, hours: number): string => {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const date: Date = typeof editedDate === 'string' ? new Date(editedDate) : editedDate;

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date provided');
    }

    const nextEditableDate = new Date(date);
    nextEditableDate.setHours(date.getHours() + hours);

    const now = new Date();
    const isToday = nextEditableDate.toDateString() === now.toDateString();
    const isTomorrow = nextEditableDate.toDateString() === new Date(now.getTime() + 24 * 60 * 60 * 1000).toDateString();

    const formattedTime = new Intl.DateTimeFormat(undefined, {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: userTimeZone,
    }).format(nextEditableDate);

    if (isToday) {
        return `You can edit this data today at ${formattedTime}`;
    } else if (isTomorrow) {
        return `You can edit this data tomorrow at ${formattedTime}`;
    } else {
        const formattedDate = new Intl.DateTimeFormat(undefined, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: userTimeZone,
        }).format(nextEditableDate);

        return `You can edit this data on ${formattedDate} at ${formattedTime}`;
    }
};
