import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import EditContactPricesView from "../features/digital-products/views/edit-contact-prices-view";
import EditSubscriptionView from "../features/subscriptions/views/edit-subscription-view";
import ListDigitalProductsView from "../features/digital-products/views/list-digital-products-view";
import {SessionCreatorToolsProvider} from "../features/digital-products/contexts/session-creator-tools-context";
import FormDigitalProductView from "../features/digital-products/views/form-digital-product-view";
import DigitalProductDetailsView from "../features/digital-products/views/digital-product-details-view";

const CreatorToolsPage = () => {
    return (
        <SessionCreatorToolsProvider initFetch={true}>
            <Routes>
                <Route index element={<EditContactPricesView/>}/>
                <Route path='/subscription' element={<EditSubscriptionView/>}/>
                <Route path='/digital-products' element={<ListDigitalProductsView/>}/>
                <Route path='/new-product' element={<FormDigitalProductView mode='create'/>}/>
                <Route path='/edit-product/:aliasName' element={<FormDigitalProductView mode='edit'/>}/>
                <Route path='/digital-product/:aliasName' element={<DigitalProductDetailsView/>}/>
                <Route path="*" element={<Navigate to="/not-found"/>}/>
            </Routes>
        </SessionCreatorToolsProvider>
    )
}

export default CreatorToolsPage
