import {PurchasedSubscription} from "../../core/models/interfaces/purchased-subscription.interface";
import {PeriodSubscription} from "../../core/models/enums/period-subscription.enum";

export const getSubscriptionStatus = (subscription: PurchasedSubscription) => {
    const now: Date = new Date();
    const activeToDate: Date = new Date(subscription.activeTo);

    const isActive: boolean = subscription.isRenewable
    const isExpires: boolean = !subscription.isRenewable && activeToDate > now;
    const isCanceled: boolean = !subscription.isRenewable && activeToDate <= now;

    const canRestore: boolean = (subscription.subOfferEnabled && subscription.period === PeriodSubscription.MONTHLY)
        || (subscription.subYearlyOfferEnabled && subscription.period === PeriodSubscription.YEARLY)

    return {isActive, isExpires, isCanceled, canRestore};
};
