import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import StickyBottomActionBar from "../../common/components/sticky-bottom-action-bar";
import AppButton from "../../common/components/app-button";
import React, {useContext, useEffect} from "react";
import {DigitalProductDetails} from "../../core/models/interfaces/digital-product-details.interface";
import Coin from "../../core/components/ui/coin";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import {useModal} from "../../common/hooks/use-modal";
import useRequiredFollow from "../../core/hooks/use-required-follow";
import {UserProfileContext, UserProfileContextProps} from "../../profile/contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import {useAnalytics} from "../../core/hooks/use-analytics";
import {DataModalDigitalProduct} from "../models/interfaces/data-modal-digital-product.interface";
import {removeProtocol} from "../../../utils/extract-base-url";

interface PublicProductActionsProps {
    product: DigitalProductDetails | null;
    parentRef?: React.RefObject<any>;
    creatorUserName?: string;
}

const PublicProductActions = ({product, parentRef, creatorUserName}: PublicProductActionsProps) => {
    const aliasName = product?.aliasName;
    const owned = product?.owned;
    const urlPublicProduct = `${removeProtocol(window.location.origin)}/${creatorUserName}/${aliasName}`
    const {
        userProfile,
        changeStateFollowed,
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const {showModal, hideModal} = useModalWithData<DataModalDigitalProduct>(ModalIds.PURCHASE_PROMPT);
    const {showModal: showModalFollow} = useModalWithData<{ creatorId: string }>(ModalIds.FOLLOW_REQUIRED);
    const {openModal} = useModal();
    const checkLogin = useRequiredLogin({onLoginRequired: openModal.bind(this, ModalIds.LOGIN_REQUIRED)});
    const checkFollow = useRequiredFollow(product?.ownerId!, {
        onFollowRequired: showModalFollow.bind(this, {
            creatorId: product?.ownerId!,
            onSuccessFollow: () => changeStateFollowed(true),
            creatorUsername: product?.creatorUsername ?? product?.creatorName,
        }),
    });
    const {sendCheckoutStartDigitalProductInfo} = useAnalytics();

    const copyLinkToProductHandler = async (url: string) => {
        if (aliasName) {
            await ClipboardHelper.copyText(url,
                () => NotificationService.success('Link has been copied to clipboard!', "Success", {duration: 5000}),
                (error) => NotificationService.error('Failed to copy link', error, 'Error', {duration: 5000})
            );
        } else {
            NotificationService.warning('Unable to create a link. Please check the alias name or try again.', "Warning", {duration: 5000})
        }
    }

    const handleBuyDigitalProduct = async (event?: React.MouseEvent) => {
        if (!checkLogin()) return;
        const isAllowed: boolean = await checkFollow(event);
        if (!isAllowed) return;
        if (product) {
            showModal({
                productPicture: product.productPicture.base64,
                aliasName: product.aliasName,
                price: product.price,
                description: product.description,
                id: product.id,
                name: product.name,
                category: product.category,
            });
            sendCheckoutStartDigitalProductInfo(userProfile!, product!)
        }
    };

    useEffect(() => {
        return () => hideModal();
    }, []);

    return (
        <StickyBottomActionBar parentRef={parentRef}>
            <div className='@container md:@container-normal'>
                <div className='flex gap-2 flex-wrap md:flex-nowrap justify-center @[260px]:flex-nowrap'>

                    {owned ?
                        <div
                            className="!w-[250px] !text-sm font-semibold !bg-gray-light text-gray !rounded-2xl whitespace-nowrap flex justify-center py-2 px-4">
                            You bought this product
                        </div>
                        :
                        <AppButton
                            type='submit'
                            onClick={handleBuyDigitalProduct}
                            label=''
                            className='!w-full md:!w-[250px] !text-sm font-semibold !bg-primary text-white !rounded-2xl hover:brightness-110 focus:brightness-110 whitespace-nowrap'
                        >
                            <div className='flex gap-1 items-center'>
                                Buy: {product?.price} <Coin/>
                            </div>
                        </AppButton>
                    }

                    <AppButton
                        onClick={() => copyLinkToProductHandler(urlPublicProduct)}
                        label='Copy link to product'
                        type='button'
                        className='font-semibold w-fit !rounded-2xl !text-special-gray hover:!text-primary-darken whitespace-nowrap bg-white'/>
                </div>
            </div>
        </StickyBottomActionBar>
    )
}

export default PublicProductActions
