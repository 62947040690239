import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import AppButton from "../../common/components/app-button";
import useAuth from "../hooks/use-auth";
import {VerifyResetPasswordTokenFormBody} from "../models/interfaces/reset-email-body.interface";
import {schemaSendTokenResetPassword} from "../constants/schema-send-token-reset-password";
import Tooltip from "../../common/components/tooltip";
import TextFormField from "../../common/components/forms/text-form-field";


interface SendTokenResetPasswordFormProps {
    onSubmit: (token: string) => Promise<void>;
    onResend: () => Promise<void>,
    resendCountdown: number,
}

const SendTokenResetPasswordForm = ({onSubmit, onResend, resendCountdown}: SendTokenResetPasswordFormProps) => {
    const {loading} = useAuth();

    const methods = useForm<VerifyResetPasswordTokenFormBody>({
        resolver: yupResolver(schemaSendTokenResetPassword),
        mode: "onSubmit",
        delayError: 100,
    });

    const handleCodeSubmit = async (data: VerifyResetPasswordTokenFormBody): Promise<void> => {
        return await onSubmit(data.token)
    }
    const handleResend = async (): Promise<void> => await onResend()

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleCodeSubmit)}
                  className='flex flex-col justify-between gap-y-5 flex-grow'>
                <div>
                    <TextFormField
                        name="token"
                        label="Verification code"
                        placeholder="Write"
                        maxLength={6}
                        validationPattern={/^\d+$/}
                    />
                    <div className='flex text-special-gray text-xs leading-3 mt-2 gap-1 items-center'>
                        <Tooltip/>
                        <span>
                           Didn’t receive a code?
                            <AppButton
                                onClick={handleResend}
                                type='button'
                                disabled={loading}
                                label=''
                                loaderColor='var(--primary)'
                                className='!p-0 border-none text-xs underline ml-1 cursor-pointer transition hover:text-primary focus:text-primary'>
                                Resend a code {!!resendCountdown && `(${resendCountdown})`}
                            </AppButton>
                        </span>
                    </div>
                </div>
                <AppButton
                    type="submit"
                    loading={loading}
                    label="Reset password"
                    className="w-full text-white text-sm py-[9px] h-fit !rounded-2xl font-semibold bg-primary"
                />
            </form>
        </FormProvider>
    );
};

export default SendTokenResetPasswordForm;
