import {useContext} from "react";
import {AnnouncementContext} from "../contexts/annoucement-context";

export const useAnnouncement = () => {
    const context = useContext(AnnouncementContext);
    if (!context) {
        throw new Error("useAnnouncement must be used within an AnnouncementProvider");
    }
    return context;
};
