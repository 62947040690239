import {CustomEnvironmentParams} from "../models/interfaces/custom-environment-params.interface";
import {DEFAULT_DOMAIN} from "../constants/default-domain";
import {DEFAULT_GOOGLE_VENDORS} from "../constants/default-data-cmp-vendor";
import {DEFAULT_DATA_CMP_PURPOSE} from "../constants/default-data-cmp-purpose";
import {ConfigGTM} from "../models/interfaces/config-gtm.interface";

/**
 * Function to generate an iframe snippet for GTM
 *
 * @param id - The ID of the GTM container.
 * @param environment - Parameters to use a custom GTM environment, such as `gtm_auth` and `gtm_preview`.
 *                      These parameters allow loading a specific environment configuration.
 * @param customDomain - Custom domain for GTM. Defaults to `DEFAULT_DOMAIN`.
 * @param dataCmpVendor - A custom attribute (`data-cmp-vendor`) used to specify the consent management vendor(s).
 *                        Defaults to `DEFAULT_GOOGLE_VENDORS`.
 * @param dataCmpPurpose - A custom attribute (`data-cmp-purpose`) specifying the purpose(s) for which the iframe is being used.
 *                         This can be used for compliance with regulations like GDPR. Defaults to `DEFAULT_DATA_CMP_PURPOSE`.
 *
 * @returns A string containing the HTML snippet for an iframe that can be used for GTM with the specified parameters.
 */
export const getIframeSnippet = (
    id: Pick<ConfigGTM, 'id'>['id'],
    environment?: CustomEnvironmentParams,
    customDomain: ConfigGTM['customDomain'] = DEFAULT_DOMAIN,
    dataCmpVendor: ConfigGTM['dataCmpVendor'] = DEFAULT_GOOGLE_VENDORS,
    dataCmpPurpose: ConfigGTM['dataCmpPurpose'] = DEFAULT_DATA_CMP_PURPOSE,
) => {
    let params = ``
    if (environment) {
        const {gtm_auth, gtm_preview} = environment
        params = `&gtm_auth=${gtm_auth}&gtm_preview=${gtm_preview}&gtm_cookies_win=x`
    }
    return `
        <iframe  
            src="${customDomain}/ns.html?id=${id}${params}" 
            height="0" 
            width="0" 
            style="display:none;visibility:hidden" 
            id="tag-manager"
        ></iframe>
    `
}
