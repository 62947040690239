import DigitalProductDetails from "../components/digital-product-details";
import React, {useContext, useEffect, useState} from "react";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import {
    DigitalProductDetails as IDigitalProductDetails
} from "../../core/models/interfaces/digital-product-details.interface";
import useAuth from "../../auth/hooks/use-auth";
import FileHelper from "../../../utils/file-helper";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";


const PreviewDigitalProductDetailsView = () => {
    const {currentUser} = useAuth();
    const {setPreviewModeOn, previewModeOn, methods, mode, editProductState} = useFormDigitalProduct();
    const [product, setProduct] = useState<IDigitalProductDetails | null>(null);
    const {productCategories} = useContext(SessionCreatorToolsContext)!;

    const goBackHandler = () => {
        setPreviewModeOn(false);
    }

    const mapFormDataToPreviewProductDetails = async (): Promise<IDigitalProductDetails> => {
        const formBody = methods.getValues();
        const imageBase64 = formBody.productPicture ? await FileHelper.convertFileToBase64(formBody.productPicture) : ""
        const files = formBody.files
            ? await Promise.all(
                formBody.files.map(async (el, index) => {
                    const fileBase64 = await FileHelper.convertFileToBase64(el.file);
                    return {
                        id: mode === "edit"
                            ? editProductState?.files.find(f => f.uuid === el.uuid)?.id ?? null
                            : null,
                        uuid: mode === "edit" ? el.uuid : "",
                        ordinalNumber: mode === "edit" ? el.ordinalNumber : index,
                        fileFormat: FileHelper.getFileExtension(el.file)!,
                        fileName: FileHelper.getFileNameWithoutExtension(el.file),
                        fileSize: el.file.size,
                        base64: fileBase64,
                    };
                })
            )
            : [];

        return {
            edited: "empty",
            description: formBody.description || "Empty description",
            price: formBody.price,
            creatorName: currentUser?.firstname + " " + currentUser?.lastname,
            aliasName: formBody.aliasName,
            name: formBody.name || "Empty name",
            fileFormats: "",
            productSize: 0,
            files,
            id: mode === "edit" ? editProductState?.id! : "",
            productPicture: {
                base64: imageBase64,
                fileSize: 0,
                fileFormat: "",
                fileName: ""
            },
            owned: mode === "edit",
            ownerId: currentUser?.id!,
            creatorUsername: currentUser?.username!,
            deleted: null,
            category: {
                name: productCategories.find(p => p.id === formBody.categoryId)?.name ?? "category",
                id: formBody.categoryId,
                ordinalNumber:  productCategories.find(p => p.id === formBody.categoryId)?.ordinalNumber ?? 0,
            },
        }
    }

    useEffect(() => {
        mapFormDataToPreviewProductDetails().then(res => setProduct(res))
    }, [previewModeOn]);

    if (!previewModeOn) return null;

    return (
        <div className='pb-0 md:pb-8'>
            <DigitalProductDetails
                product={product}
                backEventPath=""
                customEventBack={goBackHandler}
            />
        </div>
    )
}

export default PreviewDigitalProductDetailsView
