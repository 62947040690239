import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import React, {useContext} from "react";
import {
    DataConfirmationUpdateSubscriptionModal
} from "../models/interfaces/data-confirmation-update-subscription-modal.interface";
import SubscriptionMarker from "./ui/subscription-marker";
import AppButton from "../../common/components/app-button";
import SubscriptionRenewableStatsCard from "../../statistics/components/ui/subscription-renewable-stats.card";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {SessionCreatorToolsContext} from "../../digital-products/contexts/session-creator-tools-context";
import {useFormSubscriptionOffer} from "../hooks/use-form-subscription-offer.hook";

interface ConfirmationUpdateSubscriptionModalProps {
    appendTo?: string | React.RefObject<HTMLElement> | null;
    onConfirm?: () => void;
    onCancel?: () => void;
}

const ConfirmationUpdateSubscriptionModal = (props: ConfirmationUpdateSubscriptionModalProps) => {
    const {appendTo, onConfirm, onCancel} = props;
    const {data} = useModalWithData<DataConfirmationUpdateSubscriptionModal>(ModalIds.CONFIRMATION_UPDATE_SUBSCRIPTION);
    const {methods} = useFormSubscriptionOffer()
    const showExtraInfo: boolean = (data?.totalSubs ?? 0) > 0;

    return (
        <AppModal
            key={ModalIds.CONFIRMATION_UPDATE_SUBSCRIPTION}
            id={ModalIds.CONFIRMATION_UPDATE_SUBSCRIPTION}
            appendTo={appendTo}
            disableScroll={true}
            zIndex={50}
            classModal="md:min-w-[548px] p-6"
        >
            <aside className="flex flex-col gap-4 bg-white rounded-lg">
                <header className="flex items-center gap-2">
                    <SubscriptionMarker/>
                    <h3 className="font-semibold text-black tracking-[1px] text-[0.625rem] leading-[0.75rem] uppercase">Save
                        changes</h3>
                </header>

                <h2 className="font-semibold text-black text-[1.375rem] leading-[1.65rem]">
                    Do you really want to save changes?
                </h2>

                <p className="text-sm font-medium text-special-gray leading-[1.1375rem]">
                    Remember! You can do changes one time per 24h
                </p>

                {showExtraInfo && (
                    <p className="text-sm font-medium text-special-gray leading-[1.1375rem]">
                        All your fans who have an active subscription will have their subscription canceled.
                    </p>
                )}

                {showExtraInfo && (
                    <SubscriptionRenewableStatsCard
                        yearlySubscriptions={data?.yearlySubs ?? 0}
                        monthlySubscriptions={data?.monthlySubs ?? 0}
                    />
                )}

                <div className="flex gap-3 justify-center mt-4">
                    <AppButton
                        type='submit'
                        onClick={onCancel}
                        disabled={methods.formState.isSubmitting}
                        label="No"
                        disabledClassName="!bg-gray-300"
                        className='!text-sm font-semibold !bg-primary text-white !rounded-2xl w-full hover:brightness-110 focus:brightness-110 whitespace-nowrap'
                    />
                    <AppButton
                        type='submit'
                        onClick={onConfirm}
                        loading={methods.formState.isSubmitting}
                        label="Yes"
                        loaderColor="text-black"
                        className='!text-sm font-semibold border-special-gray !bg-white text-special-gray !rounded-2xl w-full hover:text-primary-darken focus:text-primary-darken whitespace-nowrap'
                    />
                </div>
            </aside>
        </AppModal>
    );
};

export default ConfirmationUpdateSubscriptionModal;
