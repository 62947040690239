import * as yup from "yup";
import {PostType} from "../../core/models/enums/post-type.enum";

export const schemaCreatePost = yup.object().shape({
    userId: yup
        .string()
        .required("User ID is required"),

    image: yup
        .mixed<File>()
        .nullable()
        .test("file-or-null", "Image must be a File or null", (value) => {
            return value === null || value instanceof File;
        })
        .default(null),

    video: yup
        .mixed<File>()
        .nullable()
        .test("file-or-null", "Image must be a File or null", (value) => {
            return value === null || value instanceof File;
        })
        .default(null),

    description: yup
        .string()
        .nullable()
        .max(1000, "Max length is 1000 characters")
        .transform((value) => (value === "" ? null : value)),

    postType: yup
        .mixed<PostType>()
        .oneOf(Object.values(PostType), "Invalid post type")
        .default(PostType.PUBLIC)
        .required("Post type is required"),

    otherPriceForSubscribers: yup
        .boolean()
        .default(false),

    price: yup
        .number()
        .transform((value, originalValue) => {
            const numberValue = parseFloat(originalValue);
            return isNaN(numberValue) ? null : numberValue;
        })
        .nullable()
        .typeError("Price must be a number")
        .when("postType", {
            is: PostType.PAID,
            then: (schema) => schema
                .required("Price is required when post type is PAID")
                .min(0.01, "Price must be at least $0.01"),
        }),

    priceForSubscribers: yup
        .number()
        .transform((value, originalValue) => {
            const numberValue = parseFloat(originalValue);
            return isNaN(numberValue) ? null : numberValue;
        })
        .nullable()
        .typeError("Price must be a number")
        .when(["postType", "otherPriceForSubscribers"], {
            is: (postType: PostType, otherPriceForSubscribers: boolean) =>
                postType === PostType.PAID && otherPriceForSubscribers,
            then: (schema) => schema
                .required("Price for subscribers is required when post type is PAID and 'otherPriceForSubscribers' is true")
                .min(0.01, "Price must be at least $0.01")
                .max(
                    yup.ref("price"),
                    "Subscriber price can't be greater than price for all fans"
                ),
        })
})
    .test(
        "atLeastOneField",
        "At least one of 'imageBase64', 'videoBase64', or 'description' must be provided",
        function (values) {
            const {image, video, description} = values;
            if (!image && !video && !description) {
                return this.createError({
                    path: "description",
                    message: "At least one of media or description must be provided",
                });
            }
            return true;
        }
    )
    .required();
