import React, {useEffect, useState} from "react";
import {
    DigitalProductDetails as IDigitalProductDetails
} from "../../core/models/interfaces/digital-product-details.interface";
import {useDigitalProductApi} from "../../core/hooks/use-digital-product-api";
import {useParams} from "react-router-dom";
import DigitalProductDetails from "../components/digital-product-details";
import useAuth from "../../auth/hooks/use-auth";


const DigitalProductDetailsView = () => {
    const [product, setProduct] = useState<IDigitalProductDetails | null>(null);
    const {getDigitalProduct} = useDigitalProductApi();
    const {currentUser} = useAuth();
    const params = useParams();

    useEffect(() => {
        const aliasName = params['aliasName']
        if (aliasName) {
            getDigitalProduct(aliasName, currentUser?.username!).then(product => setProduct(product))
        }
    }, [params]);

    return <DigitalProductDetails product={product} backEventPath="../digital-products"/>
};

export default DigitalProductDetailsView;
