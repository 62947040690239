import React from "react";
import {Navigate, NavLink, Route, Routes} from "react-router-dom";
import GuardRestrictedRoute from "../features/auth/components/guard-restricted-route";
import {UserAuthority} from "../features/auth/models/enums/user-authority.enum";
import MessagesPage from "./messages-page";
import AddPostFormView from "../features/feed-and-wall/views/add-post-form-view";
import AppHeader from "../layout/app-header";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import Breadcrumbs from "../layout/components/breadcrumbs";
import AppHamburgerMenu from "../layout/components/app-hamburger-menu";

const FeedPage = () => {
    return (
        <Routes>
            <Route index element={
                <>
                    <AppHeader customContent={true}>
                        <div className='flex justify-between flex-wrap gap-5'>
                            <div className='flex flex-col gap-5'>
                                <div className='flex items-center w-fit'>
                                    <div className='mr-3'><AppHamburgerMenu/></div>
                                    <Breadcrumbs path='Feed'/>
                                </div>
                            </div>
                        </div>
                    </AppHeader>
                    <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] pt-6 sm:pt-9'>
                        {/*<FeedManagerProvider>*/}
                        {/*    <FeedWallSwitcher/>*/}
                        {/*    <FeedAndWall/>*/}
                        {/*</FeedManagerProvider>*/}
                        <NavLink to="./add-post">ADD POST</NavLink>
                    </AppContentWrapper>
                </>
            }/>
            <Route path='/add-post' element={
                <GuardRestrictedRoute requiredRoles={[UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]}>
                    <AddPostFormView/>
                </GuardRestrictedRoute>
            }/>
            <Route path='/messages' element={<MessagesPage/>}/>
            <Route path="*" element={<Navigate to="/not-found"/>}/>
        </Routes>
    );
}

export default FeedPage
