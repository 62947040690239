import * as yup from "yup";
import {TempProductFile} from "../models/interfaces/product-file.interface";
import FileHelper from "../../../utils/file-helper";
import {allowedProductPictureFormats} from "./allowed-product-picture-formats";

export const schemaAddProduct = yup.object().shape({
    name: yup
        .string()
        .max(100, 'Max length is 100 characters')
        .required('Name is required'),

    aliasName: yup
        .string()
        .required('aliasName is required'),

    categoryId: yup
        .string()
        .required('Category is required'),

    description: yup
        .string()
        .nullable()
        .max(1000, 'Max length is 1000 characters'),

    price: yup
        .number()
        .nullable()
        .typeError('Price must be a number')
        .required('Price is required')
        .min(0, 'Price must be a positive number'),

    productPicture: yup
        .mixed()
        .nullable()
        .test("fileType", "Only jpg, svg, png, or jpeg files are allowed", (value) => {
            if (!value) return true;

            if (value instanceof File) {
                const fileType = FileHelper.getExtensionFromMimeType(value.type);
                return allowedProductPictureFormats.includes(fileType);
            }

            return false;
        })
        .test("fileSize", "Image must be under 5MB", (value) => {
            if (!value) return true;

            if (value instanceof File) {
                return value.size <= 5 * 1024 * 1024;
            }

            return false;
        }),

    files: yup
        .array()
        .of(
            yup.mixed().nullable().required("File is required")
        )
        .test("totalSize", (files, context) => {
            if (!files || files.length === 0) {
                return context.createError({
                    message: "At least one file is required",
                });
            }
            const totalSize = (files as TempProductFile[]).reduce((sum, file) => sum + (file?.file.size || 0), 0);

            if (totalSize > 50 * 1024 * 1024) {
                const totalSizeMb = (totalSize / (1024 * 1024)).toFixed(2);
                return context.createError({
                    message: `Total size of all files is ${totalSizeMb}MB, but must be under 50MB.`,
                });
            }

            return true;
        })
        .min(1, "At least one file is required")
        .required("Files are required"),
}).required();
