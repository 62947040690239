import React from "react";
import {ReactComponent as ChatIcon} from "@assets/icons/chat.svg";
import {ReactComponent as VoiceIcon} from "@assets/icons/audio.svg";
import {ReactComponent as VideoIcon} from "@assets/icons/video.svg";
import WrapperCard from "../../../common/components/wrapper-card";

interface BenefitsSubscriptionCardProps {
    freeMessageBlock: number,
    freeVoiceCall: number,
    freeVideoCall: number,
}

const BenefitsSubscriptionCard = (props: BenefitsSubscriptionCardProps) => {
    const {
        freeMessageBlock,
        freeVideoCall,
        freeVoiceCall
    } = props

    return (
        <WrapperCard className="flex flex-col gap-5 w-full !border-gray-light !rounded-xl">
            <div className="flex fap-3 justify-between">
                <span className="text-sm leading-[1.05rem] font-medium text-special-gray">
                    Text message block
                </span>
                <span className="inline-flex items-center gap-2 font-semibold leading-[1.2rem]">
                    {freeMessageBlock}
                    <ChatIcon className="w-4 h-4"/>
                </span>
            </div>
            <div className="flex fap-3 justify-between">
                <span className="text-sm leading-[1.05rem] font-medium text-special-gray">
                    Voice call (x1 min)
                </span>
                <span className="inline-flex items-center gap-2 font-semibold leading-[1.2rem]">
                    {freeVoiceCall}
                    <VoiceIcon className="w-4 h-4"/>
                </span>
            </div>
            <div className="flex fap-3 justify-between">
                <span className="text-sm leading-[1.05rem] font-medium text-special-gray">
                    Video call (x1 min)
                </span>
                <span className="inline-flex items-center gap-2 font-semibold leading-[1.2rem]">
                    {freeVideoCall}
                    <VideoIcon className="w-[0.875rem] h-4"/>
                </span>
            </div>
        </WrapperCard>
    )
}

export default BenefitsSubscriptionCard
