import React, { useRef, useState } from 'react';
import { useFormContext } from "react-hook-form";
import { classNames } from "../../../../utils/class-names";

interface CounterFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    min?: number;
    max?: number;
    step?: number;
    className?: string;
    formatValue?: (value: number) => string;
}

const CounterFormField: React.FC<CounterFormFieldProps> = (props: CounterFormFieldProps) => {
    const { register, setValue, getValues, watch, formState: { errors, isSubmitting } } = useFormContext();
    const [isFocused, setIsFocused] = useState(false);
    const parentInputRef = useRef<HTMLDivElement>(null); // Dodanie referencji do inputa
    const {
        name,
        label,
        min = 0,
        max,
        step = 1,
        className = '',
        formatValue,
        disabled,
        ...rest
    } = props;
    const value = watch(name);

    const handleIncrement = () => {
        let currentValue = parseFloat(getValues(name)) || 0;
        let newValue = currentValue + step;
        if (max !== undefined && newValue > max) newValue = max;
        newValue = formatValue ? parseFloat(formatValue(newValue)) : newValue;
        setValue(name, newValue,{shouldDirty:true});
    };

    const handleDecrement = () => {
        let currentValue = parseFloat(getValues(name)) || 0;
        let newValue = currentValue - step;
        if (newValue < min) newValue = min;
        newValue = formatValue ? parseFloat(formatValue(newValue)) : newValue;
        setValue(name, newValue,{shouldDirty:true});
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (disabled) return;
        if (e.key === 'ArrowUp' || e.key === 'ArrowRight') {
            e.preventDefault();
            handleIncrement();
        } else if (e.key === 'ArrowDown' || e.key === 'ArrowLeft') {
            e.preventDefault();
            handleDecrement();
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = parseFloat(e.target.value) || 0;
        console.log(newValue)
        if (newValue < min) {
            newValue = min;
        } else if (max !== undefined && newValue > max) {
            newValue = max;
        }
        setValue(name, newValue,{shouldDirty:true});
    };

    const handleBlur = () => {
        let currentValue = parseFloat(getValues(name)) || 0;
        if (currentValue < min) {
            setValue(name, min,{shouldDirty:true});
        } else if (max !== undefined && currentValue > max) {
            setValue(name, max,{shouldDirty:true});
        }
        setIsFocused(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
        (parentInputRef.current?.childNodes[2] as HTMLInputElement | undefined)?.focus();
    };

    return (
        <div
            tabIndex={0}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={classNames(
                `cursor-text flex justify-between items-center border-2 border-gray-light rounded-2xl p-3 appearance-none w-full`,
                `focus:outline-none focus:ring-2 focus:ring-primary`,
                isFocused && "outline-none ring-2 ring-primary",
                errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400',
                className,
                (disabled) && "bg-gray-light text-gray"
            )}
        >
            <label htmlFor={name} className="text-special-gray font-medium text-sm leading-[1.05rem]">
                {label}
            </label>
            <div ref={parentInputRef} className="flex items-center">
                <button
                    tabIndex={-1}
                    type="button"
                    onClick={handleDecrement}
                    disabled={isSubmitting || disabled}
                    className="px-3 py-1 border border-special-gray rounded-xl bg-transparent text-black focus:font-bold focus:text-primary-darken hover:font-bold hover:text-primary-darken disabled:opacity-50"
                >
                    −
                </button>
                <span
                    className={classNames(
                        "w-16 flex items-center justify-center h-fit font-semibold text-black text-base leading-[1.2rem] relative"
                    )}
                >
                    {value}
                    {isFocused && <span className="blinking-caret h-4"></span>}
                </span>
                <input
                    type="number"
                    id={name}
                    className={classNames(
                        `focus:ring-0 focus:outline-none w-0 max-w-0  opacity-0 absolute z-[-10]`,
                        errors[name] && "text-red-500"
                    )}
                    disabled={disabled}
                    {...register(name)}
                    onInput={handleInputChange}
                    {...rest}
                />
                <button
                    tabIndex={-1}
                    type="button"
                    onClick={handleIncrement}
                    disabled={isSubmitting || disabled}
                    className="px-3 py-1 border border-special-gray rounded-xl bg-transparent text-black focus:font-bold focus:text-primary-darken hover:font-bold hover:text-primary-darken disabled:opacity-50"
                >
                    +
                </button>
            </div>
        </div>
    );
};

export default CounterFormField;
