import {NavLink} from 'react-router-dom';
import SubmenuTab from "../../core/components/submenu-tab";
import {PurchasesSubmenu} from "../constants/purchases-submenu";
import {useContext, useMemo, useRef} from "react";
import {PurchasesSubscriptionsContext} from "../contexts/purchases-subscriptions-context";
import useAuth from "../../auth/hooks/use-auth";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import useEffectOnce from "../../common/hooks/use-effect-once";
import {getSubscriptionStatus} from "../../subscriptions/helpers/get-subscription-status";

const PurchasesMenu = () => {
    const {subscriptions} = useContext(PurchasesSubscriptionsContext)!;
    const baseLocation = "/app/purchases";
    const {currentUser} = useAuth();
    const activeTabRef = useRef<HTMLDivElement | null>(null);

    const MappedSubmenu = useMemo(() => {
        return PurchasesSubmenu
            .map(item => {
                if (item.href === "/subscriptions") {
                    item.badge = subscriptions.filter(sub =>{
                        return !getSubscriptionStatus(sub).isCanceled
                    }).length || null;
                }
                return item;
            })
            .map(item => {
                if (item.href === "/subscriptions" && currentUser?.authority === UserAuthority.CONTENT_CREATOR) {
                    item.text = "Subscriptions purchased"
                }
                return item;
            })
    }, [subscriptions])

    useEffectOnce(()=>{
        if (activeTabRef.current) {
            activeTabRef.current.scrollIntoView({
                behavior: "auto",
                block: "nearest",
            });
        }
    })

    return (
        <div className='overflow-y-clip overflow-x-auto flex gap-2 cursor-pointer'>
            {MappedSubmenu.map(item => (
                <NavLink
                    key={item.href}
                    to={baseLocation + item.href}
                    end={true}
                >
                    {({ isActive }) => (
                        <div
                            ref={isActive ? activeTabRef : null}
                        >
                            <SubmenuTab
                                badge={item.badge}
                                key={item.href}
                                text={item.text}
                                isActive={isActive}
                            />
                        </div>
                    )}
                </NavLink>
            ))}
        </div>
    );
}

export default PurchasesMenu;
