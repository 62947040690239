import {classNames} from "../../../../utils/class-names";

interface BadgeDaysToDeleteProps {
    differenceDays: number,
    label: string,
}

const BadgeDaysToDelete = ({differenceDays,label}: BadgeDaysToDeleteProps) => {

    return (
        <div className={classNames(
            "w-fit px-2 py-0 rounded-full",
            differenceDays > 7 && "bg-gray-light text-special-gray",
            differenceDays <= 7 && "bg-semantic-danger-pastel text-semantic-danger"
        )}>
            <span className="leading-[0.9rem] text-xs font-medium">
                {differenceDays > 0 ? label : "DELETED"}
            </span>
        </div>
    )
}

export default BadgeDaysToDelete
