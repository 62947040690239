import {Navigate, Route, Routes} from "react-router-dom";
import React from "react";
import PurchasedSubscriptionsView from "../features/purchases/views/purchased-subscriptions-view";

const PurchasesPage = () => {
    return (
        <Routes>
            {/*<Route index element={<PurchasedSubscriptionsView/>}/>*/}
            {/*<Route index element={<PurchasedSubscriptionsView/>}/>*/}
            {/*<Route index element={<PurchasedProductsView/>}/>*/}
            <Route path='/subscriptions' element={<PurchasedSubscriptionsView/>}/>
            <Route path="*" element={<Navigate to="/not-found"/>}/>
        </Routes>
    )
}

export default PurchasesPage
