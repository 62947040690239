import { useState } from "react";
import DigitalProductCategoryApiService from "../services/digital-product-categories-api.service";
import {GetAllDigitalCategories} from "../models/interfaces/get-all-digital-categories.interface";
import {
    PostCreateDigitalProductCategoryBodyRequest
} from "../models/interfaces/post-digital-product-category-body-request.interface";
import {PutEditDigitalProductCategory} from "../models/interfaces/put-edit-digital-product-category.interface";
import {PutChangeOrdinalNumberRequestBody} from "../models/interfaces/put-change-ordinal-number-request-body.interface";
import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";

export const useDigitalProductCategoryApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getAllCategories = async (): Promise<GetAllDigitalCategories> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductCategoryApiService.getAllCategories();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const createDigitalProductCategory = async (data: PostCreateDigitalProductCategoryBodyRequest): Promise<string> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductCategoryApiService.createCategory(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const editDigitalProductCategory = async (data: PutEditDigitalProductCategory): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductCategoryApiService.editCategory(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deleteDigitalProductCategory = async (id: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductCategoryApiService.deleteCategory(id);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const changeOrdinalNumber = async (data: PutChangeOrdinalNumberRequestBody): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductCategoryApiService.changeOrdinalNumber(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const editAllCategories = async (data: Array<DigitalProductCategory>): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await DigitalProductCategoryApiService.editAll(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const initCategories = async() : Promise<void> => {
        setLoading(true);
        setError(null);
        try{
            await DigitalProductCategoryApiService.initCategories();
        } catch(err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        error,
        getAllCategories,
        createDigitalProductCategory,
        editDigitalProductCategory,
        deleteDigitalProductCategory,
        changeOrdinalNumber,
        editAllCategories,
        initCategories
    };
};
