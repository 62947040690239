import React, {useState} from 'react';
import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import AppButton from "../../common/components/app-button";
import {useUserProfileApi} from "../hooks/use-user-profile-api";
import useModalWithData from "../../common/hooks/use-modal-with-data";

export interface DataFollowRequiredModal {
    creatorId: string,
    onSuccessFollow: () => void;
    creatorUsername: string;
}

const FollowRequiredModal: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const {data, hideModal} = useModalWithData<DataFollowRequiredModal>(ModalIds.FOLLOW_REQUIRED);
    const {followCreator} = useUserProfileApi();

    const handleCloseModal = () => {
        hideModal();
        data?.onSuccessFollow && data?.onSuccessFollow();
    }

    const handleFollowingCC = async () => {
        setLoading(true)
        try {
            if (data?.creatorId) {
                await followCreator(data.creatorId, data.creatorUsername);
                handleCloseModal();
            }
        } catch (err: any) {
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return (
        <AppModal
            zIndex={40}
            forceFixed={true}
            key={ModalIds.FOLLOW_REQUIRED}
            id={ModalIds.FOLLOW_REQUIRED}
            disableScroll={true}>
            <div>
                <h2 className="text-xl font-semibold mb-4">Follow Required</h2>
                <p className="text-gray mb-6">You need to following Content Creator for perform this action.</p>
                <div className="flex justify-end gap-4">
                    <AppButton
                        onClick={handleCloseModal}
                        className="text-primary-darken border-0 px-4 py-2 rounded-lg border-primary-darken hover:border transition"
                        label='Cancel'
                    />
                    <AppButton
                        onClick={handleFollowingCC}
                        disabled={loading}
                        className="bg-primary text-white px-4 py-2 rounded-lg hover:bg-primary-darken transition"
                        label='Follow'
                    />
                </div>
            </div>
        </AppModal>
    );
};

export default FollowRequiredModal;
