import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {classNames} from "../../../utils/class-names";

interface SubmenuTabProps {
    isActive?: boolean,
    text: string,
    onClick?: UniversalClickHandler,
    badge?: number | null,
}

const SubmenuTab = (props: SubmenuTabProps) => {
    const {
        isActive = false,
        text,
        onClick,
        badge
    } = props;

    const handleClick = async () => {
        if (onClick) await onClick();
    }

    return (
        <div
            className={classNames(
                "h-fit w-fit text-primary-darken text-[15px] rounded-2xl px-4 py-2 transition whitespace-nowrap flex gap-2 items-center justify-center shadow-tile-shadow",
                isActive ? "font-bold bg-white " : "font-medium hover:bg-[rgba(255,255,255,0.4)]"
            )}
            onClick={handleClick}
        >
            <span className='first-letter:uppercase'>
                {text}
            </span>
            {!!badge && (
                <span className={classNames(
                    "rounded-full h-6 w-6 min-w-6 max-w-6 font-semibold text-xs flex items-center justify-center",
                    isActive ? "bg-primary text-white" : "bg-[rgba(255,255,255,0.4)] text-primary-darken"
                )}>
                    {badge}
                </span>
            )}
        </div>
    )
}

export default SubmenuTab
