import {MessageText as SCRMessageText, renderText, StreamMessage, useChannelActionContext} from "stream-chat-react";
import React, {useCallback, MouseEvent} from "react";
import {ReactComponent as ReplyIcon} from "@assets/icons/reply.svg";

interface QuotedMessageProps {
    message?: StreamMessage;
}

const QuotedMessage = ({message}: QuotedMessageProps) => {
    const {jumpToMessage} = useChannelActionContext();

    let rootClass = "font-medium leading-[0.9rem] text-xs underline"

    const modifiedMessage: StreamMessage = {
        ...message!,
        quoted_message: undefined,
        attachments: undefined,
    }

    const highlightElement = useCallback((el: HTMLElement) => {
        el.classList.add('highlighted-message');
        setTimeout(() => {
            el.classList.remove('highlighted-message');
        }, 1500);
    }, []);

    const handleClick = async (event: MouseEvent<HTMLDivElement>): Promise<void> => {
        event.stopPropagation();
        event.preventDefault();
        try {
            if (message?.id) {
                await jumpToMessage(message.id)
                setTimeout(()=>{
                    let el = document.querySelector<HTMLLIElement>(`[data-message-id="${message.id}"]`);
                    el && highlightElement(el);
                },100)
            }
        } catch (error: any) {
            console.error(error);
            throw error;
        }
    }

    return (
        <div
            data-getstream-outside-message-options={true}
            onClick={handleClick}
            className='quoted-message flex gap-1 justify-end items-center font-medium leading-[0.9rem] text-xs text-special-gray cursor-pointer'>
            <div className='flex gap-1 items-center w-fit min-w-fit'>
                <ReplyIcon className='w-[0.625rem] h-fit max-h-[8.71px] min-w-[0.625rem]'/>
                Reply to
            </div>
            <SCRMessageText
                customWrapperClass="truncate line-clamp-2"
                renderText={renderText}
                message={modifiedMessage}
                customInnerClass={rootClass}/>
        </div>
    )
}

export default QuotedMessage
