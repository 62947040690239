import {initializeApp} from "firebase/app";
import {deleteToken, getMessaging, getToken, Messaging, onMessage} from "firebase/messaging";
import {firebaseConfig} from "./firebaseConfig";
import {Firebase} from "./firebase.enum";
import {environment} from "../environments/environment";
import NotificationService from "../features/common/services/notification.service";

// Initialize Firebase App
export const firebaseApp = initializeApp(firebaseConfig, Firebase.NAME);

class FirebaseMessaging {
    private messaging: Messaging | undefined;

    constructor() {
        this.init();
    }

    async init() {
        this.messaging = getMessaging(firebaseApp);
        this.handleForegroundMessages();
    }

    async getFirebaseToken(vapidKey: string, serviceWorker: ServiceWorkerRegistration) {
        if (!this.messaging) {
            console.warn("Firebase Messaging is not initialized or supported.");
            return null;
        }

        try {
            return await getToken(this.messaging, {
                vapidKey,
                serviceWorkerRegistration: serviceWorker
            });
        } catch (error) {
            console.error("Error getting Firebase token:", error);
            throw error;
        }
    }

    async deleteFirebaseToken() {
        if (!this.messaging) {
            console.warn("Firebase Messaging is not initialized.");
            return;
        }

        try {
            await deleteToken(this.messaging);
            console.log("Firebase token deleted successfully.");
        } catch (error) {
            console.error("Error deleting Firebase token:", error);
        }
    }

    handleForegroundMessages() {
        if (!this.messaging) {
            return;
        }

        onMessage(this.messaging, (payload) => {
            console.log("Message received in foreground:", payload);

            const urlToOpen = payload.data?.url || environment.frontendUrl;
            if (Notification.permission === "granted") {
                const notificationTitle = payload.data?.title || "New Notification";
                const notificationOptions = {
                    body: payload.data?.body || "You have a new message",
                    icon: payload.data?.icon || "/assets/icons/icon-128x128.png",
                    image: payload.data?.imageUrl,
                    data: {
                        url: urlToOpen
                    }
                };

                // Show the notification
                const notification = new Notification(notificationTitle, notificationOptions);

                // Handle click event on notification
                notification.onclick = () => {
                    window.open(urlToOpen, "_blank");
                };
            } else {
                console.warn("Notifications are not enabled or permission was not granted.");
            }
        });
    }
}

export const firebaseMessaging = new FirebaseMessaging();
