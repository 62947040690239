import CustomSendButton from "./custom-send-button";
import AttachmentUploadButton from "./attachment-upload-button";
import CustomVoiceButton from "./custom-voice-button";
import {UniversalClickHandler} from "../../../common/models/types/universal-click-handler.type";

interface ActionButtonsProps {
    text: string;
    attachmentsLength: number;
    isRecording: boolean;
    startRecording: UniversalClickHandler
    stopRecording: UniversalClickHandler;
    sendMessage: (event: any) => void;
}

const ActionButtons = (props: ActionButtonsProps) => {
    const {
        text,
        attachmentsLength,
        isRecording,
        startRecording,
        stopRecording,
        sendMessage
    } = props;

    return (
        <div className="self-center mr-4 flex" style={{userSelect: "none"}}>
            {text || attachmentsLength > 0 ? (
                <div className="flex justify-right self-center">
                    {!isRecording && (
                        <>
                            <CustomSendButton sendMessage={sendMessage}/>
                            <div className="self-center w-fit h-fit p-2 pr-0">
                                <AttachmentUploadButton/>
                            </div>
                        </>
                    )}
                    <div className="self-center w-fit h-fit p-2 pr-0">
                        <CustomVoiceButton
                            startRecording={startRecording}
                            stopRecording={stopRecording}
                            isRecording={isRecording}/>
                    </div>
                </div>
            ) : (
                <>
                    {!isRecording && (
                        <div className="self-center w-fit h-fit p-2 pr-0">
                            <AttachmentUploadButton/>
                        </div>
                    )}
                    <div className="self-center w-fit h-fit p-2 py-0 pr-0">
                        <CustomVoiceButton
                            startRecording={startRecording}
                            stopRecording={stopRecording}
                            isRecording={isRecording}/>
                    </div>
                </>
            )}
        </div>
    );
};

export default ActionButtons;
