export const RegistrationSteps = {
    INITIAL_REGISTRATION: 'INITIAL_REGISTRATION',
    EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
    BECOME_CREATOR: 'BECOME_CREATOR',
    COMPLETED: 'COMPLETED'
} as const;

export const CriticalSteps = {
    LEGACY_USERNAME_REQUIRED: 'LEGACY_USERNAME_REQUIRED',
    LEGACY_EMAIL_VERIFICATION: 'LEGACY_EMAIL_VERIFICATION',
} as const;
