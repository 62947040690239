import { useState } from 'react';
import { PeriodSubscription } from '../models/enums/period-subscription.enum';
import { GetPurchasedSubscriptions } from '../models/interfaces/get-purchased-subscriptions.interface';
import SubscriptionApiService from "../services/subscription-api-service";
import {UpdateSubscriptionOfferRequest} from "../models/interfaces/update-subscription-offer-request.interface";
import {ConsequencesUpdateSubOffer} from "../models/interfaces/consequences-update-sub-offer.interface";
import {CreatorSubscriptionOffer} from "../models/interfaces/creator-subscription-offer.interface";
import {OwnSubscriptionOffer} from "../models/interfaces/own-subscription-offer.interface";

export const useSubscriptionApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const updateSubscriptionOffer = async (data: UpdateSubscriptionOfferRequest): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await SubscriptionApiService.updateSubscriptionOffer(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const showConsequencesOfEditingOffer = async (data: UpdateSubscriptionOfferRequest): Promise<ConsequencesUpdateSubOffer> => {
        setLoading(true);
        setError(null);
        try {
            return await SubscriptionApiService.showConsequencesOfEditingOffer(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getOwnSubscriptionOffer = async (): Promise<OwnSubscriptionOffer> => {
        setLoading(true);
        setError(null);
        try {
            return await SubscriptionApiService.getOwnSubscriptionOffer();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getCreatorSubscriptionOffer = async (creatorId: string): Promise<CreatorSubscriptionOffer> => {
        setLoading(true);
        setError(null);
        try {
            return await SubscriptionApiService.getCreatorSubscriptionOffer(creatorId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    // const buySubscription = async (subscriptionId: string, period: PeriodSubscription): Promise<number> => {
    //     setLoading(true);
    //     setError(null);
    //     try {
    //         return await SubscriptionApiService.buySubscription(subscriptionId, period);
    //     } catch (err: any) {
    //         setError(err.message);
    //         throw err;
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const cancelSubscription = async (subscriptionId: string, fullNameSeller: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await SubscriptionApiService.cancelSubscription(subscriptionId,fullNameSeller);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const restoreSubscription = async (subscriptionId: string, fullNameSeller: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            await SubscriptionApiService.restoreSubscription(subscriptionId,fullNameSeller);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getPurchasedSubscriptions = async (): Promise<GetPurchasedSubscriptions> => {
        setLoading(true);
        setError(null);
        try {
            return await SubscriptionApiService.getPurchasedSubscriptions();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        updateSubscriptionOffer,
        showConsequencesOfEditingOffer,
        getOwnSubscriptionOffer,
        getCreatorSubscriptionOffer,
        cancelSubscription,
        restoreSubscription,
        getPurchasedSubscriptions,
    };
};
