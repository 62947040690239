import WrapperCard from "../../../common/components/wrapper-card";
import NumberFormattingService from "../../../common/services/number-formatting.service";

interface SubscriptionRenewableStatsCardProps {
    monthlySubscriptions: number;
    yearlySubscriptions: number;
}

const SubscriptionRenewableStatsCard = (props: SubscriptionRenewableStatsCardProps) => {
    const {
        monthlySubscriptions = 0,
        yearlySubscriptions = 0,
    } = props;

    return (
        <WrapperCard className="bg-gray-light border-0 flex flex-col gap-5 w-full p-6">
            <div className="flex flex-col">
                <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                    Number of Renewable Subscriptions
                </span>
                <span
                    className="h-[28px] inline-flex gap-1 items-center font-bold text-black text-[1.375rem] leading-[1.65rem]">
                    {NumberFormattingService.formatNumberWithSpaceSeparator(monthlySubscriptions + yearlySubscriptions)}
                </span>
            </div>
            <div className="grid gap-3 grid-cols-2">
                <div className="flex flex-col">
                    <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                        Monthly subs.
                    </span>
                    <span
                        className="h-[20px] inline-flex gap-1 items-center font-medium text-black text-base leading-[0.9rem]">
                        {NumberFormattingService.formatNumberWithSpaceSeparator(monthlySubscriptions)}
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                        Yearly subs.
                    </span>
                    <span
                        className="h-[20px] inline-flex gap-1 items-center font-medium text-black text-base leading-[0.9rem]">
                        {NumberFormattingService.formatNumberWithSpaceSeparator(yearlySubscriptions)}
                    </span>
                </div>
            </div>
        </WrapperCard>
    );
};

export default SubscriptionRenewableStatsCard;
