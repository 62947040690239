import {createContext, ReactNode, useEffect, useState} from "react";
import {useSubscriptionApi} from "../../core/hooks/use-subscription-api";
import {PurchasedSubscription} from "../../core/models/interfaces/purchased-subscription.interface";

interface PurchasesSubscriptionsContextProps {
    subscriptions: PurchasedSubscription[];
    loading: boolean;
    error: string | null;
    fetchOwnSubscriptions: () => Promise<void>;
    cancelSubscriptionHandler: (subscriptionId: string, fullNameSeller: string) => Promise<void>;
    restoreSubscriptionHandler: (subscriptionId: string, fullNameSeller: string) => Promise<void>;
}

interface PurchasesSubscriptionsProviderProps {
    children?: ReactNode;
    initFetch?: boolean;
}

export const PurchasesSubscriptionsContext = createContext<PurchasesSubscriptionsContextProps | undefined>(undefined);

export const PurchasesSubscriptionsProvider = ({children, initFetch = false}: PurchasesSubscriptionsProviderProps) => {
    const [subscriptions, setSubscriptions] = useState<PurchasedSubscription[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const {
        getPurchasedSubscriptions,
        cancelSubscription,
        restoreSubscription
    } = useSubscriptionApi();

    const fetchOwnSubscriptions = async () => {
        setLoading(true);
        try {
            const response = await getPurchasedSubscriptions();
            setSubscriptions(response);
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    const cancelSubscriptionHandler = async (subscriptionId: string, fullNameSeller: string): Promise<void> => {
        setError(null);
        try {
            await cancelSubscription(subscriptionId,fullNameSeller);
            setSubscriptions(prevState => {
                return prevState.map(sub => {
                    if (sub.id === subscriptionId) {
                        return {...sub, isRenewable: false}
                    }
                    return sub;
                })
            })
        } catch (err: any) {
            setError(err.message);
        }
    }

    const restoreSubscriptionHandler = async (subscriptionId: string, fullNameSeller: string): Promise<void> => {
        setError(null);
        try {
            await restoreSubscription(subscriptionId,fullNameSeller);
            setSubscriptions(prevState => {
                return prevState.map(sub => {
                    if (sub.id === subscriptionId) {
                        return {...sub, isRenewable: true}
                    }
                    return sub;
                })
            })
        } catch (err: any) {
            setError(err.message);
        }
    }

    useEffect(() => {
        if (initFetch) {
            fetchOwnSubscriptions();
        }
    }, [initFetch]);

    return (
        <PurchasesSubscriptionsContext.Provider
            value={{
                subscriptions,
                loading,
                error,
                fetchOwnSubscriptions,
                cancelSubscriptionHandler,
                restoreSubscriptionHandler
            }}>
            {children}
        </PurchasesSubscriptionsContext.Provider>
    );
};
