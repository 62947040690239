import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as InfoIcon } from "@assets/icons/info.svg";
import { ReactComponent as XMarkIcon } from "@assets/icons/cross.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit.svg";
import AppButton from "../../common/components/app-button";
import NotificationService from "../../common/services/notification.service";
import { allowedPostMediaFormats } from "../constants/allowed-post-media-formats";
import {PostMediaObject} from "../models/interfaces/post-media.interface";

interface PostMediaUploaderProps {
    label?: string;
    description?: string;
    maxFileSizeInMb?: number;
    allowedFormats?: string[];
    onMediaChange?: (media: PostMediaObject | null) => void;
    mediaFile?: File | null;
    isSubmittingSuccessful: boolean;
    isSubmitting: boolean;
    forceDisabled?: boolean;
}

const PostMediaUploader: React.FC<PostMediaUploaderProps> = (props) => {
    const [previewUrl, setPreviewUrl] = useState<string | null>(null);
    const [mediaType, setMediaType] = useState<"image" | "video" | null>(null);

    const {
        label = "Cover",
        description = "Max. 200MB in .jpg, .jpeg, .png, .mp4, .mov format",
        maxFileSizeInMb = 200,
        allowedFormats = allowedPostMediaFormats,
        onMediaChange,
        isSubmittingSuccessful,
        isSubmitting,
        mediaFile,
        forceDisabled = false,
    } = props;

    const inputRef = useRef<HTMLInputElement>(null);

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFile = event.target.files?.[0];

        if (uploadedFile) {
            const fileExtension = uploadedFile.name.split(".").pop()?.toLowerCase();
            const isAllowed = allowedFormats.includes(fileExtension || "");
            const isUnderSizeLimit = uploadedFile.size <= maxFileSizeInMb * 1024 * 1024;

            if (!isAllowed) {
                NotificationService.warning(`Invalid file format. Allowed: ${allowedFormats.join(", ")}`, '', { duration: 5000 });
            } else if (!isUnderSizeLimit) {
                NotificationService.warning(`File size exceeded. Max allowed size is ${maxFileSizeInMb}MB.`, '', { duration: 5000 });
            } else {
                const fileUrl = URL.createObjectURL(uploadedFile);
                const fileType = uploadedFile.type.startsWith("video") ? "video" : "image";

                setPreviewUrl(fileUrl);
                setMediaType(fileType);

                onMediaChange && onMediaChange({ source: uploadedFile, type: fileType });
            }
        }
    };

    const handleRemoveFile = () => {
        setPreviewUrl(null);
        setMediaType(null);
        onMediaChange && onMediaChange({ source: null, type: "empty" });
    };

    const handleOpenExplorerFiles = () => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    };

    useEffect(() => {
        if (isSubmittingSuccessful) handleRemoveFile();
    }, [isSubmittingSuccessful]);

    useEffect(() => {
        if (mediaFile) {
            const fileUrl = URL.createObjectURL(mediaFile);
            const fileType = mediaFile.type.startsWith("video") ? "video" : "image";
            setPreviewUrl(fileUrl);
            setMediaType(fileType);

            onMediaChange && onMediaChange({ source: mediaFile, type: fileType });
        }
    }, [mediaFile]);

    return (
        <div className="border-2 border-dashed border-gray-light rounded-xl p-4 space-y-2">
            <div className="text-lg font-semibold leading-5">{label}</div>
            <div className="flex items-center mb-3 text-special-gray">
                <InfoIcon className="h-3 w-3 min-w-3 inline-block mr-1" />
                <p className="font-medium text-xs inline-block leading-[0.9rem]">
                    {description}
                </p>
            </div>

            {!previewUrl ? (
                <AppButton
                    onClick={handleOpenExplorerFiles}
                    label="+ Add media"
                    disabled={isSubmitting || forceDisabled}
                    className="!mt-4 w-full !rounded-2xl hover:bg-primary hover:text-white bg-primary-pastel text-primary font-semibold"
                />
            ) : (
                <div className="relative group bg-gray-light aspect-[4/3] w-full h-full max-h-[300px] rounded-2xl !mt-4">
                    <div className="mx-auto w-full h-full overflow-hidden rounded-2xl">
                        {mediaType === "video" ? (
                            <video controls className="w-fit h-full object-cover object-center max-h-full mx-auto rounded-2xl">
                                <source src={previewUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={previewUrl} alt="Uploaded media" className="w-fit h-full object-cover object-center max-h-full mx-auto rounded-2xl" />
                        )}
                    </div>
                    {(!isSubmitting && !forceDisabled) && (
                        <div className="absolute top-2 right-2 flex space-x-1">
                            <AppButton
                                label=""
                                onClick={handleOpenExplorerFiles}
                                className="bg-black rounded-xl text-white h-8 w-8 border-black"
                            >
                                <EditIcon className="w-[0.625rem] h-[0.625rem]" />
                            </AppButton>
                            <AppButton
                                label=""
                                onClick={handleRemoveFile}
                                className="bg-black rounded-xl text-white h-8 w-8 border-black"
                            >
                                <XMarkIcon className="w-2 h-2" />
                            </AppButton>
                        </div>
                    )}
                </div>
            )}

            <input
                ref={inputRef}
                id="post-media-upload"
                type="file"
                disabled={forceDisabled}
                accept={allowedFormats.map((ext) => `.${ext}`).join(",")}
                className="hidden"
                onChange={handleFileUpload}
            />
        </div>
    );
};

export default PostMediaUploader;
