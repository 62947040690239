import {useCallback} from "react";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";
import SubscriptionCalculatorService from "../services/subscription-calculator.service";

/**
 * Hook for calculating discounted subscription prices.
 * Can be used inside or outside a FormProvider.
 *
 * @param {number} price - The base monthly price of the subscription.
 * @param {number} discount - The discount amount.
 * @param {string} discountType - The type of discount ("percentage" or "fixed").
 * @param {any[]} [dependencies=[]] - Optional dependencies to trigger recalculation.
 * @returns {() => string} - A function that calculates and returns the formatted discount price.
 */
export const useSubscriptionInfoYearlyDiscountPrice = (
    price: number,
    discount: number,
    discountType: TypeDiscount,
    dependencies: any[] = []
): () => string => {

    return useCallback(() => {
        return SubscriptionCalculatorService.calculateDiscountPrice(price, discount, discountType);
    }, [price, discount, discountType, ...dependencies]);
};
