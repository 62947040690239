import React, {useEffect, useMemo} from "react";
import {
    Attachment,
    MessageDeleted,
    messageHasAttachments,
    messageHasReactions,
    ReactionsList,
    useMessageContext
} from "stream-chat-react";
import MessageWrapper from "../containers/message-wrapper";
import {MessageTimestamp} from "../ui/message-time-stamp";
import MessageText from "../ui/message-text";
import MessageStatus from "../ui/message-status";
import {isReadByPartner} from "../../helpers/is-read-by-partner";
import {useActiveChat} from "../../hooks/use-active-chat";
import {getChatPartnerId} from "../../helpers/get-chat-partner-id";
import useAuth from "../../../auth/hooks/use-auth";
import Media from "../ui/media";
import {Gallery} from "../ui/galery";
import Image from "../ui/image";

const CustomMessageBasic = () => {
    const {currentUser} = useAuth();
    const {message, isMyMessage} = useMessageContext();
    const {activeChannel} = useActiveChat();
    const chatPartnerId = getChatPartnerId(currentUser?.id!, activeChannel);

    const hasAttachment = messageHasAttachments(message);
    const hasReactions = messageHasReactions(message);
    const paidCredits = message["fan_message_price"] || null;
    const receivedCredits = message["cc_message_price"] || null;
    const cc_id = message["cc_id"] || null;
    const fan_id = message["fan_id"] || null;

    const additionalUpperInfo = useMemo(() => {
        if (paidCredits && fan_id === currentUser?.id) {
            return `You used $${paidCredits}`;
        } else if (receivedCredits && cc_id === currentUser?.id) {
            return `You received $${receivedCredits}`;
        }
        return undefined;
    }, [paidCredits, receivedCredits]);

    if (message.customType === "message.date") {
        return null;
    }

    if (message.deleted_at || message.type === 'deleted') {
        return <MessageDeleted message={message}/>;
    }

    return (
        <div className='flex max-w-full'>
            <MessageWrapper additionalUpperInfo={additionalUpperInfo}>
                <div className='str-chat__message-bubble'>
                    {message.attachments?.length && !message.quoted_message ? (
                        <Attachment
                            attachments={message.attachments}
                            Gallery={Gallery}
                            Image={Image}

                            // @ts-ignore
                            Media={Media}
                        />
                    ) : null}
                    <MessageText message={message} byOwner={isMyMessage()}/>
                </div>

                <div className="flex justify-end gap-2 items-center mt-1">
                    {(message.latest_reactions?.length || 0) > 0 && (
                        <ReactionsList reactions={message.latest_reactions} reverse/>
                    )}
                    <MessageTimestamp timestamp={message.created_at!}/>
                    {isMyMessage() && (
                        <MessageStatus isReadByPartner={isReadByPartner(activeChannel, message, chatPartnerId)}/>
                    )}
                </div>
            </MessageWrapper>
        </div>
    );
};

export default CustomMessageBasic;
