import React, {createContext, useEffect, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import useAuth from "../../auth/hooks/use-auth";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {CreatePostRequest} from "../../core/models/interfaces/create-post-request.inerface";
import {useFeedApi} from "../../core/hooks/use-feed-api";
import {PostType} from "../../core/models/enums/post-type.enum";
import {schemaCreatePost} from "../constants/schema-create-post";
import Base64Helper from "../../../utils/base64-helper";

interface FormPostContextType {
    methods: UseFormReturn<CreatePostRequest>;
    handleSubmitPost: (onSuccess?: () => void) => Promise<void>;
    loading: boolean;
    isSubmittingSuccessful: boolean;
    postId: string | null;
}

interface FormPostProviderProps {
    children: React.ReactNode;
    callbackOnSuccessSubmit?: UniversalClickHandler;
}

export const FormPostContext = createContext<FormPostContextType | undefined>(undefined);

export const FormPostProvider: React.FC<FormPostProviderProps> = ({children, callbackOnSuccessSubmit}) => {
    const {createPost, loading} = useFeedApi();
    const {currentUser} = useAuth();
    const [postId, setPostId] = useState<string | null>(null);
    const [isSubmittingSuccessful, setIsSubmittingSuccessful] = useState(false);

    const methods = useForm<CreatePostRequest>({
        defaultValues: {
            userId: currentUser?.id!,
            image: null,
            video: null,
            description: "",
            postType: PostType.PUBLIC,
            otherPriceForSubscribers: false,
            price: 0.01,
            priceForSubscribers: 0.01,
        },
        // @ts-ignore
        resolver: yupResolver(schemaCreatePost),
        mode: "onSubmit",
    });

    const handleSubmitPost = async (onSuccess?: () => void) => {
        await methods.handleSubmit(async (data) => {
            try {
                const sanitizedBody: CreatePostRequest = {
                    ...data,
                    price: data.postType === "paid" ? data.price : 0,
                    priceForSubscribers: (data.postType === "paid" && data.otherPriceForSubscribers) ? data.priceForSubscribers : 0,
                    image: data.image ? data.image : null,
                    video: data.video ? data.video : null,
                    description: data.description ? data.description : null,
                }
                await createPost(sanitizedBody);
                setIsSubmittingSuccessful(true);
                methods.reset();
                if (callbackOnSuccessSubmit) callbackOnSuccessSubmit();
                if (onSuccess) onSuccess();
            } catch (error) {
                console.error("Error while submitting post:", error);
            }
        })();
    };

    useEffect(() => {
        if (isSubmittingSuccessful) setIsSubmittingSuccessful(false);
    }, [methods.watch("description"), methods.watch("image"), methods.watch("video")]);

    return (
        <FormPostContext.Provider
            value={{methods, handleSubmitPost, loading, isSubmittingSuccessful, postId}}>
            {children}
        </FormPostContext.Provider>
    );
};
