import ApiService from "../../../axios/api.service";
import {CreatePostRequest} from "../models/interfaces/create-post-request.inerface";

class FeedApiService extends ApiService {

    public async createPost(data: CreatePostRequest): Promise<void> {
        const formData = new FormData();
        const body = {
            userId: data.userId,
            description: data.description,
            postType: data.postType,
            otherPriceForSubscribers: data.otherPriceForSubscribers,
            price: data.price,
            priceForSubscribers: data.priceForSubscribers,
        }

        formData.append("request", new Blob([JSON.stringify(body)], {type: "application/json"}));
        if (data.video) formData.append("video", data.video, data.video.name);
        if (data.image) formData.append("picture", data.image, data.image.name);

        return await this.post<void>("/feed/post", formData, {
            successMessage: "Post created successfully!",
            errorMessage: "Error creating post",
            skipDefault404Handling: true,
        }).catch((error) => {
            throw error;
        });
    }

    public async deletePost(postId: string): Promise<void> {
        return await this.delete<void>(`/feed/post/${postId}`, {
            successMessage: "Post deleted successfully!",
            errorMessage: "Error deleting post",
        }).catch((error) => {
            throw error;
        });
    }
}

export default new FeedApiService();
