import React from 'react';
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import StepDot, {StepDotState as DotState} from '../../common/components/app-dot';
import SkipStepBecomeCreator from "../../core/components/ui/skip-step-become-creator";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import FormSubscriptionBecomeCreator from "../../subscriptions/components/form-subscription-become-creator";
import {useFormContext} from "react-hook-form";

interface StepThreeProps {
    prevStep: () => void;
    nextStep: () => void;
    onSkipAll?: UniversalClickHandler;
}

const StepThree: React.FC<StepThreeProps> = (props) => {
    const {
        onSkipAll,
        prevStep,
        nextStep
    } = props
    const methods = useFormContext();

    const handleSubmit = async () => {
        try {
            const isValid = await methods.trigger();
            if (isValid) nextStep();
        } catch (error: any) {
            throw error;
        }
    }

    return (
        <div className='max-w-[450px] md:ml-auto md:mr-auto space-y-6'>
            <div className={classNames('space-y-3')}>
                <div className="flex gap-1 justify-between">
                    <AppButton
                        type="button"
                        label=""
                        className="bg-gray-light text-gray w-10 h-10 !rounded-2xl border-0"
                        onClick={prevStep}
                    >
                        <ChevronLeftIcon className="w-2 h-3"/>
                    </AppButton>
                    {onSkipAll && (
                        <span className='inline-block md:hidden'><SkipStepBecomeCreator onClick={onSkipAll}/></span>
                    )}
                </div>
                <h1 className="md:max-w-[422px] font-bold text-4xl">
                    Set your <br/>
                    subscription details
                </h1>
            </div>

            <FormSubscriptionBecomeCreator/>

            <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.PREVIOUS}/>
                <StepDot state={DotState.ACTIVE}/>
                <StepDot state={DotState.NEXT}/>
                <StepDot state={DotState.NEXT}/>
            </div>

            <AppButton
                type="button"
                label="Next"
                className="bg-primary text-white w-full !rounded-3xl"
                onClick={handleSubmit}
            />
        </div>
    );
};

export default StepThree;
