import {forwardRef, ReactNode} from "react";

type AppContentWrapperProps = {
    children: ReactNode;
    className?: string;
};

const AppContentWrapper = forwardRef<HTMLDivElement, AppContentWrapperProps>(({children, className}, ref) => {
    return (
        <div ref={ref} className={'!overscroll-none mx-auto max-w-[800px] relative w-full h-fit z-[1] lg:p-4 flex-grow ' + className}>
            {children}
        </div>
    );
});

export default AppContentWrapper;
