import { ReactComponent as VoiceIcon } from "@assets/icons/microphone.svg";
import { StopIcon } from "@heroicons/react/24/outline";
import { UniversalClickHandler } from "../../../common/models/types/universal-click-handler.type";
import { useRef } from "react";

interface Props {
    startRecording: UniversalClickHandler;
    stopRecording: UniversalClickHandler;
    isRecording: boolean;
}

const CustomVoiceButton = ({ startRecording, stopRecording, isRecording }: Props) => {
    const isPressedRef = useRef(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const handlePointerDown = () => {
        isPressedRef.current = true;
        timeoutRef.current = setTimeout(() => {
            if (isPressedRef.current) {
                startRecording();
            }
        }, 200);
    };

    const handlePointerUp = () => {
        if (isPressedRef.current) {
            stopRecording();
        }
        cleanup();
    };

    const handlePointerLeave = () => {
        if (isPressedRef.current) {
            stopRecording();
        }
        cleanup();
    };

    const cleanup = () => {
        isPressedRef.current = false;
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    };

    return (
        <label
            className="outline-none bg-transparent font-inherit rounded-md cursor-pointer min-w-[18px] min-h-[18px] p-2 block"
            onPointerDown={handlePointerDown}
            onPointerUp={handlePointerUp}
            onPointerLeave={handlePointerLeave}
        >
            {isRecording ? (
                <StopIcon className="w-[18px] h-[18px] text-gray" />
            ) : (
                <VoiceIcon className="w-[18px] h-[18px] text-gray" />
            )}
        </label>
    );
};

export default CustomVoiceButton;