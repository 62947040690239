import {Transaction} from "../../core/models/interfaces/transaction.interface";
import {TransactionUi} from "../models/interfaces/transaction-ui.interface";
import {DirectionOfTransaction} from "../../core/models/enums/direction-of-transaction.enum";

export const mapTransactionToUI = (transaction: Transaction, id: number): TransactionUi => {
    const from: string = transaction.providerUsername
        ? transaction.providerUsername
        : `${transaction?.firstname ?? ''} ${transaction?.lastname ?? ''}`

    return {
        id,
        type: transaction.subjectType,
        direction: transaction.direction,
        amount: transaction.direction === DirectionOfTransaction.INCOMING ? transaction.value : transaction.value * -1,
        title: transaction.subject,
        date: new Date(transaction.txnDate),
        from: from,
    };
};
