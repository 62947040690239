import React, {useEffect, useRef} from "react";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";
import TitleSection from "../../core/components/title-section";
import SubscriptionMarker from "./ui/subscription-marker";
import Tooltip from "../../common/components/tooltip";
import SwitcherFormField from "../../common/components/forms/switcher-form-field";
import NumberFormField from "../../common/components/forms/number-form-field";
import CheckboxFormField from "../../common/components/forms/checkbox-form-field";
import DiscountTypeSwitcher from "../../core/components/discount-type-switcher";
import CounterFormField from "../../core/components/forms/counter-form-field";
import {ReactComponent as LikeIcon} from "@assets/icons/like.svg";
import {useFormContext} from "react-hook-form";
import {useSubscriptionInfoEarnings} from "../hooks/use-subscription-info-earnings.hook";
import {useSubscriptionInfoYearlyDiscountPrice} from "../hooks/use-subscription-infor-discount-price.hook";
import {BecomeCreatorFormData} from "../../become-creator/models/interfaces/become-creator-form-data.interface";
import YDollarsConversionService from "../../purchases/services/ydollars-conversion.service";

const FormSubscriptionBecomeCreator = () => {
    const methods = useFormContext<BecomeCreatorFormData>();
    const values = methods.watch();
    const containerRef = useRef(null);

    const handleDiscountTypeChange = (newType: TypeDiscount) => {
        methods.setValue("yearlyDiscountType", newType, {shouldDirty: true});
        methods.setValue("yearlyDiscount", 0, {shouldDirty: true});
    };

    const calculateSubscriptionEarning = useSubscriptionInfoEarnings(parseFloat(methods.getValues("monthlyPrice") + '') || 0)
    const calculateDiscountPrice = useSubscriptionInfoYearlyDiscountPrice(values.monthlyPrice, values.yearlyDiscount, values.yearlyDiscountType);

    useEffect(() => {
        if (values.yearlyDiscountEnabled) {
            const monthlyPrice = parseFloat(methods.getValues("monthlyPrice") + "") || 0;
            const discount = parseFloat(methods.getValues("yearlyDiscount") + "") || 0;
            const discountType = methods.getValues("yearlyDiscountType")!;

            const {total} = YDollarsConversionService.calculateDiscountedPrice(
                monthlyPrice,
                discount,
                discountType,
                12
            );

            const discountedYearlyPrice = parseFloat(total.yDollars.replaceAll(" ", "")) ?? 0
            if (discountedYearlyPrice > monthlyPrice) {
                methods.setValue("yearlyPrice", discountedYearlyPrice, {shouldDirty: true,shouldValidate:true});
                methods.clearErrors("yearlyDiscount");
                methods.trigger("yearlyDiscount");
            } else {
                methods.setValue("yearlyPrice", 0, {shouldDirty: true,shouldValidate:true});
                methods.setError("yearlyDiscount", {
                    type: "yearly-price-gt-monthly-price",
                    message: "Yearly discount is not allowed because the yearly price is not greater than the monthly price",
                });
                methods.trigger("yearlyDiscount");
            }
        }
    }, [values.monthlyPrice, values.yearlyDiscount, values.yearlyDiscountType, values.yearlyDiscountEnabled]);

    return (
        <section>
            <div ref={containerRef} className="relative flex flex-col mx-auto pb-4 pt-2 md:pt-0">
                <div className="flex gap-2 items-center justify-between flex-wrap">
                    <TitleSection
                        Icon={SubscriptionMarker}
                        title="Subscription"
                        description={
                            <span className="text-special-gray text-xs leading-[0.9rem]">
                                <Tooltip classNameIcon="relative top-[2px]"/>
                                You can edit subscription data on time per 24h
                            </span>
                        }
                    />
                    <div className="flex gap-2 items-center justify-between">
                        <SwitcherFormField name="subscriptionEnabled"/>
                    </div>
                </div>

                {values.subscriptionEnabled && (
                    <div className="flex flex-col gap-y-3 mx-auto w-full">
                        <div className="mt-4">
                            <NumberFormField
                                min={1}
                                label="Monthly Price"
                                placeholder="Enter price"
                                name="monthlyPrice"
                                minFractionDigits={2}
                                maxFractionDigits={2}
                                showCoin={true}
                            />
                            <div className="flex flex-wrap items-center mt-2 gap-1">
                                <span className="text-special-gray text-xs leading-[0.9rem]">
                                    <Tooltip classNameIcon="relative top-[2px]"/>
                                    {`You'll earn $${calculateSubscriptionEarning()}`}
                                </span>
                            </div>
                        </div>

                        <CheckboxFormField name="yearlyDiscountEnabled" label="Activate yearly discount"/>

                        {values.yearlyDiscountEnabled && (
                            <div>
                                <NumberFormField
                                    min={0}
                                    label="Yearly discount"
                                    placeholder="Enter discount"
                                    name="yearlyDiscount"
                                    minFractionDigits={methods.getValues("yearlyDiscountType") === TypeDiscount.PRICE ? 2 : 0}
                                    maxFractionDigits={methods.getValues("yearlyDiscountType") === TypeDiscount.PRICE ? 2 : 0}
                                    suffix={
                                        <DiscountTypeSwitcher
                                            disabled={methods.formState.isSubmitting}
                                            value={values.yearlyDiscountType!}
                                            onChange={handleDiscountTypeChange}/>
                                    }
                                />
                                <div className="flex flex-wrap items-center mt-2 gap-1">
                                    <span className="text-special-gray text-xs leading-[0.9rem]">
                                        <Tooltip classNameIcon="relative top-[2px]"/>
                                        {`Price after discount: ${calculateDiscountPrice()}`}
                                    </span>
                                </div>
                            </div>
                        )}

                        <div
                            className="w-[calc(100%+2rem)] -translate-x-[1rem] md:-translate-x-0 md:w-full mb-2 border-b border-special-gray"></div>

                        <div className="flex flex-col gap-y-2 mx-auto w-full">
                            <div className="flex justify-between flex-wrap items-center">
                                <TitleSection
                                    Icon={LikeIcon}
                                    title="Subscribers free messages"
                                    className="mb-3 w-fit"
                                    description="Provide your subscribers with a set amount of free communication options included in their subscription."
                                />
                            </div>

                            <CounterFormField
                                name="textFreeMessagesCount"
                                label="Text message block"
                                min={0}
                                max={999}
                            />
                            {/*<CounterFormField*/}
                            {/*    name="voiceFreeMessagesCount"*/}
                            {/*    label="Voice messages / min"*/}
                            {/*    min={0}*/}
                            {/*    max={999}*/}
                            {/*/>*/}
                            {/*<CounterFormField*/}
                            {/*    name="videoFreeMessagesCount"*/}
                            {/*    label="Video messages / min"*/}
                            {/*    min={0}*/}
                            {/*    max={999}*/}
                            {/*/>*/}
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
}

export default FormSubscriptionBecomeCreator
