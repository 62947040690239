import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import {AllSteps} from "../models/types/all-steps.type";
import {CriticalSteps, RegistrationSteps} from "../models/enums/registration-steps.enum";
import {RegistrationStep} from "../models/types/registration-step.type";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {StepConfig} from "../models/interfaces/step-registration-config.interface";
import {CreatorFlow, CriticalFlow, FanFlow} from "../constants/registration-flow";
import {CriticalRegistrationStep} from "../models/types/critical-registration-step.type";

export class RegistrationStepsService {
    static getCurrentStep(currentUser: AuthenticatedUser | undefined): AllSteps {
        if (!currentUser) return RegistrationSteps.EMAIL_VERIFICATION;

        if (!this.isClarityNewUser(currentUser)){
            const importantStep = this.determineStepFromUserState(currentUser);
            if (importantStep !== RegistrationSteps.COMPLETED) return importantStep;
        }

        if (currentUser.registrationStep && this.validateBackendStep(currentUser)) {
            return currentUser.registrationStep as RegistrationStep;
        }

        return currentUser.registrationStep as RegistrationStep;
    }

    static determineStepFromUserState(user: AuthenticatedUser): AllSteps {
        if (!user.username) {
            return CriticalSteps.LEGACY_USERNAME_REQUIRED;
        }
        if (!user.emailConfirmed) {
            return RegistrationSteps.EMAIL_VERIFICATION;
        }
        // if (!user.ccRequestSent && user.authority === UserAuthority.CONTENT_CREATOR) {
        //     return RegistrationSteps.BECOME_CREATOR;
        // }
        return RegistrationSteps.COMPLETED;
    }

    static getStepConfig(step: AllSteps, authority: UserAuthority): StepConfig | undefined {
        if (step in CriticalFlow) {
            return CriticalFlow[step as CriticalRegistrationStep];
        }

        const flow = this.getRegistrationFlow(authority);
        return flow[step as RegistrationStep];
    }

    private static validateBackendStep(user: AuthenticatedUser): boolean {
        const flow = this.getRegistrationFlow(user.authority);
        return !!flow[user.registrationStep as RegistrationStep];
    }

    private static getRegistrationFlow(authority: UserAuthority) {
        switch (authority) {
            case UserAuthority.CONTENT_CREATOR:
                return CreatorFlow;
            default:
                return FanFlow;
        }
    }

    private static isClarityNewUser(currentUser: AuthenticatedUser):boolean{
        const actualStep: RegistrationStep = currentUser.registrationStep as RegistrationStep;
        return actualStep !== RegistrationSteps.COMPLETED
    }

}
