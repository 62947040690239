import {StripePrice} from "../../core/models/interfaces/stripe-price.interface";
import Coin from "../../core/components/ui/coin";
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import SpecialOfferLabel from "./special-offer-label";
import {SpecialOfferType} from "../models/enums/special-offer-type.enum";
import Tooltip from "../../common/components/tooltip";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {useDataStripePrice} from "../hooks/use-data-stripe-price";
import useEffectOnce from "../../common/hooks/use-effect-once";
import {useAnalytics} from "../../core/hooks/use-analytics";

interface OfferPriceCardProps {
    price: StripePrice
}

const OfferPriceCard = (props: OfferPriceCardProps) => {
    const {price} = props;
    const {showModal} = useModalWithData(ModalIds.PURCHASE_YC);
    const {
        isBestBuy,
        isHotPromotion,
        hasSavings,
        originalPrice,
        previousPrice,
        lowestPriceOf30Days,
        hasDiscount,
        pricePerUnit,
        discountAmount,
    } = useDataStripePrice(price)!;
    const {sendCreditsPromotionViewInfo, sendSelectedCreditsPromotionInfo} = useAnalytics();

    const openPurchaseYcModal = () => {
        showModal(price);
        sendSelectedCreditsPromotionInfo(price, discountAmount ?? 0);
    }

    useEffectOnce(() => {
        if (isHotPromotion) {
            sendCreditsPromotionViewInfo(price, discountAmount ?? 0);
        }
    })

    return (
        <div
            className={classNames("rounded-2xl",
                isBestBuy
                    ? "bg-secondary-pastel border-secondary-pastel"
                    : "border border-special-gray",
            )}
        >
            <div className={classNames(
                'w-full flex gap-1',
                (isHotPromotion || isBestBuy || hasSavings) && "p-1"
            )}>
                <SpecialOfferLabel offerType={SpecialOfferType.BEST_BUY} isVisible={isBestBuy}/>
                <SpecialOfferLabel offerType={SpecialOfferType.HOT_PROMOTION} isVisible={isHotPromotion}/>
                <SpecialOfferLabel offerType={SpecialOfferType.SAVINGS} isVisible={hasSavings}
                                   savings={price.savePercent}/>
            </div>

            <div className='flex justify-between items-center p-4'>
                <div className='flex gap-3 justify-center items-center'>
                    <span className='text-[2rem]'><Coin/></span>
                    <div className='flex flex-col'>
                        <div className='flex gap-1 items-center h-fit'>
                            <span className="text-lg font-bold leading-tight">
                                {price?.coins}
                            </span>
                            <span className="text-xs font-bold block relative top-[1px]">
                                $
                            </span>
                        </div>
                        <span className="text-xs text-gray">
                            $1 = {pricePerUnit}
                        </span>
                    </div>
                </div>

                <div className='flex flex-col items-end gap-1'>
                    <AppButton
                        onClick={openPurchaseYcModal}
                        label=''
                        className={classNames(
                            'bg-primary-pastel !rounded-2xl border-none text-primary font-semibold text-sm flex flex-col items-center',
                            'hover:bg-primary hover:text-white transition focus:bg-primary focus:text-white',
                            isBestBuy && "bg-secondary !text-black hover:bg-secondary hover:!text-primary"
                        )}

                    >
                        {hasDiscount && (
                            <span className="block text-xs font-medium line-through leading-tight">
                            ${previousPrice}
                        </span>
                        )}
                        <span className='block leading-tight'>{`Buy ($${originalPrice})`}</span>
                    </AppButton>
                    {hasDiscount && (
                        <div className='text-special-gray text-xs font-medium flex items-center'>
                            <Tooltip/>
                            <span>30 days lowest price: ${lowestPriceOf30Days}</span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default OfferPriceCard
