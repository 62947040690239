import * as yup from "yup";

export const schemaContactPrices = yup.object({
    textMessagePrice:
        yup.string()
            .required('Price is required')
            .optional()
            .nullable(),

    voiceMessagePrice:
        yup.string()
            .required('Price is required')
            .optional().nullable(),

    videoMessagePrice:
        yup.string()
            .required('Price is required')
            .optional()
            .nullable(),

    textMessageEnabled:
        yup.boolean(),

    voiceMessageEnabled:
        yup.boolean(),

    videoMessageEnabled:
        yup.boolean(),

    availabilityVoiceMessage:
        yup.string()
            .required('Availability is required'),

    availabilityVideoMessage:
        yup.string()
            .required('Availability is required')

}).required();
