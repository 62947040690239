import * as yup from "yup";
import {validateUsername} from "../../core/constants/validate-username";
import {UserAuthority} from "../models/enums/user-authority.enum";

export const schemaRegister = yup.object({
    username: validateUsername,

    email: yup.string()
        .email('Invalid email format')
        .required('Email is required'),

    password: yup.string()
        .required('Password is required')
        .min(8, 'Password must be at least 8 characters')
        .max(255, 'Password must be less than 255 characters')
        .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
        .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
        .matches(/[0-9]/, 'Password must contain at least one number')
        .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),

    confirmPassword: yup
        .string()
        .oneOf([yup.ref('password'), undefined], 'Passwords must match')
        .required("Confirm password is required"),

    authorityId: yup
        .number()
        .default(4)
        .required(),

}).required();
