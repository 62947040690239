import {
    DigitalProductCategory as MappedDigitalProductCategory
} from "../models/interfaces/digital-product-category.interface";
import {DigitalProductCategory} from "../../core/models/interfaces/digital-product-category.interface";

export function mapDynamicCategories(
    categories: DigitalProductCategory[]
): MappedDigitalProductCategory[] {
    const dynamicCategoriesMap = new Map<string, DigitalProductCategory>();

    categories.forEach((category): void => {
        const categoryId = category.id!;

        if (categoryId && !dynamicCategoriesMap.has(categoryId)) {
            dynamicCategoriesMap.set(categoryId, category);
        }
    });

    return Array.from(dynamicCategoriesMap.entries()).map(([id, cat]) => ({
        name: cat.name!.charAt(0).toUpperCase() + cat.name!.slice(1).toLowerCase(),
        badge: null,
        showCategory: true,
        value: id,
        ordinalNumber: cat.ordinalNumber
    }));
}
