import {ComponentType, ReactNode} from "react";

interface TitleSectionProps {
    Icon: ComponentType<any>,
    title: string,
    description?: ReactNode,
    className?: string,
    iconClassName?: string,
    titleClassName?: string,
}

const TitleSection = (props: TitleSectionProps) => {
    const { title, Icon,description,className, iconClassName, titleClassName } = props;

    return (
        <header className={"flex items-center font-bold text-lg leading-[1.35rem] gap-x-3 h-fit "+className}>
            <Icon className={"w-[1.25rem] min-w-[1.25rem] h-fit max-h-[1.25rem] "+iconClassName} />
            <div className="flex flex-col">
                <h3 className={"font-bold text-lg text-[rgba(0,0,0,1)] "+titleClassName}>{title}</h3>
                {description && (
                    <span className="text-special-gray font-medium text-xs leading-[0.9rem]">
                        {description}
                    </span>
                )}
            </div>
        </header>
    );
};

export default TitleSection;
