import {Orders} from "../models/enums/order-settings.enum";
import {OrderType} from "../models/interfaces/order-type.interface";

export const orderTypes: OrderType[] = [
    // {
    //     value: Orders.PRODUCT,
    //     label: "Products",
    // },
    {
        value: Orders.CATEGORY,
        label: "Categories",
    },
];
