import {useContext} from "react";
import {
    FormSubscriptionOfferContext,
    FormSubscriptionOfferContextType
} from "../contexts/form-subscription-offer-context";

export const useFormSubscriptionOffer = (): FormSubscriptionOfferContextType => {
    const context = useContext(FormSubscriptionOfferContext);
    if (!context) {
        throw new Error("useFormSubscriptionOffer must be used within a FormSubscriptionOfferProvider");
    }
    return context;
};
