import {ItemSubmenuCCTools} from "../models/interfaces/ItemSubmenuCCTools.interface";

export const CCToolsSubmenu: Array<ItemSubmenuCCTools> = [
    {
        text: "Contact prices",
        href: "",
        badge: null,
    },
    {
        text: "Subscription",
        href: "/subscription",
        badge: null,
    },
    {
        text: "Digital products",
        href: "/digital-products",
        badge: null,
    }
]
