import Tooltip from "../../common/components/tooltip";
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import {GenderEnum} from "../models/enums/gender.enum";
import {getDictionaryGenders} from "../helpers/dictionary-genders";


interface SelectorUserRoleProps {
    selectedGender: GenderEnum,
    onSelect: (role: GenderEnum) => void;
}

const SelectorGender = (props: SelectorUserRoleProps) => {
    const genders = getDictionaryGenders();
    const {selectedGender, onSelect} = props;

    const handleOnSelect = (gender: GenderEnum) => {
        onSelect(gender);
    }

    return (
        <div className='flex flex-col gap-1'>
            <span className='flex gap-1'>
                <p className='font-semibold text-base'>Gender</p>
            </span>
            <div className='w-full p-1 flex bg-gray-light max-h-[40px] rounded-2xl h-[40px] mb-4 gap-1'>
                {genders.map(gender =>
                    <AppButton
                        key={gender.value}
                        type='button'
                        onClick={() => handleOnSelect(gender.value)}
                        className={classNames(
                            'w-full border-none h-full !p-0 text-special-gray !text-[0.8135rem] focus:outline-none',
                            (selectedGender === gender.value) && "bg-white font-semibold !text-black drop-shadow-xl"
                        )}
                        label={gender.name}/>
                )}
            </div>
        </div>
    )
}

export default SelectorGender
