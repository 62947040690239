import React, {useContext, useMemo} from "react"
import AppHeader from "../../../layout/app-header"
import AppContentWrapper from "../../../layout/components/app-content-wrapper"
import {useNavigate} from "react-router-dom"
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface"
import {UserProfileContext, UserProfileContextProps} from "../../profile/contexts/user-profile-context"
import {mapCategoriesWithBadges} from "../utils/map-categories-with-badges"
import SubmenuTab from "../../core/components/submenu-tab"
import PreviewDigitalProductToBuy from "../components/preview-digital-product-to-buy"
import AppButton from "../../common/components/app-button"
import {ChevronLeftIcon} from "@heroicons/react/24/solid"
import PurchaseProductModal from "../../purchases/components/purchase-product-modal";
import {classNames} from "../../../utils/class-names";
import PublicDigitalProductsEmpty from "../components/ui/public-digital-products-empty";
import {State, ViewStates} from "../../common/components/viewStates";
import SkeletonPreviewDigitalProductToBuy from "../components/ui/skeleton-preview-digital-product-to-buy";
import {ViewState} from "../../common/models/enums/view-state.enum";
import {DefaultDigitalProductCategory} from "../../core/models/enums/digital-product-category.enum";
import SearchInput from "../../common/components/search-input";
import {useDigitalProductFilter} from "../hooks/use-digital-product-filter.hook";
import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";
import {mapDynamicCategories} from "../utils/map-dynamic-categories";
import {StaticDigitalProductCategories} from "../constants/static-categories-digital-products";

interface PublicProfileAllDigitalProductsViewProps {
    isPreviewMode?: boolean
}

const PublicProfileAllDigitalProductsView = (props: PublicProfileAllDigitalProductsViewProps) => {
    const {isPreviewMode = false} = props
    const navigate = useNavigate();
    const {
        userProfile,
        digitalProducts,
        updateProduct,
        loading,
        productCategories,
        error,
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const back = () => navigate('../')
    const {
        filteredProducts,
        setQueryTyped,
        queryTyped,
        selectedCategory,
        setSelectedCategory
    } = useDigitalProductFilter();

    const isSelectedCategory = (category: DigitalProductCategory) => selectedCategory === category.value
    const handleCategoryChange = (category: DefaultDigitalProductCategory | string) => setSelectedCategory(category);
    const categoriesWithCounts: DigitalProductCategory[] = useMemo(
        () => {
            const dynamicCategories = mapDynamicCategories(productCategories);
            return mapCategoriesWithBadges([...StaticDigitalProductCategories, ...dynamicCategories], digitalProducts, queryTyped);
        },
        [digitalProducts, productCategories, queryTyped]
    );

    return (
        <>
            <div className={classNames(
                "ml-auto mr-auto w-full max-h-full flex flex-col overflow-x-clip overflow-y-auto",
                isPreviewMode && "pb-24"
            )}>
                <AppHeader customContent={true} className={classNames(
                    isPreviewMode && "!p-0"
                )}>
                    <div className='flex justify-between flex-wrap gap-5 pt-5'>
                        <div className='flex flex-col gap-5 w-full'>
                            <div className='flex items-center w-full'>
                                <AppButton
                                    type="button"
                                    label=""
                                    className="bg-[#e1d7fd] text-gray w-10 h-10 !rounded-2xl border-0 mr-3"
                                    onClick={back}
                                >
                                    <ChevronLeftIcon className="w-4 h-4 text-primary-darken"/>
                                </AppButton>
                                <span
                                    className='font-bold text-2xl lg:text-[40px] text-primary-darken whitespace-nowrap'>
                                    Digital Products
                                </span>
                            </div>
                            <div className='overflow-y-clip overflow-x-auto flex gap-2 cursor-pointer'>
                                {categoriesWithCounts
                                    .filter(category => category.value !== DefaultDigitalProductCategory.DELETED)
                                    .filter(cat => cat.showCategory)
                                    .sort((cat1, cat2) => cat1.ordinalNumber - cat2.ordinalNumber)
                                    .map((category, index) => (
                                        <SubmenuTab
                                            onClick={() => handleCategoryChange(category.value as any)}
                                            badge={category.badge}
                                            key={index}
                                            text={category.name}
                                            isActive={isSelectedCategory(category)}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </AppHeader>
                <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-fit'>
                    <ViewStates loading={loading} data={digitalProducts} error={error}>
                        <State state={ViewState.LOADING}>
                            <div className={classNames(
                                "grid grid-cols-1 sm:grid-cols-2 gap-4",
                                isPreviewMode && "pb-24"
                            )}>
                                {Array.from({length: 4}).map((value, index) => <SkeletonPreviewDigitalProductToBuy
                                    key={index}/>)}
                            </div>
                        </State>
                        <State state={ViewState.FETCHED}>
                            <div className='flex flex-col gap-3 pb-6'>
                                <div className="flex gap-2">
                                    <SearchInput onSearch={setQueryTyped} disableEmptySearch={false}/>
                                </div>

                                <div className={classNames(
                                    "grid grid-cols-1 sm:grid-cols-2 gap-4",
                                    isPreviewMode && "pb-24"
                                )}>
                                    {filteredProducts.map((product, index) => <PreviewDigitalProductToBuy
                                            disableDetails={false}
                                            disableBuy={isPreviewMode}
                                            product={product}
                                            key={index}
                                            creatorUsername={userProfile?.username + ''}
                                            allItemsView={true}
                                            creatorId={userProfile?.id!}
                                        />
                                    )}
                                </div>
                            </div>
                        </State>
                        <State state={ViewState.EMPTY}>
                            <PublicDigitalProductsEmpty/>
                        </State>
                    </ViewStates>
                </AppContentWrapper>
            </div>
            <PurchaseProductModal modalPlace="allProducts" onSuccessBuyProduct={updateProduct}/>
        </>
    )
}

export default PublicProfileAllDigitalProductsView;
