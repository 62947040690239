import StickyBottomActionBar from "../../common/components/sticky-bottom-action-bar";
import AppButton from "../../common/components/app-button";
import React, {useContext} from "react";
import {useFormSubscriptionOffer} from "../hooks/use-form-subscription-offer.hook";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {
    DataConfirmationUpdateSubscriptionModal
} from "../models/interfaces/data-confirmation-update-subscription-modal.interface";
import {SessionCreatorToolsContext} from "../../digital-products/contexts/session-creator-tools-context";
import NotificationService from "../../common/services/notification.service";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";

interface FormSubscriptionActionsProps {
    parentRef: React.RefObject<any>;
}

const FormSubscriptionActions = ({parentRef}: FormSubscriptionActionsProps) => {
    const {methods} = useFormSubscriptionOffer();
    const {getValues, formState: {disabled, isValid, isDirty}} = methods;
    const {fetchConsequencesOfEditingOffer} = useContext(SessionCreatorToolsContext)!
    const {showModal} = useModalWithData<DataConfirmationUpdateSubscriptionModal>(ModalIds.CONFIRMATION_UPDATE_SUBSCRIPTION)

    const openConfirmationModal = async () => {
        try {
            const consequences = await fetchConsequencesOfEditingOffer({
                yearlyDiscountActive: getValues("yearlyDiscountEnabled"),
                monthlyPrice: getValues("monthlyPrice"),
                yearlyPrice: getValues("yearlyPrice"),
                voiceMessages: getValues("voiceFreeMessagesCount"),
                textMessageBlock: getValues("textFreeMessagesCount"),
                videoMessages: getValues("videoFreeMessagesCount"),
                yearlyDiscountPercentage: getValues("yearlyDiscountType") === TypeDiscount.PERCENTAGE ? getValues("yearlyDiscount") : 0,
                yearlyDiscountPrice: getValues("yearlyDiscountType") === TypeDiscount.PRICE ? getValues("yearlyDiscount") : 0,
                active: getValues("subscriptionEnabled"),
            });

            if (consequences) {
                showModal({
                    monthlySubs: consequences.renewableMonthlySubscriptionsToBeCanceled,
                    totalSubs: consequences.renewableSubscriptionsToBeCanceled,
                    yearlySubs: consequences.renewableYearlySubscriptionsToBeCanceled
                });
            }
        } catch (err: any) {
            NotificationService.error("We couldn't process your changes. Please try again shortly.", err);
            throw err;
        }
    }


    return (
        <StickyBottomActionBar parentRef={parentRef}>
            <div className='@container md:@container-normal'>
                <div className='flex gap-2 flex-wrap md:flex-nowrap @[260px]:flex-nowrap'>
                    <AppButton
                        type='submit'
                        disabled={disabled || !isValid || !isDirty}
                        onClick={openConfirmationModal}
                        label="Save changes"
                        className='!text-sm font-semibold !bg-primary text-white !rounded-2xl w-full hover:brightness-110 focus:brightness-110 whitespace-nowrap'
                        disabledClassName='disabled:!bg-gray-200'
                    />
                </div>
            </div>
        </StickyBottomActionBar>
    )
}

export default FormSubscriptionActions
