import {useCallback} from "react";
import SubscriptionCalculatorService from "../services/subscription-calculator.service";

/**
 * Hook for calculating subscription earnings.
 * Can be used inside or outside a FormProvider.
 *
 * @param {number} price - The base price used for calculation.
 * @param {any[]} [dependencies=[]] - Optional: Additional dependencies to trigger recalculation.
 * @returns {() => number} - A function that calculates subscription earnings.
 */
export const useSubscriptionInfoEarnings = (price: number, dependencies: any[] = []) => {
    return useCallback(() => {
        return SubscriptionCalculatorService.calculateSubscriptionEarning(price);
    }, [price, ...dependencies]);
};
