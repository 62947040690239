import ProductForm from "../components/product-form";
import {FormDigitalProductProvider} from "../contexts/form-digital-product-context";
import {SessionCreatorToolsContext, SessionCreatorToolsProvider} from "../contexts/session-creator-tools-context";
import AppButton from "../../common/components/app-button";
import {useFormDigitalProduct} from "../hooks/use-form-digital-product.hook";
import {SidebarProvider} from "../../../layout/contexts/sidebar-context";
import CardDigitalProduct from "../components/card-digital-product";
import React, {useContext} from "react";
import { useNavigate } from "react-router";

interface AddFirstProductsViewProps {
    onSuccess: () => void;
    hideNewCategoryButton?: boolean
}

const AddFirstProductsView = ({onSuccess, hideNewCategoryButton}: AddFirstProductsViewProps) => {
    const {handleSubmitProduct} = useFormDigitalProduct();
    const {products} = useContext(SessionCreatorToolsContext)!;

    const submitHandler = async () => {
        await handleSubmitProduct(onSuccess)
    }

    return (
        <div className='space-y-8'>
            {!!products.length && (
                <div className="space-y-3">
                    <span className="font-semibold text-2xl text-primary-darken">Your Added Products</span>
                    <div
                        className="grid grid-cols-1 sm:grid-cols-2 gap-4 max-h-[calc(3*theme(height.24)+10px)] overflow-y-auto">
                            {products.map((product) => (
                                <CardDigitalProduct key={product.id} product={product} isDisabled={true}/>
                            ))}
                    </div>
                </div>
            )}

            <div className='flex flex-col gap-3'>
                <span className='font-semibold text-2xl text-primary-darken'>Add your product!</span>
                <ProductForm classNameForm='!pb-0' hideAddCategoryButton={hideNewCategoryButton}/>
                <AppButton
                    onClick={submitHandler}
                    label='+ Add product'
                    className='bg-primary text-white !rounded-2xl font-semibold text-sm !py-[9px]'/>
            </div>
        </div>
    )
}

export const AddFirstProductsViewWithProviders = (props: AddFirstProductsViewProps) => {
    const navigate = useNavigate();
    return (
        <SidebarProvider>
            <SessionCreatorToolsProvider initFetch={true}>
                <FormDigitalProductProvider mode='create'>
                    <AddFirstProductsView {...props}/>
                </FormDigitalProductProvider>
            </SessionCreatorToolsProvider>
        </SidebarProvider>
    )
}

export default AddFirstProductsViewWithProviders
