import AppModal from "./app-modal";
import {ModalIds} from "../models/enums/modal-ids.enum";
import React from "react";
import AdditionalAccountDetailsForm from "../../core/components/additional-account-details-form";
import {useModal} from "../hooks/use-modal";
import NotificationService from "../services/notification.service";

interface AdditionalInfoModalProps {
    appendTo?: string | React.RefObject<HTMLElement> | null;
}

const AdditionalInfoModal = (props: AdditionalInfoModalProps) => {
    const {closeModal} = useModal();

    const onSuccessHandler = () => {
        NotificationService.success("Hurraaaaa")
        closeModal(ModalIds.GET_DOLLAR_FOR_ADDITIONAL_ACCOUNT_INFO)
    }

    return (
        <AppModal
            key={ModalIds.GET_DOLLAR_FOR_ADDITIONAL_ACCOUNT_INFO}
            id={ModalIds.GET_DOLLAR_FOR_ADDITIONAL_ACCOUNT_INFO}
            appendTo={props.appendTo}
            disableScroll={true}
            zIndex={40}
            classModal='md:min-w-[500px]'
        >
            <AdditionalAccountDetailsForm
                onSuccess={onSuccessHandler}/>
        </AppModal>
    );
}

export default AdditionalInfoModal
