import AppHeader from "../../../layout/app-header";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import React from "react";
import Breadcrumbs from "../../../layout/components/breadcrumbs";
import AppBackButton from "../../../layout/components/app-back-button";

const WithdrawPanelView = () => {

    return (
        <>
            <AppHeader customContent={true}>
                <div className='flex justify-between items-end flex-wrap gap-5 min-h-[70px]'>
                    <div className='flex flex-col gap-5'>
                        <div className='flex items-center w-fit gap-5'>
                            <AppBackButton/>
                            <Breadcrumbs path={'Withdraw'}/>
                        </div>
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] pt-4 sm:pt-9'>
                <section className='h-full flex flex-col'>
                    <p>
                        To request a cashout, please send an email to our support team with the following details:
                        your <span className="text-primary font-bold">username</span>, the{" "}
                        <span className="text-primary font-bold">amount</span> of $ you wish to{" "}
                        <span className="text-primary font-bold">withdraw</span>, and any additional relevant
                        information. Our support email is:{" "}
                        <strong className='text-primary-darken'>
                            <a href="mailto:support@yurs.email" className="text-primary-darken font-bold">
                                support&#64;yurs.email
                            </a>
                        </strong>
                    </p>
                </section>
            </AppContentWrapper>
        </>
    )
}

export default WithdrawPanelView
