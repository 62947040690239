import ApiService from "../../../axios/api.service";
import {CreateWithdrawRequestBody} from "../models/interfaces/create-withdraw-request-body.interface";
import {Transaction} from "../models/interfaces/transaction.interface";
import {PaymentMethodType} from "../models/enums/payment-method-type.enum";

class StripeApiService extends ApiService {

    public async getPrices(): Promise<any> {
        return await this.get<any>(`/stripe/prices`, {})
            .catch((error) => {
                throw error;
            });
    }

    public async createCheckoutSession(
        priceId: string,
        paymentMethod: PaymentMethodType = PaymentMethodType.CARD
    ): Promise<{ checkoutUrl: string }> {
        return await this.post<{ checkoutUrl: string }>(`/stripe/create-checkout-session/${priceId}`, {},{
            params:{
                paymentMethodType: paymentMethod
            }
        })
            .catch((error) => {
                throw error;
            });
    }

    public async createSubscriptionCheckoutSession(
        priceId: string,
        successUrl: string,
        cancelUrl: string,
    ): Promise<{ checkoutUrl: string }> {
        return await this.post<{ checkoutUrl: string }>(`/stripe/subscription/create-checkout-session/${priceId}`, {},{
            params:{
                successUrl,
                cancelUrl
            }
        })
            .catch((error) => {
                throw error;
            });
    }
}

export default new StripeApiService();
