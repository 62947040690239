import {FormPostContext} from "../contexts/form-post-provider";
import {useContext} from "react";

export const useFormPost = () => {
    const context = useContext(FormPostContext);
    if (!context) {
        throw new Error("useFormPost must be used within a FormPostProvider");
    }
    return context;
};
