import {classNames} from "../../../utils/class-names";
import React, {useContext, useMemo, useRef} from "react";
import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";
import {mapCategoriesWithBadges} from "../utils/map-categories-with-badges";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import {useDigitalProductFilter} from "../hooks/use-digital-product-filter.hook";
import {DefaultDigitalProductCategory} from "../../core/models/enums/digital-product-category.enum";
import {mapDynamicCategories} from "../utils/map-dynamic-categories";
import {StaticDigitalProductCategories} from "../constants/static-categories-digital-products";

const SelectorCategoryProduct = () => {
    const {products, productCategories} = useContext(SessionCreatorToolsContext)!
    const {selectedCategory, setSelectedCategory, queryTyped} = useDigitalProductFilter();
    const buttonRefs = useRef<Map<string, HTMLButtonElement>>(new Map());
    const containerRef = useRef<HTMLDivElement>(null);

    const isSelectedCategory = (category: DigitalProductCategory) => selectedCategory === category.value
    const handleCategoryChange = (category: DefaultDigitalProductCategory | string) => {
        setSelectedCategory(category);

        const button = buttonRefs.current.get(category);
        const container = containerRef.current;

        if (button && container) {
            const buttonLeft = button.offsetLeft;
            const buttonWidth = button.offsetWidth;
            const containerWidth = container.offsetWidth;

            const scrollTo = buttonLeft - (containerWidth / 2) + (buttonWidth / 2);
            container.scrollTo({left: scrollTo, behavior: "smooth"});
        }
    };
    const categoriesWithCounts: DigitalProductCategory[] = useMemo(
        () => {
            const dynamicCategories = mapDynamicCategories(productCategories);
            return mapCategoriesWithBadges([...StaticDigitalProductCategories, ...dynamicCategories], products, queryTyped);
        },
        [products, productCategories, queryTyped]
    );

    return (
        <div className="my-4 mt-2 h-full min-h-full select-none">
            <div
                className="flex items-center gap-2 min-h-full border-b-2 h-[40px] border-gray-light overflow-x-auto overflow-y-hidden"
                ref={containerRef}>
                {categoriesWithCounts
                    .filter(cat => cat.showCategory)
                    .sort((cat1, cat2) => cat1.ordinalNumber - cat2.ordinalNumber)
                    .map((category) => (
                        <button
                            ref={el => {
                                if (el) buttonRefs.current.set(category.value, el);
                            }}
                            key={category.value}
                            onClick={() => handleCategoryChange(category.value)}
                            className={classNames(
                                "relative top-[2px] flex items-center gap-2 p-1 text-sm font-semibold transition pb-2",
                                isSelectedCategory(category)
                                    ? "text-black border-b-2 border-black"
                                    : "text-special-gray"
                            )}
                        >
                            <span
                                className="first-letter:uppercase select-none first-letter:select-none whitespace-nowrap">{category.name}</span>
                            <span
                                className={classNames(
                                    "transition flex items-center justify-center w-5 h-5 rounded-full text-xs font-semibold",
                                    isSelectedCategory(category)
                                        ? "text-white bg-black"
                                        : "bg-gray-light text-special-gray"
                                )}>
                                {category.badge}
                            </span>
                        </button>
                    ))}
            </div>
        </div>
    )
}

export default SelectorCategoryProduct
