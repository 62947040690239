import React, {useContext, useEffect, useRef, useState} from "react";
import ProfileBackground from "../components/profile-background";
import ProfileImage from "../components/profile-image";
import ShortUserData from "../components/short-user-data";
import FollowButton from "../components/follow-button";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import useAuth from "../../auth/hooks/use-auth";
import ProfileCcMenu from "../components/profile-cc-menu";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import PublicProfileSettingsButton from "../components/public-profile-settings-button";
import {NavigationProfileAnchors} from "../types/navigation-profile-anchors.interface";
import CardBaseOffer from "../../digital-products/components/card-base-offer";
import {ContactType} from "../../core/models/enums/communications-type.enum";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import PreviewDigitalProductToBuy from "../../digital-products/components/preview-digital-product-to-buy";
import AppButton from "../../common/components/app-button";
import PurchaseProductModal from "../../purchases/components/purchase-product-modal";
import {useNavigate} from "react-router-dom";
import {useAnalytics} from "../../core/hooks/use-analytics";
import BuySubscriptionCard from "../../subscriptions/components/buy-subscription-card";
import PurchaseSubscriptionModal from "../../purchases/components/purchase-subscription-modal";
import CardConsumedBaseOffer from "../../digital-products/components/card-consumed-base-offer";
import {getDiscountType} from "../../subscriptions/helpers/get-discount-type";
import {DateHelper} from "../../../utils/date-helper";
import {useStatusPurchasedSubscriptionHandler} from "../../purchases/hooks/use-status-purchased-subscription.handler";

interface PublicProfileProps {
    isPreviewMode?: boolean;
}


const PublicCcProfile = ({isPreviewMode}: PublicProfileProps) => {
    const {currentUser, isAuthenticated} = useAuth();
    const refParentStickyActionBar = useRef<HTMLDivElement>(null);
    // TODO probably this variable in the future will be get from user settings about display products
    const initialMaxDisplayProducts = 3;
    const [maxDisplayProducts, setMaxDisplayProducts] = useState<number>(initialMaxDisplayProducts);
    const {
        userProfile,
        isFollowed,
        loading,
        followCreator,
        digitalProducts,
        updateProduct,
        creatAndNavigateToChat,
        visitorHasUnlockedSubscription,
        offerSubscription: sub,
        syncDataAfterBoughtSubscription,
    } = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;
    const navigate = useNavigate();
    const {sendVisitedCreatorInfo} = useAnalytics();

    const isDisabledFollow = () => currentUser?.username?.toLowerCase() === userProfile?.username?.toLowerCase()
    const showMoreProducts = () => navigate('./digital-products')

    useEffect(() => {
        userProfile && sendVisitedCreatorInfo(userProfile)
    }, [userProfile]);

    useStatusPurchasedSubscriptionHandler();

    return (
        <AppContentWrapper className='bg-white rounded-t-[1.5rem] md:max-w-[1400px] !p-0 md:!p-4'>
            <div className='relative inset-0 h-[260px]'>
                <ProfileBackground
                    canShowMenuHamburger={isPreviewMode || isAuthenticated}
                    backgroundImage={isPreviewMode ? currentUser?.backgroundProfileImage! : userProfile?.backgroundProfile!}/>
            </div>
            <div className='relative' ref={refParentStickyActionBar}>
                <div
                    className='p-4 w-full h-full md:max-w-[832px] bg-white rounded-t-[2rem] relative top-[-30px] left-0 md:flex gap-4 md:mx-auto md:top-0'>
                    <div className='static md:sticky top-[80px] left-0 h-fit min-w-[250px] md:max-w-[250px]'>
                        <div
                            className='absolute top-[-60px] md:top-[-80px] left-1/2 md:left-0 translate-x-[-50%] md:translate-x-0 w-fit'>
                            <ProfileImage avatar={isPreviewMode ? currentUser?.profileImage! : userProfile?.avatar!}
                                          role={isPreviewMode ? currentUser?.authority! : UserAuthority.CONTENT_CREATOR}/>
                        </div>
                        <div className='mt-[80px] md:mt-[65px]'></div>
                        <ShortUserData
                            fullName={userProfile?.username}
                            shortDescription={''}
                            username={userProfile?.username + ''}
                        />
                        <div className="w-[30px] border border-gray-400  my-4 ml-auto mr-auto md:mx-0"></div>
                        <div className="font-medium text-sm text-gray mb-2 text-center md:text-left">
                            {userProfile?.bio}
                        </div>
                        <div className='flex gap-3 h-fit mt-4 items-center'>
                            <div className='flex-grow h-fit'>
                                <FollowButton
                                    isFollowed={isPreviewMode ? false : isFollowed}
                                    onFollow={followCreator}
                                    onOpenChat={creatAndNavigateToChat.bind(this, userProfile?.username!)}
                                    disabled={isDisabledFollow() || loading}/>
                            </div>
                            <PublicProfileSettingsButton
                                creatorId={userProfile?.id!}
                                isPreviewMode={isPreviewMode}
                                creatorUserName={isAuthenticated ? currentUser?.username! : userProfile?.username!}/>
                        </div>
                    </div>
                    <div className='w-full flex flex-col space-y-8 overflow-hidden'>
                        <ProfileCcMenu hasActiveSub={sub?.active}
                                       visitorHasUnlockedSubscription={visitorHasUnlockedSubscription}/>
                        {(visitorHasUnlockedSubscription && sub) && (
                            <section id={NavigationProfileAnchors.SUBSCRIPTION_USAGE} className='space-y-4'>
                                <header className='flex flex-col gap-1'>
                                    <h2 className='font-bold text-lg leading-5'>Subscription usage</h2>
                                    <span className="text-xs leading-[0.9rem] text-special-gray">
                                        Valid to {DateHelper.formatDate(new Date(sub.activeTo), "DD.MM.YYYY")}
                                    </span>
                                </header>
                                <div className="flex gap-2">
                                    <div className="w-full">
                                        <CardConsumedBaseOffer
                                            title="Chat"
                                            description="Message blocks"
                                            max={sub.textMessageBlock}
                                            used={sub.textMessageBlockUsed ?? 0}
                                            type={ContactType.CHAT}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <CardConsumedBaseOffer
                                            title="Voice"
                                            description="Minutes"
                                            max={sub.voiceMessages}
                                            used={sub.voiceMessagesUsed ?? 0}
                                            type={ContactType.CALL}
                                        />
                                    </div>
                                    <div className="w-full">
                                        <CardConsumedBaseOffer
                                            title="Video"
                                            description="Minutes"
                                            max={sub.videoMessages}
                                            used={sub.videoMessagesUsed ?? 0}
                                            type={ContactType.VIDEO_CALL}
                                        />
                                    </div>
                                </div>
                            </section>
                        )}
                        <section id={NavigationProfileAnchors.CONTACT_PRICING} className='space-y-2'>
                            <header>
                                <h2 className='font-bold text-lg leading-5 pb-2'>Contact pricing</h2>
                            </header>
                            <div className='flex gap-2'>
                                <div className='w-full'>
                                    <CardBaseOffer
                                        isEnabled={true}
                                        type={ContactType.CHAT}
                                        shortInfoTooltip='Its more data.'
                                        title='Chat'
                                        description='for message block'
                                        price={userProfile?.chatPrice!}/>
                                </div>
                                <div className='w-full'>
                                    <CardBaseOffer
                                        isEnabled={false}
                                        type={ContactType.CALL}
                                        title='Voice'
                                        description='for 1 min'
                                        price={userProfile?.callPrice!}/>
                                </div>
                                <div className='w-full'>
                                    <CardBaseOffer
                                        isEnabled={false}
                                        type={ContactType.VIDEO_CALL}
                                        title='Video'
                                        description='for 1 min'
                                        price={userProfile?.videoCallPrice!}/>
                                </div>
                            </div>
                        </section>
                        {(!visitorHasUnlockedSubscription && sub && sub?.active) && (
                            <section id={NavigationProfileAnchors.SUBSCRIPTION} className="!mt-2">
                                <header className="sr-only">
                                    <h2 className='font-bold text-lg leading-5 pb-2'>Subscription</h2>
                                </header>
                                <BuySubscriptionCard
                                    subscription={{
                                        id: sub.subscriptionId,
                                        priceMonthly: sub.monthlyPrice ?? 0,
                                        priceYearly: sub.yearlyPrice ?? 0,
                                        yearlyDiscountActive: sub.yearlyDiscountActive,
                                        yearlyDiscountPercentage: sub.yearlyDiscountPercentage ?? 0,
                                        yearlyDiscountPrice: sub.yearlyDiscountPrice ?? 0,
                                        discountType: getDiscountType(sub.yearlyDiscountPrice, sub.yearlyDiscountPercentage),
                                        freeVideoCall: sub.videoMessages ?? 0,
                                        freeVoiceCall: sub.voiceMessages ?? 0,
                                        freeMessageBlock: sub.textMessageBlock ?? 0,
                                        stripeMonthlyPriceId: sub.stripeMonthlyPriceId,
                                        stripeYearlyPriceId: sub.stripeYearlyPriceId,
                                    }}
                                    isDisabled={isPreviewMode}
                                    sellerFullName={userProfile?.creatorName!}
                                    sellerUsername={userProfile?.username!}
                                    creatorId={userProfile?.id!}
                                />
                            </section>
                        )}
                        <section id={NavigationProfileAnchors.DIGITAL_PRODUCTS} className='space-y-4'>
                            <header className='flex gap-3 items-center'>
                                <h2 className='font-bold text-lg leading-5'>Digital products</h2>
                                <span className='w-[24px] h-[24px] p-1 bg-gray-light text-center text-gray font-semibold
                                text-xs rounded-full'>
                                    {digitalProducts.length}
                                </span>
                            </header>
                            <div className='flex flex-col gap-3'>
                                {digitalProducts.slice(0, maxDisplayProducts).map((product, i) => (
                                    <PreviewDigitalProductToBuy
                                        disableDetails={false}
                                        disableBuy={isPreviewMode}
                                        product={product} key={i}
                                        creatorUsername={userProfile?.username + ''}
                                        creatorId={userProfile?.id!}/>
                                ))}
                            </div>
                            {/*TODO this button should display sidebar with all products*/}
                            {digitalProducts.length > 0 && (
                                <AppButton
                                    onClick={showMoreProducts}
                                    className='w-full text-gray text-xs hover:text-primary font-semibold border-special-gray'
                                    label={`Show all products ${(digitalProducts.length - maxDisplayProducts) > 0 ? "(+" + (digitalProducts.length - maxDisplayProducts) + ")" : ""}`}/>
                            )}
                        </section>
                        <section id={NavigationProfileAnchors.BIO}>
                            <header className='font-bold text-lg'><h3>Bio</h3></header>
                            <p className='font-medium text-base text-gray text-wrap break-all'>
                                {userProfile?.longBio}
                            </p>
                        </section>
                    </div>
                </div>
            </div>
            <PurchaseProductModal modalPlace="publicProfile" onSuccessBuyProduct={updateProduct}/>
            <PurchaseSubscriptionModal/>
        </AppContentWrapper>
    )
}

export default PublicCcProfile;
