import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import {useSidebar} from "../hooks/use-sidebar";
import {useMediaQuery} from "react-responsive";
import {SidebarIds} from "../types/sidebar-ids.interface";
import {classNames} from "../../utils/class-names";
import {animated, useTransition} from "@react-spring/web";

interface SidebarWrapperProps {
    children: React.ReactNode;
    id: SidebarIds;
    forceMobile?: boolean;
    className?: string;
    classNameSidebar?: string;
    side: 'left' | 'right';
    appendTo?: HTMLElement;
    zIndex?: number;
}

const AppSidebarWrapper: React.FC<SidebarWrapperProps> = (props: SidebarWrapperProps) => {
    const {
        children,
        id,
        forceMobile = false,
        side,
        className,
        classNameSidebar,
        appendTo,
        zIndex = 40,
    } = props;

    const {isOpen, closeSidebar, registerSidebar} = useSidebar();
    const isDesktop = useMediaQuery({minWidth: 1024});
    const isMobileView = !isDesktop || forceMobile;

    const transitionsSidebar = useTransition(isOpen(id) || (isDesktop && !forceMobile), {
        from: {opacity: isMobileView ? 0 : 1},
        enter: {opacity: 1},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    const transitionsBackdrop = useTransition(isOpen(id) && isMobileView, {
        from: {opacity: 0},
        enter: {opacity: isMobileView ? 0.2 : 0},
        leave: {opacity: 0},
        config: {tension: 200, friction: 30},
    });

    useEffect(() => {
        registerSidebar(id);
    }, [id]);

    const sidebarContent = (
        <div className={classNames(
            'flex',
            isMobileView && 'absolute inset-0',
            !isOpen(id) && 'pointer-events-none'
        )}>
            {transitionsBackdrop((styles, item) =>
                    item && (
                        <animated.div
                            style={{
                                ...styles,
                                zIndex: zIndex ?? 10,
                            }}
                            className={classNames(
                                isMobileView && "fixed inset-0 bg-special-gray",
                            )}
                        />
                    )
            )}
            {transitionsSidebar((style, item) =>
                    item && (
                        <animated.div
                            style={style}
                            className={classNames(
                                `z-40 flex w-full pointer-events-auto ${className} `,
                                side === 'right' && 'flex-row-reverse',
                                isMobileView && 'gap-3 p-3'
                            )}
                        >
                            <div className={classNames(
                                "bg-white lg:static lg:block z-40 h-full transition-all max-w-sm duration-300 w-fit",
                                isDesktop && !forceMobile && 'flex h-full',
                                isMobileView && 'relative top-0 left-0 rounded-3xl shadow-lg max-h-full w-full overflow-hidden',
                                classNameSidebar
                            )}>
                                {children}
                            </div>
                            {isMobileView && (
                                <button
                                    className="flex items-center justify-center z-40 max-w-[32px] min-w-[32px] flex-1 w-[32px] h-[32px] mt-9 p-2 rounded-xl bg-white focus:outline-none "
                                    onClick={() => closeSidebar()}
                                >
                                    <CrossIcon className="h-fit w-[8px] min-w-[8px] text-special-gray"/>
                                </button>
                            )}
                        </animated.div>
                    )
            )}
        </div>
    );

    return appendTo ? ReactDOM.createPortal(sidebarContent, appendTo) : sidebarContent;
};

export default AppSidebarWrapper;
