import NotificationService from "../../common/services/notification.service";
import ApiService from "../../../axios/api.service";
import {AxiosError} from "axios";
import {CheckStatusPromotionRequest} from "../models/interfaces/check-status-promotion-request.interface";

class BecomeCreatorApiService extends ApiService {

    public async submitFormAndGoToPersonalExperiences(formData: FormData) {
        return this.post<void>('/account/promotion-request', formData, {
            errorMessage: ""
        }).catch((error: AxiosError) => {
            const response = error.response

            if (response) {
                const data = response.data
                switch (response.status) {
                    case 422:
                        if (Array.isArray(data)) {
                            if (data[0]?.errorCode === "USERNAME_ALREADY_TAKEN") NotificationService.error("This username is already taken.")
                        }
                        break;
                }
            }

            throw error;
        });
    }

    public async sendCreatorBasicData(formData: FormData) {
        return this.put<void>('/account/creator-basic-data', formData, {
            errorMessage: "",
            skipDefault404Handling:true,
        }).catch((error: AxiosError) => {
            const response = error.response

            if (response) {
                const data = response.data
                switch (response.status) {
                    case 422:
                        if (Array.isArray(data)) {
                            if (data[0]?.errorCode === "USERNAME_ALREADY_TAKEN") NotificationService.error("This username is already taken.")
                        }
                        break;
                }
            }

            throw error;
        });
    }

    public async reloadSessionOnAcceptedPromotionRequest(): Promise<CheckStatusPromotionRequest> {
        return await this.get<CheckStatusPromotionRequest>(`/reload-session-on-accepted-promotion-request`, {})
    }
}

export default new BecomeCreatorApiService();
