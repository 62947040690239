const SkeletonFormField = ({children}: any) => {
    return (
        <div className="w-full h-10 bg-gray-200 animate-pulse rounded-lg">
            {children}
        </div>
    )

}

export default SkeletonFormField
