import {FormProvider, useForm} from "react-hook-form";
import LogoutButton from "./logout-button";
import TextFormField from "../../common/components/forms/text-form-field";
import AppButton from "../../common/components/app-button";
import {yupResolver} from "@hookform/resolvers/yup";
import useAuth from "../hooks/use-auth";
import {AuthenticatedUser} from "../models/interfaces/authenticated-user.interface";
import {schemaUsername} from "../constants/schema-legacy-username";
import Tooltip from "../../common/components/tooltip";
import React from "react";


interface LegacyUsernameFormProps {
    onSuccess?: (currentUser: AuthenticatedUser) => Promise<void>;
}

const LegacyUsernameForm = ({onSuccess}: LegacyUsernameFormProps) => {
    const {currentUser, loading, updateAuthenticationField, updateUsername} = useAuth();
    const methods = useForm<{ username: string }>({
        resolver: yupResolver(schemaUsername),
        mode: "onSubmit",
    });

    const handleSetUsername = async () => {
        const isValid = await methods.trigger();
        if (!isValid) return;

        const {username} = methods.getValues();
        try {
            await updateUsername(username);
            const updatedUser: AuthenticatedUser = {
                ...currentUser!,
                username
            };
            updateAuthenticationField('user', updatedUser);
            onSuccess && (await onSuccess(updatedUser));
        } catch (error: any) {
            if (error.response?.status === 409) {
                methods.setError('username', {
                    type: 'manual',
                    message: 'This username is already taken'
                });
            } else {
                console.error("Username update failed:", error);
            }
        }
    };

    return (
        <FormProvider {...methods}>
            <form className="flex flex-col gap-y-6 p-4 max-w-md mx-auto h-full min-h-fit">
                <div className='flex-grow md:flex-grow-0 space-y-4'>
                    <div className="flex items-center gap-3 justify-between">
                        <h2 className="text-[1.75rem] md:text-[2.5rem] font-bold">Set username</h2>
                        <span className='inline-block md:hidden'><LogoutButton/></span>
                    </div>
                    <p className='font-semibold text-lg md:text-[1.375rem] leading-7'>
                        Choose your unique username that will be visible to other users:
                    </p>

                    <div>
                        <TextFormField
                            disabled={loading}
                            name="username"
                            label="Username"
                            placeholder="Enter your username"
                            autoComplete="username"
                        />

                        <div className='flex text-special-gray text-xs leading-3 gap-1 mt-2'>
                            <Tooltip/>
                            <span>Use only letters, numbers and some special signs ($ _ -)</span>
                        </div>
                    </div>
                </div>

                <AppButton
                    type='button'
                    loading={loading}
                    onClick={methods.handleSubmit(handleSetUsername)}
                    label='Set username'
                    className='w-full text-white text-sm py-[9px] h-fit font-semibold bg-primary rounded-2xl'
                />
            </form>
        </FormProvider>
    );
};

export default LegacyUsernameForm;
