import {useState} from 'react';
import UserApiService from "../services/user-api.service";
import {UserBasicInfoPutRequest} from "../models/interfaces/user-basic-info-put-request.interface";
import {UserAdditionalInfoPutRequest} from "../models/interfaces/user-additional-info-put-request.interface";

export const useUserApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const changeFanOnBoarding = async (): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await UserApiService.changeFanOnBoarding();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const changeCcOnBoarding = async (): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await UserApiService.changeCcOnBoarding();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const updateRegistrationStep = async (step: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await UserApiService.updateRegistrationStep(step);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const sendBasicInfo = async (data: UserBasicInfoPutRequest): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await UserApiService.updateBasicUserInfo(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const sendAdditionalInfo = async (data: UserAdditionalInfoPutRequest): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await UserApiService.updateAdditionalUserInfo(data);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const getFreeCoinsForFirstDownloadApp = async (coins: number): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await UserApiService.getFreeCoinsForFirstDownloadApp(coins);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        changeFanOnBoarding,
        changeCcOnBoarding,
        updateRegistrationStep,
        sendBasicInfo,
        sendAdditionalInfo,
        getFreeCoinsForFirstDownloadApp,
    };
};
