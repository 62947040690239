import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import {RegistrationSteps} from "../models/enums/registration-steps.enum";
import {RegistrationStepsService} from "../services/registration-steps.service";

const useGuardRegistrationSteps = (currentUser: AuthenticatedUser | undefined): boolean => {
    if (!currentUser) return false;

    const currentStep = RegistrationStepsService.getCurrentStep(currentUser);
    return currentStep === RegistrationSteps.COMPLETED;
};

export default useGuardRegistrationSteps;
