export enum ModalIds {
    LOGIN_REQUIRED = 'LOGIN_REQUIRED',
    FOLLOW_REQUIRED = "FOLLOW_REQUIRED",
    PURCHASE_PROMPT = "PURCHASE_PROMPT",
    PURCHASE_SUBSCRIPTION = "PURCHASE_SUBSCRIPTION",
    PURCHASE_YC = "PURCHASE_YC",
    SUCCESS_PURCHASE_CREDITS = "SUCCESS_PURCHASE_CREDITS",
    CONFIRMATION_UPDATE_SUBSCRIPTION = "CONFIRMATION_UPDATE_SUBSCRIPTION",
    GET_STREAM_MESSAGE_OPTIONS = "GET_STREAM_MESSAGE_OPTIONS",
    CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
    RESTORE_SUBSCRIPTION = "RESTORE_SUBSCRIPTION",
    GET_DOLLAR_FOR_ADDITIONAL_ACCOUNT_INFO = "GET_DOLLAR_FOR_ADDITIONAL_ACCOUNT_INFO",
}
