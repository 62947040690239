import useAuth from "../../auth/hooks/use-auth";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import SettingsHeader from "../components/settings-header";
import NotificationsFan from "../components/notifications-fan";
import NotificationsCC from "../components/notifications-cc";

export interface AppFanFormValues {
    isNotificationsApp: boolean,
    isTextMessagesApp: boolean,
    isVoiceMessagesApp: boolean,
    isVideoMessagesApp: boolean,
    isYCreditsApp: boolean,
}

export interface EmailFanFormValues {
    isNotificationsEmail: boolean,
    isTextMessagesEmail: boolean,
    isVoiceMessagesEmail: boolean,
    isVideoMessagesEmail: boolean,
    isYCreditsEmail: boolean
}

export interface AppCCFormValues {
    isNotificationsApp: boolean,
    isTextMessagesApp: boolean,
    isVoiceMessagesApp: boolean,
    isVideoMessagesApp: boolean,
    isNewFanApp: boolean,
    isNewSubscriptionApp: boolean,
    isPurchasePeApp: boolean,

}

export interface EmailCCFormValues {
    isNotificationsEmail: boolean,
    isTextMessagesEmail: boolean,
    isVoiceMessagesEmail: boolean,
    isVideoMessagesEmail: boolean,
    isNewFanEmail: boolean,
    isNewSubscriptionEmail: boolean,
    isPurchasePeEmail: boolean,
}


export enum NotificationType {
    App, Email
}

const NotificationsView = () => {

    const {currentUser} = useAuth();

    return (
        <div className="md:min-w-[300px] w-full">
            <SettingsHeader title='Notifications' className="mb-8 md:mb-12"/>
            <div className="md:flex w-full gap-x-24 gap-y-12 flex-nowrap @[700px]:flex-wrap">
                {currentUser!.authority == UserAuthority.FAN ? (
                    <NotificationsFan/>
                ) : (
                    <NotificationsCC/>
                )}
            </div>
        </div>
    )
}

export default NotificationsView;
