/**
 * Extracts the domain and port part from a URL, optionally replacing the port if present.
 * Additionally removes the ".backend" part from the domain if it exists.
 * @param url - The full URL string.
 * @param newPort - The port to replace with if a port is present in the URL.
 * @returns The domain with the replaced port, or just the domain if no port is present, without ".backend" if present.
 * @example
 * console.log(extractDomain(urlWithPort, "3000"));  // Output: "192.168.10.79:3000"
 * console.log(extractDomain(urlWithoutPort));        // Output: "production-site.com"
 */
export const extractDomain = (url: string, newPort?: number): string => {
    const match = url.match(/\/\/([^/:]+)(:\d+)?/);

    if (match) {
        let domain = match[1];
        const port = match[2];

        // Remove ".backend" from the domain if it exists
        domain = domain.replace('.backend', '');

        if (port && newPort) {
            return `${domain}:${newPort}`;
        }

        return domain;
    }

    return '';
};

/**
 * Removes the protocol (http or https) from the given URL.
 *
 * @param url - The full URL string.
 * @returns The URL without the protocol.
 * @example
 * console.log(removeProtocol("https://192.168.10.79:8000")); // Output: "192.168.10.79:8000"
 * console.log(removeProtocol("http://production-site.com"));  // Output: "production-site.com"
 */
export const removeProtocol = (url: string): string => {
    return url.replace(/^(https?:\/\/)/, '');
};

