import React, {useEffect, useMemo} from 'react';
import {useFormContext} from 'react-hook-form';
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import Uploady from '@rpldy/uploady';
import Base64Helper from "../../../utils/base64-helper";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import AppButton from "../../common/components/app-button";
import TextFormField from "../../common/components/forms/text-form-field";
import {classNames} from "../../../utils/class-names";
import StepDot, {StepDotState as State} from '../../common/components/app-dot';
import {ReactComponent as InstaIcon} from '@assets/icons/socials/instagram-logo.svg';
import {ReactComponent as FacebookIcon} from '@assets/icons/socials/facebook-logo.svg';
import {ReactComponent as LinkedInIcon} from '@assets/icons/socials/linkedin-logo.svg';
import {ReactComponent as TwitterIcon} from '@assets/icons/socials/twitter-logo.svg';
import {ReactComponent as YoutubeIcon} from '@assets/icons/socials/youtube-logo.svg';
import TextareaFormField from '../../common/components/forms/textarea-form-field';
import ProfileBackground from "../components/profile-background";
import ProfileImage from "../components/profile-image";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import SkipStepBecomeCreator from "../../core/components/ui/skip-step-become-creator";
import WarningMessage from "../../common/components/warning-message";
import DescriptionFormField from '../../common/components/forms/description-form-field';

interface StepOneProps {
    prevStep: () => void;
    nextStep: () => void;
    showPrevButton?: boolean;
    onSkipAll?: UniversalClickHandler;
    hideStepper?: boolean;
}

const StepOne: React.FC<StepOneProps> = ({prevStep, nextStep, showPrevButton = true, onSkipAll, hideStepper = false}) => {
    const {getValues, setValue, watch} = useFormContext();
    const [showError, setShowError] = React.useState(false);
    const values = watch();
    useEffect(() => {
        const subscription = watch((value, {name}) => {
            if (name?.includes('SocialHandle') && showError) {
                setShowError(false);
            }
        });

        return () => subscription.unsubscribe();
    }, [watch, showError]);

    const checkIfNotExist = (value: string) => !(typeof value === 'undefined' || value === null || value.trim().length === 0)
    const disabled = useMemo(() => !(checkIfNotExist(values.firstName) && checkIfNotExist(values.lastName) && checkIfNotExist(values.userName)), [getValues()])

    const hasAnySocialMedia = () => {
        const values = getValues();
        return Boolean(
            values.instagramSocialHandle ||
            values.facebookSocialHandle ||
            values.linkedinSocialHandle ||
            values.twitterSocialHandle ||
            values.youtubeSocialHandle
        );
    };

    const handleNextClick = () => {
        if (!hasAnySocialMedia()) {
            setShowError(true);
            return;
        }
        setShowError(false);
        nextStep();
    };

    return (
        <div className='md:max-w-[450px] md:ml-auto md:mr-auto'>
            <div className="space-y-3">
                <div className="flex gap-1 justify-between">
                    {showPrevButton && (
                        <AppButton
                            type="button"
                            label=""
                            className="bg-gray-light text-gray w-10 h-10 !rounded-2xl border-0"
                            onClick={prevStep}
                        >
                            <ChevronLeftIcon className="w-2 h-3"/>
                        </AppButton>
                    )}
                    {onSkipAll && (
                        <span className='inline-block md:hidden'><SkipStepBecomeCreator onClick={onSkipAll}/></span>
                    )}
                </div>
                <h1 className="font-bold text-4xl">Become a creator</h1>
                <div className="text-gray md:my-3">Fill the fields below to complete your creator profile</div>
            </div>

            <div className="relative top-0 left-0 mb-[100px]">
                <div className="relative inset-0 h-[260px] mt-5 overflow-clip rounded-3xl">
                    <Uploady autoUpload={false}>
                        <ProfileBackground
                            isEditable={true}
                            className="lg:rounded-3xl md:rounded-none"
                            onChange={(imageSrc) => setValue('backgroundProfileImage', imageSrc, {shouldDirty: true})}
                            backgroundImage={Base64Helper.removeBase64Prefix(getValues('backgroundProfileImage'))}
                        />
                    </Uploady>
                </div>
                <div className="flex justify-center absolute bottom-[-60px] left-1/2 -translate-x-1/2">
                    <Uploady autoUpload={false}>
                        <ProfileImage
                            isEditable={true}
                            avatar={Base64Helper.removeBase64Prefix(getValues('profileImage'))}
                            onChange={(imageSrc) => setValue('profileImage', imageSrc, {shouldDirty: true})}
                            role={UserAuthority.CONTENT_CREATOR}
                            showRole={true}
                        />
                    </Uploady>
                </div>
            </div>

            <div className="flex space-x-4 my-3 justify-center">
                <TextFormField isRequired={true} id="firstName" name="firstName" label="First name"
                               placeholder='ex. John'/>
                <TextFormField isRequired={true} id="lastName" name="lastName" label="Last name" placeholder='ex. Doe'/>
            </div>

            <div className="my-3 flex flex-col gap-y-3">
                <DescriptionFormField id="bio" name="bio" label="Short bio" maxLength={80}/>
                <TextareaFormField id="longBio" name="longBio" label="Bio" maxChar={500}/>
            </div>

            {/* <span className='font-semibold leading-[19.2px] '>Contact prefered language</span> <InfoIcon
            className='inline-block w-[10px] h-[10px]'/>
            <SelectFormField name="language" label="Language" valueKey='value' labelKey='label' options={[
                {
                    value: "PL",
                    label: "Polish"
                },
                {
                    value: "EN",
                    label: "English"
                },
                {
                    value: "DE",
                    label: "German"
                }
            ]}/> */}
            <div className='space-y-3 mt-3'>
                <span className='font-bold leading-[19.2px] '>Social media profiles</span>
                <TextFormField
                    className={classNames(showError && "border-red-400")}
                    id="instagramSocialHandle"
                    name="instagramSocialHandle"
                    label="Instagram"
                    icon={InstaIcon}/>
                <TextFormField
                    className={classNames(showError && "border-red-400")}
                    id="facebookSocialHandle"
                    name="facebookSocialHandle"
                    label="Facebook"
                    icon={FacebookIcon}/>
                <TextFormField
                    className={classNames(showError && "border-red-400")}
                    id="linkedinSocialHandle"
                    name="linkedinSocialHandle"
                    label="LinkedIn"
                    icon={LinkedInIcon}/>
                <TextFormField
                    className={classNames(showError && "border-red-400")}
                    id="twitterSocialHandle"
                    name="twitterSocialHandle"
                    label="X(Twitter)"
                    icon={TwitterIcon}/>
                <TextFormField
                    className={classNames(showError && "border-red-400")}
                    id="youtubeSocialHandle"
                    name="youtubeSocialHandle"
                    label="Youtube"
                    icon={YoutubeIcon}/>
            </div>

            {showError && (
                <div className="mt-2 !px-2">
                    <WarningMessage
                        text="Please add at least one social media link to continue"
                        initStateShow={true}
                        shownClassName="flex !px-[0.675rem] !mx-0 items-center h-[32px] min-w-[32px] !bg-semantic-warning-pastel"
                        disableToggleShow={false}
                    />
                </div>
            )}

            {!hideStepper && (
                <>
                    <div className={classNames('flex space-x-5 my-6 justify-center items-center')}>
                        <StepDot state={State.ACTIVE}/>
                        <StepDot state={State.NEXT}/>
                        <StepDot state={State.NEXT}/>
                        <StepDot state={State.NEXT}/>
                        <StepDot state={State.NEXT}/>
                    </div>

                    <AppButton
                        type="button"
                        label="Next"
                        className="bg-primary text-white w-full !rounded-3xl"
                        onClick={handleNextClick}
                        disabled={disabled}
                    />
                </>
            )}
        </div>
    );
};

export default StepOne;
