import WrapperCard from "../../common/components/wrapper-card";
import {ContactType} from "../../core/models/enums/communications-type.enum";

import Tooltip from "../../common/components/tooltip";
import {useRef} from "react";
import {classNames} from "../../../utils/class-names";
import Coin from "../../core/components/ui/coin";
import {renderIconBaseOffer} from "../utils/render-icon-base-offer";

interface CardBaseOfferProps {
    title: string,
    description: string,
    price: number,
    type: ContactType
    shortInfoTooltip?: string,
    fullInfoTooltip?: string,
    isEnabled: boolean,
}

const CardBaseOffer = (props: CardBaseOfferProps) => {
    const {price, description, title, type, shortInfoTooltip, isEnabled, fullInfoTooltip} = props;
    const contentRef = useRef(null);

    return (
        <WrapperCard className={classNames(
            'w-full !h-full !min-h-full !p-3 relative',
            isEnabled ? 'bg-gray-light border-0 text-black' : 'bg-white !text-special-gray'
        )}>
            {!isEnabled && (
                <div className="absolute inset-0 pointer-events-none mix-blend-screen bg-gray-light opacity-30 rounded-3xl"></div>
            )}
            <div ref={contentRef} className={classNames(
                'flex flex-col gap-3 h-full',
                shortInfoTooltip && 'cursor-pointer'
            )}>
                <div className='flex gap-2 items-center font-semibold text-[0.625rem] uppercase'>
                    {renderIconBaseOffer(type)}
                    {title}
                </div>
                {isEnabled ? (
                    <div className='flex flex-col'>
                        <span className='text-[1.4rem] flex items-center gap-2 font-bold'>{price}<Coin/></span>
                        <div className='text-xs items-center gap-1 text-gray'>
                            <span>{description}</span>
                            {shortInfoTooltip && (
                                <Tooltip
                                    preferredPositionX='right'
                                    preferredPositionY='top'
                                    shortInfo={shortInfoTooltip}
                                    info={fullInfoTooltip}
                                    triggerMode='click'
                                    clickActivationRef={contentRef}/>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='text-xs font-semibold text-special-gray leading-[0.9rem]'>
                        Unavailable from this Creator
                    </div>
                )}
            </div>
        </WrapperCard>
    )
}

export default CardBaseOffer
