import SelectorOrderType from "./selector-order-type";
import React, {useContext, useState} from "react";
import {Orders} from "../models/enums/order-settings.enum";
import {Case, Switch} from "../../common/components/switch-case";
import CategoriesList from "./categories-list";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import AppButton from "../../common/components/app-button";
import {DigitalProductCategory} from "../../core/models/interfaces/digital-product-category.interface";
import {useSidebar} from "../../../layout/hooks/use-sidebar";
import {SidebarIds} from "../../../layout/types/sidebar-ids.interface";
import useEffectOnce from "../../common/hooks/use-effect-once";

const OrderSettings = () => {
    const [type, setType] = useState<Orders>(Orders.CATEGORY)
    const {products, productCategories, saveAllCategories, setEditedCategory} = useContext(SessionCreatorToolsContext)!
    const [updatedProductCategories, setUpdatedProductCategories] = useState<DigitalProductCategory[]>(productCategories);
    const {closeSidebar, registerOnCloseCallback} = useSidebar();

    const handleUpdateLocalCategories = (updatedCategories: DigitalProductCategory[]) => setUpdatedProductCategories(updatedCategories);
    const handleSaveChanges = async () => {
        await saveAllCategories(updatedProductCategories)
        closeSidebar();
    }

    useEffectOnce(() => {
        registerOnCloseCallback(
            SidebarIds.SETTINGS_DIGITAL_PRODUCTS,
            () => setEditedCategory(null),
        )
    })

    return (
        <div className="p-6 pt-10 flex flex-col justify-between h-full gap-4">
            <SelectorOrderType selectedType={type} onSelect={setType}/>
            <div className="overflow-y-auto h-full">
                <Switch value={type}>
                    <Case case={Orders.PRODUCT}></Case>
                    <Case case={Orders.CATEGORY}>
                        <CategoriesList products={products} categories={updatedProductCategories}
                                        onChanges={handleUpdateLocalCategories}/>
                    </Case>
                </Switch>
            </div>
            <AppButton
                type="button"
                onClick={handleSaveChanges}
                label="Save changes"
                className="max-h-[40px] bg-primary !rounded-2xl border-0 text-white py-[10px] w-full"
            />
        </div>
    )
}

export default OrderSettings
