import {ReactComponent as HomeIcon} from "@assets/icons/home.svg";
import {ReactComponent as ChatIcon} from "@assets/icons/chat.svg";
import {ReactComponent as WalletIcon} from "@assets/icons/wallet.svg";
import {ReactComponent as ProfileIcon} from "@assets/icons/profile.svg";
import {ReactComponent as SettingsIcon} from "@assets/icons/settings.svg";
import {ReactComponent as LayersIcon} from "@assets/icons/layers.svg";
import {ReactComponent as PasswordIcon} from "@assets/icons/password.svg";
import {ReactComponent as DocIcon} from "@assets/icons/doc.svg";
import {ReactComponent as ProductsIcon} from "@assets/icons/products.svg";
import {ReactComponent as SupportIcon} from "@assets/icons/support.svg";
import {UserAuthority} from "../../features/auth/models/enums/user-authority.enum";
import {ReactComponent as NotificationIcon} from "@assets/icons/notifications.svg";
import {ReactComponent as TilesIcon} from "@assets/icons/tiles.svg";

export const navigation = [
    {
        name: 'Home',
        href: '/app',
        Icon: HomeIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    {
        name: 'Feed',
        href: '/app/feed',
        Icon: TilesIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    {
        name: 'Messages',
        href: '/app/messages',
        Icon: ChatIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    {
        name: 'Creator Tools',
        href: '/app/cc-tools',
        Icon: LayersIcon,
        accessRoles: [UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    {
        name: 'Purchases',
        href: '/app/purchases/subscriptions',
        Icon: ProductsIcon,
        accessRoles: [UserAuthority.CONTENT_CREATOR, UserAuthority.FAN]
    },
    {
        name: 'Wallet',
        href: '/app/wallet',
        Icon: WalletIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    {
        name: 'My profile',
        href: '/app/my-profile',
        Icon: ProfileIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    {
        name: 'Settings',
        href: '/app/settings',
        Icon: SettingsIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
]

export const settingsNavigation = [
    {
        name: 'Notifications',
        href: '/notifications',
        Icon: NotificationIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    // {
    //     name: 'Preferences',
    //     href: '/preferences',
    //     Icon: AdjustmentsHorizontalIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
    {
        name: 'Change password',
        href: '/change-password',
        Icon: PasswordIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    {
        name: 'Support',
        href: '/support',
        Icon: SupportIcon,
        accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR, UserAuthority.SUPPORT]
    },
    // {
    //     name: 'Terms',
    //     href: '/terms',
    //     Icon: DocIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
    // {
    //     name: 'Privacy policy',
    //     href: '/privacy-policy',
    //     Icon: ClipboardDocumentCheckIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
    // {
    //     name: 'Advanced',
    //     href: '/advanced',
    //     Icon: WrenchScrewdriverIcon,
    //     accessRoles: [UserAuthority.FAN, UserAuthority.CONTENT_CREATOR]
    // },
]
