import {useState} from "react";
import {ReactComponent as ReportIcon} from "@assets/icons/report.svg";
import {classNames} from "../../../utils/class-names";

interface ErrorMessageProps {
    text: any,
    disableToggleShow?: boolean,
    initStateShow?: boolean,
    hiddenClassName?: string,
    shownClassName?: string,
}

const WarningMessage = (props: ErrorMessageProps) => {
    const {
        text,
        shownClassName,
        hiddenClassName,
        initStateShow = false,
        disableToggleShow = false,
    } = props
    const [show, setShow] = useState<boolean>(initStateShow);

    return (
        <div
            onClick={() => !disableToggleShow && setShow(prev => !prev)}
            className={classNames(
                "rounded-xl inline-block max-h-[32px] leading-[0.9rem]",
                show ? "mx-[2px] gap-1 w-full p-0" : "flex justify-center items-center h-[32px] min-w-[32px] max-w-[32px] w-fit bg-semantic-warning-pastel ",
                show ? shownClassName : hiddenClassName,
                !disableToggleShow && "cursor-pointer"
            )}>
            <ReportIcon className={classNames(
                "inline-block leading-[0.9rem] text-semantic-warning h-[12px] w-[12px] min-w-[12px]",
                show && "relative mr-1"
            )}/>
            {show && (
                <span className="inline font-medium text-xs leading-[0.9rem] text-semantic-warning">{text}</span>
            )}
        </div>
    )
}

export default WarningMessage
