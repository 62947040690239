import Tooltip from "../../common/components/tooltip";
import AppButton from "../../common/components/app-button";
import {PurchasedSubscription} from "../../core/models/interfaces/purchased-subscription.interface";
import React, {useContext, useEffect} from "react";
import {PurchasesSubscriptionsContext} from "../../purchases/contexts/purchases-subscriptions-context";
import {DateHelper} from "../../../utils/date-helper";
import {getSubscriptionStatus} from "../helpers/get-subscription-status";
import {UserProfileContext, UserProfileContextProps} from "../../profile/contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import {useModal} from "../../common/hooks/use-modal";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {SubscriptionModalData} from "../../purchases/models/interfaces/subscription-modal-data.interface";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {DataFollowRequiredModal} from "../../core/components/follow-required-modal";
import useRequiredLogin from "../../auth/hooks/use-required-login";
import useRequiredFollow from "../../core/hooks/use-required-follow";
import {RestoreSubscriptionModalData} from "../models/interfaces/restore-subscription-modal-data.interface";

const RestorePurchasedSubscriptionButton = ({subscription}: { subscription: PurchasedSubscription }) => {
    const activeToDate = new Date(subscription.activeTo);
    const untilDate = DateHelper.formatDate(activeToDate, "DD.MM");
    const {canRestore} = getSubscriptionStatus(subscription);

    const {showModal} = useModalWithData<RestoreSubscriptionModalData>(ModalIds.RESTORE_SUBSCRIPTION);
    const openRestoreSubscriptionModalHandle = async () => showModal(subscription)

    return (
        <div className="flex justify-between gap-3 items-center">
            <span className="text-xs text-special-gray font-medium flex">
                <Tooltip classNameIcon="relative top-[2px]"/>
                You still can use the current free messages & calls until {untilDate} under your active subscription
            </span>
            {canRestore && (
                <AppButton
                    onClick={openRestoreSubscriptionModalHandle}
                    label="Restore"
                    classNameContentWrapper=""
                    className="bg-white border-1 border-special-gray text-special-gray font-semibold text-sm min-h-[40px]"
                />
            )}
        </div>
    )
}

export default RestorePurchasedSubscriptionButton
