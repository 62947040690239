import AppHeader from "../../../layout/app-header";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import CCToolsMenu from "../../digital-products/components/cc-tools-menu";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import React from "react";
import FormSubscriptionCreatorTools from "../components/form-subscription-creator-tools";
import {FormSubscriptionOfferProvider} from "../contexts/form-subscription-offer-context";

const EditSubscriptionView = () => {
    return (
        <>
            <AppHeader customContent={true}>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5 max-w-full'>
                        <div className='flex items-center w-fit'>
                            <div className='mr-3'><AppHamburgerMenu/></div>
                            <span
                                className={'font-bold text-2xl lg:text-[40px] text-primary-darken'}>Creator Tools</span>
                        </div>
                        <CCToolsMenu/>
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-full'>
                <FormSubscriptionOfferProvider>
                    <FormSubscriptionCreatorTools/>
                </FormSubscriptionOfferProvider>
            </AppContentWrapper>
        </>
    )
}

export default EditSubscriptionView
