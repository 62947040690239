import WrapperCard from "../../../common/components/wrapper-card";
import Coin from "../../../core/components/ui/coin";
import YDollarsConversionService from "../../../purchases/services/ydollars-conversion.service";

interface SubscriptionEarningsStatsCardProps {
    monthlySubscriptionEarnings: number,
    yearlySubscriptionEarnings: number,
}

const SubscriptionEarningsStatsCard = (props: SubscriptionEarningsStatsCardProps) => {
    const {
        monthlySubscriptionEarnings = 0,
        yearlySubscriptionEarnings = 0
    } = props

    return (
        <WrapperCard className="bg-secondary-pastel border-0 flex flex-col gap-5 w-full p-6">
            <div className="flex flex-col">
                <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                    Subscription earnings
                </span>
                <span className="inline-flex gap-1 items-center font-bold text-black text-[1.375rem] leading-[1.65rem]">
                    {YDollarsConversionService.formatNumberWithSpaceSeparator(monthlySubscriptionEarnings + yearlySubscriptionEarnings)}
                    <Coin className="inline-block w-[1.75rem] h-[1.75rem]"/>
                </span>
            </div>
            <div className="grid gap-3 grid-cols-2">
                <div className="flex flex-col">
                    <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                        Monthly subs.
                    </span>
                    <span className="inline-flex gap-1 items-center font-medium text-black text-base leading-[0.9rem]">
                        {YDollarsConversionService.formatNumberWithSpaceSeparator(monthlySubscriptionEarnings)}
                        <Coin className="inline-block"/>
                    </span>
                </div>
                <div className="flex flex-col">
                    <span className="text-xs leading-[0.9rem] font-medium text-special-gray">
                        Yearly subs.
                    </span>
                    <span className="inline-flex gap-1 items-center font-medium text-black text-base leading-[0.9rem]">
                        {YDollarsConversionService.formatNumberWithSpaceSeparator(yearlySubscriptionEarnings)}
                        <Coin className="inline-block"/>
                    </span>
                </div>
            </div>
        </WrapperCard>
    )
}

export default SubscriptionEarningsStatsCard
