import {prefixNameVoiceRecorded} from "../constants/prefix-name-voice-recorded";
import {LocalAttachment} from "stream-chat-react";
import {getAudioDuration} from "./get-audio-duration";
import {getWaveformData} from "./get-waveform-data";

export const processAttachments = async (attachments: LocalAttachment[]) => {
    return Promise.all(
        attachments.map(async (attachment) => {
            if (attachment.title?.includes(prefixNameVoiceRecorded) && attachment.localMetadata?.file) {
                const duration = await getAudioDuration(attachment.localMetadata.file);
                const waveformData = await getWaveformData(attachment.localMetadata.file);

                return {
                    asset_url: attachment.asset_url,
                    file_size: attachment.file_size,
                    mime_type: attachment.mime_type,
                    title: attachment.title,
                    type: "voiceRecording",
                    duration,
                    waveform_data: waveformData,
                };
            }

            return attachment;
        })
    );
};
