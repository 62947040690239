import React from 'react';
import {useFormContext} from "react-hook-form";
import {classNames} from "../../../../utils/class-names";

interface CheckboxFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    className?: string;
    showError?: boolean;
    onChange?: () => void;
}

const CheckboxFormField: React.FC<CheckboxFormFieldProps> = (props: CheckboxFormFieldProps) => {
    const {register,watch, formState: {errors, isSubmitting}} = useFormContext();
    const {
        name,
        label,
        className = '',
        disabled,
        showError,
        onChange,
        ...rest
    } = props;
    const checked = watch(name)

    const isDisabled = disabled !== undefined ? disabled : isSubmitting;

    const handleOnChange = () => {
        if (!disabled && onChange) onChange();
    }

    return (
        <div>
            <label htmlFor={name}
                   className={classNames(
                       "flex items-center gap-2 cursor-pointer font-semibold text-sm leading-[1.05rem]",
                       checked ? "text-black" : "text-special-gray"
                   )}
                   onClick={handleOnChange}>
                <input
                    type="checkbox"
                    id={name}
                    {...register(name)}
                    className={classNames(
                        "appearance-none w-6 h-6 border focus:outline-none focus:ring-2 rounded-lg transition-all duration-200 cursor-pointer",
                        // Stan domyślny
                        "border-special-gray bg-transparent",
                        // Stan zaznaczony
                        "checked:bg-primary-pastel checked:border-primary checked:focus:border-primary focus:ring-primary",
                        // Stan z błędem
                        errors[name] && "!border-red-400 focus:ring-red-400",
                        // Stan wyłączony
                        isDisabled
                            ? "checked:!bg-gray-300 checked:!border-gray-300 opacity-50 cursor-not-allowed"
                            : "hover:shadow-sm"
                    )}
                    disabled={isDisabled}
                    {...rest}
                />
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={classNames(
                        "absolute w-[12px] translate-x-1/2 h-fit text-primary transition-transform duration-200",
                        checked ? "scale-100" : "scale-0",
                    )}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <polyline points="20 6 9 17 4 12" />
                </svg>
                {label}
            </label>
            {showError && errors[name] && <p className="text-red-500 text-xs">{'' + errors![name]!.message}</p>}
        </div>
    );
};

export default CheckboxFormField;
