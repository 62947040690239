import AppHeader from "../../../layout/app-header";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import Breadcrumbs from "../../../layout/components/breadcrumbs";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import React, {useContext} from "react";
import PurchasesMenu from "../components/purchases-menu";
import {
    PurchasesSubscriptionsContext,
    PurchasesSubscriptionsProvider
} from "../contexts/purchases-subscriptions-context";
import {SubscriptionFilterProvider} from "../../subscriptions/contexts/subscription-filter-context";
import PurchasedSubscriptionList from "../../subscriptions/components/purchased-subscription-list";
import SubscriptionStatusSelector from "../../subscriptions/components/subscription-status-selector";
import PurchasedSubscriptionSearchBar from "../../subscriptions/components/purchased-subscription-search-bar";
import RestoreSubscriptionModal from "../../subscriptions/components/restore-subscription-modal";
import CancelSubscriptionModal from "../../subscriptions/components/cancel-subscription-modal";
import {State, ViewStates} from "../../common/components/viewStates";
import {ViewState} from "../../common/models/enums/view-state.enum";
import SkeletonPurchasedSubscriptionItem from "../../subscriptions/components/ui/skeleton-purchased-subscription-item";

const PurchasedSubscriptionsViewWithProvider = () => {
    const {subscriptions, loading, error} = useContext(PurchasesSubscriptionsContext)!;

    return (
        <>
            <AppHeader customContent={true}>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5'>
                        <div className='flex items-center w-fit'>
                            <div className='mr-3'><AppHamburgerMenu/></div>
                            <Breadcrumbs path='Purchases'/>
                        </div>
                        <PurchasesMenu/>
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className='p-4 pt-0 sm:pt-9 bg-white rounded-t-[1.5rem] h-full'>
                <SubscriptionFilterProvider subscriptions={subscriptions}>
                    <ViewStates loading={loading} data={subscriptions} error={error}>
                        <State state={ViewState.LOADING}>
                            <div className="flex flex-col">
                                <SubscriptionStatusSelector subscriptions={subscriptions}/>
                                <div className="grid grid-cols-1 gap-4">
                                    {Array.from({length: 4}).map((_, index) => (
                                        <SkeletonPurchasedSubscriptionItem key={index}/>
                                    ))}
                                </div>
                            </div>
                        </State>
                        <State state={ViewState.FETCHED}>
                            <div className="flex flex-col gap-1 sm:gap-5">
                                <SubscriptionStatusSelector subscriptions={subscriptions}/>
                                <PurchasedSubscriptionSearchBar/>
                                <PurchasedSubscriptionList/>
                            </div>
                            <RestoreSubscriptionModal/>
                            <CancelSubscriptionModal/>
                        </State>
                        <State state={ViewState.EMPTY}>
                            <div className="font-semibold text-base text-center">No subscriptions found.</div>
                        </State>
                    </ViewStates>
                </SubscriptionFilterProvider>
            </AppContentWrapper>
        </>
    )
}

const PurchasedSubscriptionsView = () => {
    return (
        <PurchasesSubscriptionsProvider initFetch={true}>
            <PurchasedSubscriptionsViewWithProvider/>
        </PurchasesSubscriptionsProvider>
    );
}

export default PurchasedSubscriptionsView
