import React, {createContext, ReactNode, useState} from 'react';
import {SidebarIds} from "../types/sidebar-ids.interface";

interface SidebarContextProps {
    openSidebar: (id: SidebarIds) => void;
    closeSidebar: () => void;
    toggleSidebar: (id: SidebarIds) => void;
    isOpen: (id: SidebarIds) => boolean;
    registerSidebar: (id: SidebarIds) => void;
    registerOnCloseCallback: (id: SidebarIds, callback: () => void) => void;  // Rejestracja callbacków
    registeredSidebars: SidebarIds[];
}

export const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

interface SidebarProviderProps {
    children: ReactNode;
}

export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
    const [openSidebarId, setOpenSidebarId] = useState<SidebarIds | null>(null);
    const [registeredSidebars, setRegisteredSidebars] = useState<SidebarIds[]>([]);
    const [onCloseCallbacks, setOnCloseCallbacks] = useState<Map<SidebarIds, (() => void)[]>>(new Map());

    const registerSidebar = (id: SidebarIds) => {
        // @ts-ignore
        setRegisteredSidebars((prev) => [...new Set([...prev, id])]);
    };

    const registerOnCloseCallback = (id: SidebarIds, callback: () => void) => {
        setOnCloseCallbacks((prev) => {
            const newMap = new Map(prev);
            const existingCallbacks = newMap.get(id) || [];

            if (!existingCallbacks.includes(callback)) {
                newMap.set(id, [...existingCallbacks, callback]);
            }
            return newMap;
        });
    };


    const executeOnCloseCallbacks = (id: SidebarIds) => {
        const callbacks = onCloseCallbacks.get(id);
        if (callbacks) {
            callbacks.forEach((callback) => callback());
        }
    };

    const openSidebar = (id: SidebarIds) => {
        if (openSidebarId && openSidebarId !== id) {
            executeOnCloseCallbacks(openSidebarId);
        }
        setOpenSidebarId(id);
    };

    const closeSidebar = () => {
        if (openSidebarId) {
            executeOnCloseCallbacks(openSidebarId);
        }
        setOpenSidebarId(null);
    };

    const toggleSidebar = (id: SidebarIds) => {
        if (openSidebarId === id) {
            executeOnCloseCallbacks(id);
            setOpenSidebarId(null);
        } else {
            if (openSidebarId) {
                executeOnCloseCallbacks(openSidebarId);
            }
            setOpenSidebarId(id);
        }
    };

    const isOpen = (id: SidebarIds) => openSidebarId === id;

    return (
        <SidebarContext.Provider
            value={{
                openSidebar,
                closeSidebar,
                toggleSidebar,
                isOpen,
                registerSidebar,
                registerOnCloseCallback,
                registeredSidebars
            }}
        >
            {children}
        </SidebarContext.Provider>
    );
};
