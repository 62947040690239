import { EmojiPicker } from "stream-chat-react/emojis";
import EmojiIcon from "./emoji-icon";

interface EmojiPickerButtonProps {
    showTrigger: boolean;
}

const EmojiPickerButton = ({ showTrigger }: EmojiPickerButtonProps) => {
    if (!showTrigger) return null;

    return (
        <div className="ml-4 self-center h-[18px]">
            <EmojiPicker
                popperOptions={{ placement: "top-start" }}
                pickerProps={{ theme: "light" }}
                ButtonIconComponent={EmojiIcon}
            />
        </div>
    );
};

export default EmojiPickerButton;
