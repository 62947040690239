import AppModal from "../../common/components/app-modal";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import React, {useState} from "react";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import DontHaveEnoughCoinsCard from "./ui/dont-have-enough-coins-card";
import BuyButton from "./buy-button";
import Coin from "../../core/components/ui/coin";
import useAuth from "../../auth/hooks/use-auth";
import {useWalletApi} from "../../core/hooks/use-wallet-api";
import useEffectOnce from "../../common/hooks/use-effect-once";
import {SubscriptionModalData} from "../models/interfaces/subscription-modal-data.interface";
import SelectorPeriodSubscription from "../../subscriptions/components/selector-period-subscription";
import {getFinalPriceByPeriod} from "../../subscriptions/helpers/get-final-price-by-period";
import {getLabelButtonBuySubscription} from "../../subscriptions/helpers/get-label-button-buy-subscription";
import BenefitsSubscriptionCard from "../../subscriptions/components/ui/benefits-subscription-card";
import {getPriceMonthlyByPeriod} from "../../subscriptions/helpers/get-price-monthly-by-period";
import {PeriodSubscription} from "../../core/models/enums/period-subscription.enum";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import {useStripeApi} from "../../core/hooks/use-stripe-api";

interface PurchaseSubscriptionModalProps {
    appendTo?: string | React.RefObject<HTMLElement> | null;
    onSuccessBuySubscription?: UniversalClickHandler;
}

const PurchaseSubscriptionModal = (props: PurchaseSubscriptionModalProps) => {
    const {
        onSuccessBuySubscription,
        appendTo
    } = props;
    const {isAuthenticated} = useAuth();
    const {getUserBalance} = useWalletApi();
    const [buyerBalance, setBuyerBalance] = useState<number | null>(null);
    const [periodSub, setPeriodSub] = useState<PeriodSubscription>(PeriodSubscription.MONTHLY);
    const [error, setError] = useState<string | null>(null);
    const {data, hideModal, setCloseCallback} = useModalWithData<SubscriptionModalData>(ModalIds.PURCHASE_SUBSCRIPTION);
    const subscription: SubscriptionModalData | null = data;
    const {createSubscriptionCheckoutSession} = useStripeApi();

    const handlePurchaseProduct = async () => {
        try {
            if ((buyerBalance !== null) && (buyerBalance >= subscription?.priceMonthly!)) {
                if (subscription) {
                    const priceId: string = periodSub === PeriodSubscription.MONTHLY ? subscription.stripeMonthlyPriceId : subscription.stripeYearlyPriceId;
                    await createSubscriptionCheckoutSession(priceId)
                        .then(res => {
                            window.location.href = res.checkoutUrl;
                        })
                    onSuccessBuySubscription && onSuccessBuySubscription();
                    hideModal();
                }
            } else {
                setError("NOT_ENOUGH_COINS");
            }
        } catch (err: any) {
            setError(err.response.data);
        }
    };

    const loadBuyerBalance = async () => {
        try {
            if (isAuthenticated) {
                const balance = await getUserBalance();
                setBuyerBalance(balance);
            }
        } catch (error: any) {
            setBuyerBalance(0);
        }
    };

    const selectPeriodHandle = (period: PeriodSubscription) => {
        if (error === "NOT_ENOUGH_COINS") setError(null);
        setPeriodSub(period)
    }

    useEffectOnce(() => {
        loadBuyerBalance();
        setCloseCallback(ModalIds.PURCHASE_SUBSCRIPTION, () => {
            setError("");
        })
    });

    return (
        <AppModal
            key={ModalIds.PURCHASE_SUBSCRIPTION}
            id={ModalIds.PURCHASE_SUBSCRIPTION}
            appendTo={appendTo}
            disableScroll={true}
            zIndex={40}
            classModal="md:min-w-[540px]"
        >
            <div className="flex flex-col w-full gap-2 bg-white ">
                <span className="text-[0.625rem] text-primary font-semibold uppercase tracking-wider">
                    {subscription?.sellerFullName}
                </span>
                <h2 className="text-[1.375rem] leading-[1.65rem] font-bold mb-2">Buy Subscription</h2>

                {subscription?.yearlyDiscountActive && (
                    <SelectorPeriodSubscription
                        selectedPeriod={periodSub}
                        onSelect={selectPeriodHandle}
                        yearlyDiscountPrice={subscription?.yearlyDiscountPrice!}
                        yearlyDiscountPercentage={subscription?.yearlyDiscountPercentage!}
                    />
                )}

                <div className="inline-flex gap-3 justify-between items-center mb-3">
                    <span className="text-sm leading-[1.2rem] font-medium text-special-gray">
                        Monthly price
                    </span>

                    <span
                        className="leading-[1.2rem] font-semibold text-base text-black inline-flex items-center gap-1">
                        {periodSub === PeriodSubscription.YEARLY && (
                            <div
                                className="line-through text-sm font-medium leading-[1rem] translate-y-[2px] text-special-gray">
                                {subscription?.priceMonthly} $
                            </div>
                        )}
                        {subscription && (
                            <>
                                {getPriceMonthlyByPeriod(periodSub, subscription)}
                                <Coin/>
                            </>
                        )}
                    </span>
                </div>

                <div className="space-y-3">
                    <p className="text-black text-base font-semibold leading-[1.2rem]">
                        Free messages included in subscription
                    </p>
                    <BenefitsSubscriptionCard
                        freeMessageBlock={subscription?.freeMessageBlock || 0}
                        freeVoiceCall={subscription?.freeVoiceCall || 0}
                        freeVideoCall={subscription?.freeVideoCall || 0}
                    />
                </div>

                {error === "NOT_ENOUGH_COINS" && (
                    <DontHaveEnoughCoinsCard title="You don’t have enough yCoins to buy this subscription"/>
                )}

                <div className="flex justify-center mt-4">
                    <BuyButton
                        disabled={!!error}
                        onClick={handlePurchaseProduct}
                        label={
                            <div className='flex gap-1 items-center'>
                                <span>{getLabelButtonBuySubscription(periodSub)}</span>
                                <span>{getFinalPriceByPeriod(periodSub, subscription!)}</span>
                                <Coin/>
                            </div>
                        }/>
                </div>
            </div>
        </AppModal>
    )
}

export default PurchaseSubscriptionModal
