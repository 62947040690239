import {useRef, useState} from "react";

type AudioProcessorConfig = {
    fileName?: string;
    mimeType?: "audio/mp4" | "audio/webm";
};

const defaultConfig: AudioProcessorConfig = {
    fileName: `voice-recorded`,
    mimeType: "audio/webm",
};

const useAudioProcessor = (
    onRecordingStop: (file: File) => void,
    config: AudioProcessorConfig = {}
) => {
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const audioChunksRef = useRef<Blob[]>([]);
    const conf = {...defaultConfig, ...config};

    const checkMicrophonePermission = async (): Promise<PermissionState> => {
        try {
            const result = await navigator.permissions.query({name: 'microphone' as PermissionName});
            return result.state; // 'granted' | 'denied' | 'prompt'
        } catch (error) {
            console.error("Permission check failed:", error);
            return 'denied';
        }
    };

    const requestMicrophonePermission = async (): Promise<boolean> => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({audio: true});
            stream.getTracks().forEach(track => track.stop());
            return true;
        } catch (error) {
            console.error("Microphone permission denied or error occurred:", error);
            return false;
        }
    };

    const startRecording = async () => {
        if (await checkMicrophonePermission() === "granted") {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({audio: true});
                const mediaRecorder = new MediaRecorder(stream, {mimeType: conf.mimeType});
                mediaRecorderRef.current = mediaRecorder;
                audioChunksRef.current = [];

                mediaRecorder.ondataavailable = (event) => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorder.onstop = async () => {
                    const audioBlob = new Blob(audioChunksRef.current, {type: conf.mimeType});

                    // Stop the media stream as soon as recording stops


                    const audio = new Audio();
                    audio.src = URL.createObjectURL(audioBlob);
                    mediaRecorder.stream.getTracks().forEach(track => track.stop());
                    audio.onloadedmetadata = () => {

                        if (audio.duration < 0.5) { // Set your minimum duration
                            console.log("Recording is too short, not saving.");
                            audioChunksRef.current = [];
                            setIsRecording(false);
                            return;
                        }

                        const file = new File(
                            [audioBlob],
                            `${conf.fileName}_${new Date().toISOString()}.${getFileExtension()}`,
                            {type: conf.mimeType}
                        );

                        onRecordingStop(file);
                        setIsRecording(false);
                        audioChunksRef.current = [];
                    };
                };
                mediaRecorder.start();
                setIsRecording(true);
            } catch (error) {
                console.error("Error voice recording:", error);
            }
        } else if (await checkMicrophonePermission() === "prompt") {
            await requestMicrophonePermission();
        }
    };

    const stopRecording = () => {
        mediaRecorderRef.current?.stop();
        setIsRecording(false);
    };

    const getFileExtension = (): string => {
        switch (conf.mimeType) {
            case "audio/mp4":
                return "m4a";
            case "audio/webm":
                return "webm";
            default:
                return "unknown";
        }
    };

    return {
        isRecording,
        startRecording,
        stopRecording,
        mediaRecorderRef,
    };
};

export default useAudioProcessor;
