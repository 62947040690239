import ApiService from "../../../axios/api.service";
import {CreateDigitalProductRequest} from "../models/interfaces/create-digital-product-request.interface";
import {DigitalProduct} from "../models/interfaces/digital-product.interface";
import {DigitalProductDTO} from "../models/interfaces/digital-product-dto.interface";
import {Pricing} from "../models/interfaces/pricing.interface";
import {ContactType} from "../models/enums/communications-type.enum";
import {UpdateDigitalProductRequest} from "../models/interfaces/update-digital-product-request.interface";
import {ProductFile, TempProductFile} from "../../digital-products/models/interfaces/product-file.interface";
import {DigitalProductDetails} from "../models/interfaces/digital-product-details.interface";
import {GetDigitalProductImage} from "../models/interfaces/get-product-image.interface";

class DigitalProductApiService extends ApiService {

    public async createDigitalProduct(data: CreateDigitalProductRequest, productPicture: File | null, files: ProductFile[]): Promise<DigitalProductDTO> {
        const formData = new FormData();

        formData.append("request", new Blob([JSON.stringify(data)], {type: "application/json"}));
        if (productPicture) formData.append("productPicture", productPicture, productPicture.name);
        if (files && files.length > 0) {
            files.forEach((file, index) => {
                formData.append(`files`, file.file, file.file.name);
            });
        }

        return await this.post<DigitalProductDTO>("/digital-product", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            successMessage: "Successfully added new product!",
            errorMessage: "Error creating digital product"
        }).catch((error) => {
            throw error;
        });
    }

    public async getOwnerDigitalProducts(userId: string): Promise<DigitalProduct[]> {
        return await this.get<DigitalProduct[]>(`/digital-product/owner/${userId}`, {
            skipDefault404Handling: true,
            errorMessage: "Error loading digital products",
        })
            .catch((error) => {
                throw error;
            });
    }

    public async getPublicProfileDigitalProducts(userId: string): Promise<DigitalProduct[]> {
        return await this.get<DigitalProduct[]>(`/digital-product/fan/${userId}`, {
            skipDefault404Handling: true,
            errorMessage: "Error loading digital products",
        })
            .catch((error) => {
                throw error;
            });
    }

    public async existsProduct(aliasName: string, userName: string): Promise<boolean> {
        return await this.get<DigitalProductDetails>(`/digital-product/${userName}/${aliasName}`, {
            skipDefault404Handling: true,
            skipDefaultErrorHandling: true,
        })
            .then(() => true)
            .catch(() => false);
    }

    public async getDigitalProduct(aliasName: string, userName: string): Promise<DigitalProductDetails> {
        return await this.get<DigitalProductDetails>(`/digital-product/${userName}/${aliasName}`, {
            errorMessage: "Error loading digital product details",
        })
            .catch((error) => {
                throw error;
            });
    }

    public async updateDigitalProduct(id: string, data: UpdateDigitalProductRequest, productPicture?: File | null, files?: TempProductFile[]): Promise<void> {
        const formData = new FormData();

        const mappedData: UpdateDigitalProductRequest = {...data};

        files?.forEach((el, index) => {
            formData.append("newFiles", el.file, el.file.name);

            const mapping = mappedData.fileMappings.find((mapping) => mapping.uuid === el.uuid);
            if (mapping) mapping.indexMultipart = index;
        });

        formData.append("request", new Blob([JSON.stringify(mappedData)], {type: "application/json"}));
        if (productPicture) formData.append("productPicture", productPicture, productPicture.name);

        return await this.put<void>(`/digital-product/${id}`, formData, {
            successMessage: "Product updated successfully",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            errorMessage: "Error updating digital product",
        }).catch((error) => {
            throw error;
        });
    }

    public async deleteDigitalProduct(id: string): Promise<void> {
        return await this.delete<void>(`/digital-product/${id}`, {
            successMessage: "Digital product deleted successfully",
            errorMessage: "Error deleting digital product",
        }).catch((error) => {
            throw error;
        });
    }

    public async restoreDigitalProduct(id: string): Promise<void> {
        return await this.put<void>(`/digital-product/restore/${id}`, {},{
            successMessage: "Digital product restored successfully",
            errorMessage: "Error restoring digital product",
        }).catch((error) => {
            throw error;
        });
    }

    public async buyDigitalProduct(productId: string): Promise<string> {
        return await this.post<string>(`/digital-product/buy/${productId}`, {}, {
            headers: {
                "Content-Type": "text/plain"
            },
            successMessage: "Purchase completed successfully! Your digital product is now available.",
        }).catch((error) => {
            throw error;
        });
    }

    public async getContactPrices(): Promise<Pricing> {
        return await this.get<Pricing>(`/profile/pricing`, {
            errorMessage: "Error loading pricing",
        })
            .catch((error) => {
                throw error;
            });
    }

    public async getProductImageById(productId: string): Promise<GetDigitalProductImage> {
        return await this.get<GetDigitalProductImage>(`/digital-product/image/${productId}`, {
            errorMessage: "Error loading image of digital product",
            skipDefault404Handling: true,
        })
    }

    public async downloadSingleFile(fileId: number): Promise<Blob> {
        return await this.get<any>(`/digital-product/files/${fileId}/download`, {
            errorMessage: "Error downloading of digital product file",
            responseType: "blob" as "json",
            skipDefault404Handling: true,
        })
    }

    public async getDigitalProductAliasName(productId: string): Promise<string> {
        return await this.get<string>(`/digital-product/${productId}/alias-name`, {
            responseType: "text",
            skipDefault404Handling: true,
        })
    }

    public async changeContactPrice(yCredits: number, typeContact: ContactType): Promise<void> {
        switch (typeContact) {
            case ContactType.CALL:
                return await this.put<void>(`/profile/call-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                    errorMessage: "Error updating call price",
                })
                    .catch((error) => {
                        throw error;
                    });
            case ContactType.CHAT:
                return await this.put<void>(`/profile/chat-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                    errorMessage: "Error updating chat price",
                })
                    .catch((error) => {
                        throw error;
                    });
            case ContactType.VIDEO_CALL:
                return await this.put<void>(`/profile/video-call-price/${yCredits}`, {}, {
                    skipDefault404Handling: true,
                    errorMessage: "Error updating video call price",
                })
                    .catch((error) => {
                        throw error;
                    });
            default:
                console.error("Not unknow contact type.", typeContact)
        }

    }

}

export default new DigitalProductApiService();
