import React, {createContext} from 'react';
import {ComponentType} from "../../common/models/types/component.type";
import {ProfileUserForm} from "../../core/models/interfaces/profile-form-data.interface";
import useAuth from "../../auth/hooks/use-auth";
import {useForm, UseFormReturn} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {useUserProfileApi} from "../../core/hooks/use-user-profile-api";
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";
import Base64Helper from "../../../utils/base64-helper";
import ProfileImageService from '../../core/db/services/profile-image-service';
import ProfileBackgroundImageService from '../../core/db/services/profile-background-image-service';
import {schemaProfileForm} from "../constants/schema-profile-form";

interface ProfileFormContextType {
    methods: UseFormReturn<ProfileUserForm>,
    handleUpdateProfile: () => Promise<void>,
}

export const ProfileFormContext = createContext<ProfileFormContextType | undefined>(undefined);

export const ProfileFormProvider: React.FC<{
    children: ComponentType
}> = ({children}) => {
    const {currentUser, updateAuthenticationField} = useAuth();
    const {updateProfile} = useUserProfileApi();

    const methods = useForm<ProfileUserForm>({
        defaultValues: {
            profileImage: currentUser?.profileImage + '',
            backgroundProfileImage: currentUser?.backgroundProfileImage + '',
            firstname: currentUser?.firstname || '',
            lastname: currentUser?.lastname || '',
            username: currentUser?.username || '',
            bio: currentUser?.bio || '',
            longBio: currentUser?.longBio || '',
        },
        resolver: yupResolver(schemaProfileForm),
        mode: 'onChange',
    });

    const handleUpdateProfile = async (): Promise<void> => {
        return await methods.handleSubmit(
            async (data) => {
                const sanitizedData = {
                    ...data,
                    profileImage: data.profileImage === 'null' ? null : Base64Helper.removeBase64Prefix(data.profileImage || ""),
                    backgroundProfileImage: data.backgroundProfileImage === 'null' ? null : Base64Helper.removeBase64Prefix(data.backgroundProfileImage || ""),
                };
                await updateProfile(sanitizedData as ProfileUserForm)
                    .then(async () => {
                        const updatedUserAuthentication: AuthenticatedUser = {
                            ...currentUser!,
                            ...sanitizedData!,
                        }

                        updateAuthenticationField('user', updatedUserAuthentication)
                        methods.reset(sanitizedData as ProfileUserForm)
                    })
                    .then(() => {
                        ProfileImageService.updateImage(sanitizedData.profileImage ?? '')
                        ProfileBackgroundImageService.updateImage(sanitizedData.backgroundProfileImage ?? '')
                    })
            }
        )();
    };

    return (
        <ProfileFormContext.Provider value={{methods, handleUpdateProfile}}>
            {children}
        </ProfileFormContext.Provider>
    );
};
