import WrapperCard from "../../../common/components/wrapper-card";
import {ReactComponent as WalletIcon} from "@assets/icons/wallet.svg";
import SpinnerLoader from "../../../common/components/spinner-loader";
import Coin from "../../../core/components/ui/coin";

interface YourCurrentBalanceCardProps {
    balance: number,
    isLoadingBalance: boolean,
}

const YourCurrentBalanceCard = (props: YourCurrentBalanceCardProps) => {
    const {balance, isLoadingBalance} = props;

    return (
        <WrapperCard className='bg-secondary-pastel border-0 text-special-gray flex flex-col justify-between w-full flex-wrap h-[85px]'>
            <header className='flex flex-col gap-3 leading-none'>
                <div className='flex gap-2 items-center uppercase'>
                    <WalletIcon className='h-4 w-4 min-w-4'/>
                    <span className='font-semibold text-[0.625rem] uppercase tracking-wider'>Your current balance</span>
                </div>
            </header>
            <section>
                {!isLoadingBalance ? (
                    <span className='text-black font-bold text-2xl flex items-center gap-1 leading-7'>
                        {balance}
                        <Coin className='!w-6 !h-6'/>
                    </span>
                ) : (
                    <div className='text-center'>
                        <SpinnerLoader/>
                    </div>
                )}
            </section>
        </WrapperCard>
    )
}

export default YourCurrentBalanceCard
