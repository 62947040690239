import StickyBottomActionBar from "../../common/components/sticky-bottom-action-bar";
import AppButton from "../../common/components/app-button";
import React from "react";
import {useFormPost} from "../hooks/use-form-post.hook";

interface PostFormToolsActionsProps {
    parentRef: React.RefObject<any>;
}

const PostFormToolsActions = ({parentRef}: PostFormToolsActionsProps) => {
    const {methods, handleSubmitPost} = useFormPost();
    const {formState: {disabled, isValid, isDirty}} = methods;

    return (
        <StickyBottomActionBar parentRef={parentRef}>
            <div className='@container md:@container-normal'>
                <div className='flex gap-2 flex-wrap md:flex-nowrap @[260px]:flex-nowrap'>
                    <AppButton
                        type='submit'
                        disabled={disabled || !isValid || !isDirty}
                        onClick={handleSubmitPost}
                        label="Add post"
                        className='!text-sm font-semibold !bg-primary text-white !rounded-2xl w-full hover:brightness-110 focus:brightness-110 whitespace-nowrap'
                        disabledClassName='disabled:!bg-gray-200'
                    />
                </div>
            </div>
        </StickyBottomActionBar>
    )
}

export default PostFormToolsActions
