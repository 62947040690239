import { ReactComponent as SendIcon } from "@assets/icons/send.svg";
import {MouseEvent, TouchEvent, useRef} from "react";

interface ButtonProps {
    sendMessage: (event: any) => void;
}

const CustomSendButton = ({ sendMessage }: ButtonProps) => {
    const touchHandled = useRef(false);

    const handleTouchEnd = (event: TouchEvent<HTMLButtonElement>) => {
        if (touchHandled.current) return;
        touchHandled.current = true;
        sendMessage(event);
        setTimeout(() => (touchHandled.current = false), 500);
    };

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        if (touchHandled.current) return;
        sendMessage(event);
    };

    return (
        <button onClick={handleClick} onTouchEnd={handleTouchEnd} className="">
            <div className="w-[40px] h-[40px] bg-primary text-white rounded-2xl flex justify-center">
                <SendIcon className="self-center w-[17.28px] h-[16px] text-white"/>
            </div>
        </button>
    );
};

export default CustomSendButton;
