import AppButton from "../../../common/components/app-button";

interface SubscribeButtonProps {
    onClick: () => Promise<void> | void
}

const SubscribeButton = ({onClick}: SubscribeButtonProps) => {
    const handleOpenModalSubscription = async () => await onClick()

    return (
        <AppButton
            onClick={handleOpenModalSubscription}
            label="Subscribe"
            classNameContentWrapper="!inline-flex"
            loaderColor="black"
            className="bg-secondary hover:bg-secondary-active focus:bg-secondary-active !text-black !text-sm !leading-[1.05rem] border-0 !font-semibold !py-3 max-h-[40px]"
        />
    )
}

export default SubscribeButton
