import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {NavigationCCProfile} from "../constants/navigation-c-c-profile";
import {NavigationProfileAnchors} from "../types/navigation-profile-anchors.interface";

interface ProfileCcMenuProps {
    visitorHasUnlockedSubscription: boolean;
    hasActiveSub?: boolean;
}

const ProfileCcMenu = (props: ProfileCcMenuProps) => {
    const {
        visitorHasUnlockedSubscription,
        hasActiveSub,
    } = props
    const [active, setActive] = useState<string>(NavigationCCProfile[0].href);

    const handleTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, tabHref: string) => {
        event.preventDefault();
        setActive(tabHref)
        const element = document.getElementById(tabHref);
        if (element) {
            element.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    const visibilityRules: Record<NavigationProfileAnchors, (hasActiveSub?: boolean, visitorHasUnlockedSubscription?: boolean) => boolean> = {
        [NavigationProfileAnchors.SUBSCRIPTION_USAGE]: () => visitorHasUnlockedSubscription,
        [NavigationProfileAnchors.CONTACT_PRICING]: () => true,
        [NavigationProfileAnchors.SUBSCRIPTION]: () => !!hasActiveSub && !visitorHasUnlockedSubscription,
        [NavigationProfileAnchors.DIGITAL_PRODUCTS]: () => true,
        [NavigationProfileAnchors.BIO]: () => true,
        [NavigationProfileAnchors.SOCIAL_MEDIA]: () => true,
    };

    return (
        <nav className="flex py-3 overflow-x-auto overflow-y-clip h-fit">
            {NavigationCCProfile
                .filter(item => visibilityRules[item.href]?.())
                .map((item: any) => (
                        <Link
                            key={item.name}
                            to={`${item.href}`}
                            className={
                                active === item.href
                                    ? 'border-b-2 border-black text-black text-sm font-semibold px-4 py-2 text-nowrap'
                                    : 'text-special-gray border-b border-special-gray text-sm font-semibold px-3 py-2 text-nowrap'
                            }
                            onClick={(event) => handleTabClick(event, item.href)}
                        >
                            {item.name}
                        </Link>
                    )
                )}
        </nav>
    );
};

export default ProfileCcMenu;
