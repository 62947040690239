import React, {useEffect, useRef, useState} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {classNames} from "../../../../utils/class-names";
import {areaCodes as defaultAreaCodes} from "../../constats/area-codes";
import {AreaCode} from "../../models/interfaces/area-code-interface";
import SelectFormField from "./select-form-field";

interface PhoneNumberFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    nameNumber: string;
    nameCode: string;
    label: string;
    isLoading?: boolean;
    isRequired?: boolean;
    countryCodes?: Array<AreaCode>;
    className?: string;
    forceDisabled?: boolean;
}

const PhoneNumberFormField: React.FC<PhoneNumberFormFieldProps> = (props: PhoneNumberFormFieldProps) => {
    const parentForm = useFormContext();
    const {
        nameNumber,
        nameCode,
        label,
        isLoading = false,
        isRequired = false,
        countryCodes = defaultAreaCodes,
        className = '',
        forceDisabled = false,
        placeholder,
        ...rest
    } = props;

    const selectButtonRef = useRef<HTMLButtonElement>(null);
    const [paddingLeft, setPaddingLeft] = useState<number>(45);

    const updatePadding = () => {
        if (selectButtonRef.current) {
            const selectWidth = selectButtonRef.current.getBoundingClientRect().width;
            setPaddingLeft(selectWidth - 8);
        }
    };

    const formatPhoneNumber = (value: string) => {
        const digitsOnly = value.replace(/\D/g, '');
        const limitedDigits = digitsOnly.slice(0, 9);
        return limitedDigits.replace(/(\d{3})(?=\d)/g, '$1 ');
    };

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const rawValue = e.target.value;
        const formattedValue = formatPhoneNumber(rawValue);
        e.target.value = formattedValue;
        parentForm.setValue(nameNumber, formattedValue);
        rest.onInput && rest.onInput(e);
    };

    const handleClick = () => {
        if (selectButtonRef.current && !parentForm.getValues(nameCode)) {
            selectButtonRef.current.click();
        }
    };

    const changeCodeHandler = () => {
        const phoneNumberInput = document.getElementById(nameNumber);
        updatePadding();
        phoneNumberInput?.focus();
    }

    useEffect(() => {
        updatePadding();
    }, []);

    return (
        <div className="w-full" onClick={handleClick}>
            <div className={`relative w-full h-fit`}>
                <label
                    htmlFor={nameNumber}
                    className={classNames(
                        "absolute block text-xs font-medium top-2 left-[14px]",
                        forceDisabled && "text-gray"
                    )}
                >
                    {label}
                    {isRequired && <span className="text-red-500">*</span>}
                </label>
                {isLoading ? (
                    <SkeletonFormField/>
                ) : (
                    <>
                        {!parentForm.getValues(nameCode) && (
                            <div
                                className="!p-1 !w-fit !border-0 text-[#9ca4b1] !absolute !left-0 !top-1/2 -translate-y-1/2 !px-3 !py-2 !pt-6 text-gray">
                                {placeholder}
                            </div>
                        )}
                        <div>
                            <SelectFormField
                                options={countryCodes.map((code) => ({
                                    value: code.isd,
                                    label: `+${code.isd} - ${code.name}`,
                                    selected: `+${code.isd}`,
                                }))}
                                name={nameCode}
                                label=""
                                onChange={changeCodeHandler}
                                representativeNameKey="selected"
                                placeholder=""
                                buttonRef={selectButtonRef}
                                selectFirstOption={false}
                                showArrow={false}
                                searchable={true}
                                className="!p-1 !w-fit !border-0 !absolute !left-0 !top-1/2 !px-3 !py-2 !pt-6"
                                selectedOptionClassName="!text-clip pt-[2px]"
                            />
                        </div>
                        <input
                            type="tel"
                            id={nameNumber}
                            {...parentForm.register(nameNumber)}
                            className={classNames(
                                `border-2 border-gray-light rounded-2xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary ${
                                    parentForm.formState.errors[nameNumber]
                                        ? "border border-red-400 focus:ring-red-400"
                                        : "border-gray-300 focus:ring-primary_400"

                                } ${className}`,
                                !parentForm.getValues(nameCode) && "!pl-11",
                                forceDisabled && "bg-gray-light text-gray"
                            )}
                            style={{paddingLeft: `${paddingLeft}px`}}
                            disabled={forceDisabled}
                            onInput={handleInput}
                            onClick={handleClick}
                            {...rest}
                            placeholder={!parentForm.getValues(nameCode) ? "123 123 123" : ""}
                        />
                    </>
                )}
            </div>
            {parentForm.formState.errors[nameNumber] && (
                <p className="text-red-500 text-xs">{"" + parentForm.formState.errors[nameNumber]?.message}</p>
            )}
        </div>
    );
};

export default PhoneNumberFormField;
