import React, {useContext, useRef, useState} from "react";
import ClipboardHelper from "../../../utils/clipboard-helper";
import NotificationService from "../../common/services/notification.service";
import {removeProtocol} from "../../../utils/extract-base-url";
import {PurchasesSubscriptionsContext} from "../../purchases/contexts/purchases-subscriptions-context";
import {useNavigate} from "react-router-dom";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";
import {ReactComponent as LinkIcon} from "@assets/icons/link.svg";
import {ReactComponent as EllipsisVerticalIcon} from "@assets/icons/more.svg";
import {ReactComponent as ProductsIcon} from "@assets/icons/products.svg";
import {PurchasedSubscription} from "../../core/models/interfaces/purchased-subscription.interface";
import {getSubscriptionStatus} from "../helpers/get-subscription-status";
import SubscriptionMarker from "./ui/subscription-marker";
import useClickOutside from "../../common/hooks/use-click-outside";
import useModalWithData from "../../common/hooks/use-modal-with-data";
import {RestoreSubscriptionModalData} from "../models/interfaces/restore-subscription-modal-data.interface";
import {ModalIds} from "../../common/models/enums/modal-ids.enum";
import {CancelSubscriptionModalData} from "../models/interfaces/cancel-subscription-modal-data.interface";


interface SubscriptionActionsMenuProps {
    subscription: PurchasedSubscription;
}

const PurchasedSubscriptionActions = (props: SubscriptionActionsMenuProps) => {
    const {subscription} = props;
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const menuRef = useRef<HTMLDivElement>(null);

    useClickOutside(isOpen, () => setIsOpen(false), [menuRef]);

    const {showModal} = useModalWithData<CancelSubscriptionModalData>(ModalIds.CANCEL_SUBSCRIPTION);
    const openCancelSubscriptionModalHandle = async () => showModal(subscription)

    const toggleMenu = () => setIsOpen(!isOpen);
    const handleViewProducts = async () => navigate(`/${subscription.username}/digital-products`);
    const urlPublicProfile = `${removeProtocol(window.location.origin)}/${subscription.username}`;
    const handleCopyLink = async () => {
        await ClipboardHelper.copyText(
            urlPublicProfile,
            () => {
                NotificationService.success("Link copied to clipboard!", "Success", {duration: 3000});
            },
            (error) => NotificationService.error("Failed to copy link", error, "Error", {duration: 3000})
        );
    };

    return (
        <div className="relative inline-block text-left" ref={menuRef}>
            <AppButton
                onClick={toggleMenu}
                className={classNames(
                    "flex items-center justify-center w-10 h-10 bg-white text-special-gray border border-special-gray rounded-2xl",
                    isOpen && "!border-black !text-black"
                )}
                label=''>
                <EllipsisVerticalIcon className='w-fit h-[0.875rem]'/>
            </AppButton>

            {isOpen && (
                <div
                    className="min-w-[250px] absolute right-0 z-10 w-48 mt-2 bg-white rounded-2xl shadow-notification p-3 space-y-2 !font-semibold !text-sm">
                    {(subscription.isRenewable) && (
                        <AppButton
                            onClick={openCancelSubscriptionModalHandle}
                            classNameContentWrapper="!justify-start"
                            className="block w-full !p-0 border-0 hover:text-primary "
                            label=''>
                            <div className='flex gap-2 items-center'>
                                <div className="w-8 h-8 flex items-center justify-center">
                                    <SubscriptionMarker size="medium"/>
                                </div>
                                <span className='whitespace-nowrap'>Cancel subscription</span>
                            </div>
                        </AppButton>
                    )}
                    <AppButton
                        onClick={handleViewProducts}
                        classNameContentWrapper="!justify-start"
                        className="block w-full !p-0 border-0 hover:text-primary "
                        label=''>
                        <div className='flex gap-2 items-center'>
                            <div className="w-8 h-8 flex items-center justify-center">
                                <ProductsIcon className='w-[1.125rem] h-[1.125rem]'/>
                            </div>
                            <span className='whitespace-nowrap'>View products</span>
                        </div>
                    </AppButton>
                    <AppButton
                        onClick={handleCopyLink}
                        classNameContentWrapper="!justify-start"
                        className="block w-full !p-0 border-0 hover:text-primary "
                        label=''>
                        <div className='flex gap-2 items-center'>
                            <div className="w-8 h-8 flex items-center justify-center">
                                <LinkIcon className='w-[1.125rem] h-[1.125rem]'/>
                            </div>
                            <span className='whitespace-nowrap'>Copy link to profile</span>
                        </div>
                    </AppButton>
                </div>
            )}
        </div>
    );
};

export default PurchasedSubscriptionActions;
