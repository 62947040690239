import React, {useContext} from "react";
import {SubscriptionFilterContext} from "../contexts/subscription-filter-context";
import PurchasedSubscriptionCardUi from "./ui/purchased-subscription-card-ui";
import PurchasedSubscriptionActions from "./purchased-subscription-actions";
import RestorePurchasedSubscriptionButton from "./restore-purchased-subscription-button";
import {SubscriptionStatus} from "../models/enums/subscription-status.enum";
import {Case, Switch} from "../../common/components/switch-case";
import ReactivateCanceledSubscription from "./reactivate-canceled-subscription";

const PurchasedSubscriptionList = () => {
    const context = useContext(SubscriptionFilterContext);
    if (!context) return null;
    const {filteredSubscriptions} = context;

    return (
        <div className="flex flex-col gap-4 mt-4 sm:mt-0">
            {filteredSubscriptions.length > 0 ? (
                filteredSubscriptions.map((sub) => (
                    <div key={sub.id} className="flex flex-col gap-3">
                        <div className="flex gap-3 justify-between items-center">
                            <PurchasedSubscriptionCardUi subscription={sub}/>
                            <Switch value={context.selectedStatus}>
                                <Case case={SubscriptionStatus.ACTIVE}><PurchasedSubscriptionActions subscription={sub}/></Case>
                                <Case case={SubscriptionStatus.CANCELED}><ReactivateCanceledSubscription subscription={sub}/></Case>
                            </Switch>
                        </div>
                        <Switch value={context.selectedStatus}>
                            <Case case={SubscriptionStatus.EXPIRES}><RestorePurchasedSubscriptionButton subscription={sub}/></Case>
                        </Switch>
                    </div>
                ))
            ) : (
                <p className="text-black font-semibold text-sm text-center">No subscriptions found.</p>
            )}
        </div>
    );
};

export default PurchasedSubscriptionList;
