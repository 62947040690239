import React, {MouseEvent, ReactNode, TouchEvent, useRef} from "react";
import {useMessageContext} from "stream-chat-react";
import {classNames} from "../../../../utils/class-names";
import AdditionalInfoUpperMessage from "../ui/additional-info-upper-message";
import WrapperCard from "../../../common/components/wrapper-card";
import {useMessageOptions} from "../../hooks/use-message-options";
import {usePressAction} from "../../../common/hooks/use-press-action";
import MessageOptions from "./message-options";
import useClickOutside from "../../../common/hooks/use-click-outside";
import {animated, useTransition} from "@react-spring/web";

interface CustomMessageWrapperProps {
    // children as content of message
    children: ReactNode,
    additionalUpperInfo?: string;
}

const MessageWrapper = (props: CustomMessageWrapperProps) => {
    const {isMyMessage, message} = useMessageContext();
    const {setActiveMessageId, activeMessageId} = useMessageOptions();
    const messageOptionsRef = useRef<HTMLDivElement | null>(null);

    const messageId = message.id
    const isActive = activeMessageId === messageId;
    const allowRetry = message.status === 'failed' && message.errorStatusCode !== 403;

    const transitionsBG = useTransition(isActive, {
        from: {opacity: 0},
        enter: {opacity: 0.75},
        leave: {opacity: 0},
        config: {duration: 300},
    });

    const transitionsMO = useTransition(isActive, {
        from: {opacity: 0},
        enter: {opacity: 1},
        leave: {opacity: 0},
        config: {duration: 200},
        delay: 50,
    });

    const showOptionsHandler = (event: TouchEvent<HTMLElement> | MouseEvent<HTMLElement>) => {
        const excludeList = ['.quoted-message', '.str-chat__message-attachment-audio-widget--play-button'];
        const shouldExclude = excludeList.some(selector => (event.target as HTMLElement).closest(selector));

        if (shouldExclude) return

        const lastHtmlElementMessage = document.querySelector(".selected-message");
        lastHtmlElementMessage && lastHtmlElementMessage.classList.remove("selected-message");

        const htmlElementMessage = document.getElementById(messageId);
        htmlElementMessage && htmlElementMessage.classList.add("selected-message");

        if (!isActive) {
            event.stopPropagation();
            setActiveMessageId(messageId);
        }
    }

    const {ref, ...pressHandlers} = usePressAction({
        delay: 400,
        onPress: (event: TouchEvent<HTMLElement>) => showOptionsHandler(event),
        onClick: (event: MouseEvent<HTMLElement>) => showOptionsHandler(event),
    });

    useClickOutside(
        isActive,
        () => setActiveMessageId(null),
        [messageOptionsRef],
        ["data-getstream-outside-message-options"],
    );

    return (
        <div
            key={message.id}
            className={classNames(
                "custom-message-wrapper select-none",
                isMyMessage() ? 'owner' : 'opponent'
            )}
        >
            {props.additionalUpperInfo && <AdditionalInfoUpperMessage info={props.additionalUpperInfo}/>}
            <div className={classNames(
                "flex gap-2 relative w-full",
                isMyMessage() ? "flex-row-reverse" : "flex-row"
            )}>
                <WrapperCard
                    id={messageId}
                    className={classNames(
                        "card relative max-w-[90%]",
                        isMyMessage() ? "bg-white" : "bg-[#5E17EB12]"
                    )}>
                    <div
                        ref={ref}
                        {...pressHandlers}
                        className="max-w-[700px] p-3">
                        {props.children}
                    </div>
                </WrapperCard>

                {transitionsMO((styles, item) =>
                        item && (
                            <animated.div style={styles}>
                                <MessageOptions
                                    ref={messageOptionsRef}
                                    triggerRef={ref}
                                    message={isActive ? message : null}
                                    onClose={() => setActiveMessageId(null)}/>
                            </animated.div>
                        )
                )}
            </div>

            {transitionsBG((styles, item) =>
                    item && (
                        <animated.div
                            className='z-[1] bg-gray-light opacity-75 absolute left-0 top-0 flex items-center
                            justify-center w-full h-full '
                            style={styles}
                        >
                        </animated.div>
                    )
            )}
        </div>
    )
}

export default MessageWrapper
