import React, {useEffect, useState} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import {classNames} from "../../../../utils/class-names";
import Coin from "../../../core/components/ui/coin";

interface NumberFormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label: string;
    min?: number;
    max?: number;
    step?: number;
    isLoading?: boolean;
    isRequired?: boolean;
    showArrows?: boolean;
    formatValue?: (value: number) => string;
    className?: string;
    forceDisabled?: boolean;
    suffix?: string | React.ReactNode;
    showCoin?: boolean;
    maxFractionDigits?: number;
    minFractionDigits?: number;
}

const NumberFormField: React.FC<NumberFormFieldProps> = (props: NumberFormFieldProps) => {
    const {
        register,
        setValue,
        watch,
        formState: {errors, isSubmitting, disabled}
    } = useFormContext();
    const {
        name,
        label,
        min,
        max = 999999999999,
        step = 1,
        isLoading = false,
        isRequired = false,
        showArrows = false,
        formatValue,
        className = '',
        maxLength,
        forceDisabled = false,
        suffix,
        showCoin,
        maxFractionDigits = 0,
        minFractionDigits = 0,
        onChange,
        ...rest
    } = props;

    const value = watch(name) || "0"; // Watching the raw value stored in the form
    const [displayValue, setDisplayValue] = useState<string>("0");
    const [isInitial, setIsInitial] = useState(true);

    const formatNumber = (value: number): string => {
        return value.toLocaleString('en-US', {
            minimumFractionDigits: minFractionDigits,
            maximumFractionDigits: maxFractionDigits
        }).replace(/,/g, '');
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (["Backspace", "Delete", "Tab", "ArrowLeft", "ArrowRight"].includes(e.key)) {
            return;
        }

        if (!/[0-9.]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const handleDisplayInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        let cursorPosition = e.target.selectionStart ?? 0;
        let rawInput = e.target.value.replace(/[^0-9.]/g, '');

        if (!rawInput) {
            setDisplayValue("0");
            setValue(name, 0, {shouldDirty: true, shouldValidate: true});
            setIsInitial(true);
            return;
        }

        if (isInitial && rawInput.length === 1) {
            setIsInitial(false);
        }

        if (displayValue === "0.00" && cursorPosition === 1) {
            rawInput = rawInput.slice(0, 1) + ".00";
            cursorPosition = 1;
        } else if (displayValue === "0.00") {
            rawInput = rawInput + ".00";
            cursorPosition = 1;
        }

        if (maxFractionDigits && rawInput.indexOf('.') === -1) {
            rawInput = rawInput.slice(0, (-maxFractionDigits - 1)) + '.' + rawInput.slice(-maxFractionDigits);
            --cursorPosition;
        }

        if (rawInput.includes(".")) {
            const [integerPart, decimalPart] = rawInput.split(".");
            if (decimalPart.length > maxFractionDigits) {
                rawInput = `${integerPart}.${decimalPart.substring(0, maxFractionDigits)}`;
            }
        }

        let numberValue = parseFloat(rawInput);
        if (isNaN(numberValue)) return;

        if (min !== undefined && numberValue < min) numberValue = min;
        if (max !== undefined && numberValue > max) numberValue = max;
        if (maxLength && numberValue.toString().length > maxLength) {
            numberValue = parseFloat(numberValue.toString().slice(0, maxLength));
        }

        const formattedValue: string = formatNumber(numberValue);
        setDisplayValue(formattedValue);

        requestAnimationFrame(() => {
            const inputElement = document.getElementById(`${name}_display`) as HTMLInputElement | null;
            if (inputElement) {
                if (rawInput === "0.00" && rawInput.length === 1) {
                    cursorPosition = formattedValue.indexOf("."); // Move cursor before `.`
                }
                inputElement.setSelectionRange(cursorPosition, cursorPosition);
            }
        });

        //TODO refactor this bullshit, will be only one trigger to update form element, because onChange can use setValue too.
        onChange && onChange(e)
        setValue(name, numberValue, {shouldDirty: true, shouldValidate: true});
        rest.onInput && rest.onInput(e);
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        requestAnimationFrame(() => {
            const inputElement = document.getElementById(`${name}_display`) as HTMLInputElement | null;
            if (inputElement) {
                const index = displayValue.indexOf(".");
                inputElement.setSelectionRange(index, index);
            }
        });
    }

    useEffect(() => {
        if (value !== undefined && value !== null && value !== "") {
            const formattedValue: string = formatNumber(parseFloat(value));
            setDisplayValue(formattedValue);
        }
    }, [value]);

    return (
        <div className='w-full'>
            <div className={`relative w-full h-fit`}>
                <label htmlFor={`${name}_display`} className={classNames(
                    "absolute block text-xs font-medium top-2 left-[14px]",
                    (forceDisabled || disabled) && "text-gray"
                )}>
                    {label}
                    {isRequired && <span className='text-red-500'>*</span>}
                </label>
                {isLoading
                    ? <SkeletonFormField/>
                    : (
                        <>
                            {/* Formatted Display Input */}
                            <input
                                type="text"
                                inputMode="decimal"
                                pattern="[0-9]"
                                id={`${name}_display`}
                                value={displayValue}
                                className={classNames(
                                    `border-2 border-gray-light rounded-2xl px-3 py-2 pt-6 appearance-none w-full focus:outline-none focus:ring-2 focus:ring-primary ${errors[name] ? 'border border-red-400 focus:ring-red-400' : 'border-gray-300 focus:ring-primary_400'} ${className}`,
                                    !showArrows && 'disable-arrows-input-number',
                                    (forceDisabled || disabled) && "bg-gray-light text-gray"
                                )}
                                disabled={isSubmitting || forceDisabled || disabled}
                                onInput={handleDisplayInput}
                                onFocus={handleFocus}
                                onKeyDown={handleKeyDown}
                                {...rest}
                            />

                            {/* Hidden Raw Number Input */}
                            <input
                                type="number"
                                id={name}
                                {...register(name)}
                                value={value}
                                className="hidden fixed left-0 top-0 w-0 h-0"
                                readOnly
                            />

                            {showCoin && (
                                <span className="absolute right-3 top-1/2 -translate-y-1/2 transform text-gray-500">
                                    <Coin/>
                                </span>
                            )}
                            {suffix && (
                                <span
                                    className="absolute right-3 top-1/2 -translate-y-1/2 transform text-gray-500 flex items-center">
                                    {typeof suffix === "string" ? suffix : suffix}
                                </span>
                            )}

                            {showArrows && (
                                <div className="absolute right-2 top-2 flex flex-col">
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const currentValue = parseFloat(rest.value as string) || 0;
                                            const newValue = formatValue ? formatValue(currentValue + step) : currentValue + step;
                                            // @ts-ignore
                                            rest.onChange && rest.onChange(newValue);
                                        }}
                                        className="text-gray-500 hover:text-black">
                                        ↑
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            const currentValue = parseFloat(rest.value as string) || 0;
                                            const newValue = formatValue ? formatValue(currentValue - step) : currentValue - step;
                                            // @ts-ignore
                                            rest.onChange && rest.onChange(newValue);
                                        }}
                                        className="text-gray-500 hover:text-black">
                                        ↓
                                    </button>
                                </div>
                            )}
                        </>
                    )}
            </div>
            {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
        </div>
    );
};

export default NumberFormField;
