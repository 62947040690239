import React from "react";
import {classNames} from "../../../utils/class-names";
import AppButton from "../../common/components/app-button";

interface SelectorOptionsProps<T> {
    options: { label: string; value: T }[];
    selectedValue: T;
    onSelect: (value: T) => void;
    classNameOption?: string;
}

const SelectorOptions = <T, >({options, selectedValue, onSelect,classNameOption}: SelectorOptionsProps<T>) => {
    return (
        <div className="flex gap-1 bg-gray-100 rounded-full p-1 w-full min-h-[40px] h-[40px]">
            {options.map((option) => (
                <AppButton
                    key={String(option.value)}
                    type='button'
                    onClick={() => onSelect(option.value)}
                    className={classNames(
                        'w-full border-none h-full !p-0 text-special-gray text-[0.8135rem] focus:outline-none transition',
                        (selectedValue === option.value) && "bg-white font-semibold !text-black drop-shadow-xl",
                        classNameOption
                    )}
                    label={option.label}/>
            ))}
        </div>
    );
};

export default SelectorOptions;
