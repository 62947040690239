import {useState} from 'react';
import StatisticsApiService from "../services/statistics-api.service";
import {ShortStatisticsSubscription} from "../models/interfaces/short-statistics-subscription.interface";

export const useStatisticsApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const getShortStatsSubscription = async (): Promise<ShortStatisticsSubscription> => {
        setLoading(true);
        setError(null);
        try {
            return await StatisticsApiService.getShortStatisticsOwnSubscription();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        getShortStatsSubscription,
    };
};
