import useAuth from "../../auth/hooks/use-auth";
import React, {useContext, useRef} from "react";
import {UserProfileContext, UserProfileContextProps} from "../contexts/user-profile-context";
import {PublicUserProfile} from "../../core/models/interfaces/public-user-profile.interface";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import ProfileBackground from "../components/profile-background";
import {classNames} from "../../../utils/class-names";
import ProfileImage from "../components/profile-image";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import ShortUserData from "../components/short-user-data";
import PublicProfileSettingsButton from "../components/public-profile-settings-button";
import {NavigationProfileAnchors} from "../types/navigation-profile-anchors.interface";
import PurchaseProductModal from "../../purchases/components/purchase-product-modal";
import SendMessageToFanButton from "../components/send-message-to-fan-button";

interface PublicFanProfileProps {
    isPreviewMode?: boolean;
}

const PublicFanProfile = ({isPreviewMode}: PublicFanProfileProps) => {
    const {currentUser, isAuthenticated} = useAuth();
    const refParentStickyActionBar = useRef<HTMLDivElement>(null);
    const {userProfile} = useContext(UserProfileContext) as UserProfileContextProps<PublicUserProfile>;

    return (
        <AppContentWrapper className='bg-white rounded-t-[1.5rem] md:p-3 md:max-w-[1400px] !p-0'>
            <div className='relative inset-0 h-[260px]'>
                <ProfileBackground
                    canShowMenuHamburger={isPreviewMode}
                    backgroundImage={isAuthenticated ? currentUser?.backgroundProfileImage! : userProfile?.backgroundProfile!}/>
            </div>
            <div className='relative' ref={refParentStickyActionBar}>
                <div className={classNames(
                    'p-4 w-full h-full md:max-w-[832px] bg-white rounded-t-[2rem] relative top-[-30px] left-0 md:flex gap-4 md:mx-auto md:top-0',
                    isPreviewMode ? "pb-28 sm:pb-20 md:pb-28" : ""
                )}>
                    <div className='static md:sticky top-[80px] left-0 h-fit min-w-[250px] md:max-w-[250px]'>
                        <div
                            className='absolute top-[-60px] md:top-[-80px] left-1/2 md:left-0 translate-x-[-50%] md:translate-x-0 w-fit'>
                            <ProfileImage avatar={isAuthenticated ? currentUser?.profileImage! : userProfile?.avatar!}
                                          role={isAuthenticated ? currentUser?.authority! : UserAuthority.CONTENT_CREATOR}/>
                        </div>
                        <div className='mt-[80px] md:mt-[65px]'></div>
                        <ShortUserData
                            fullName={userProfile?.username}
                            shortDescription={''}
                            username={userProfile?.username + ''}
                        />
                        <div className="w-[30px] border border-gray-400 my-4"></div>
                        <div className="font-medium text-sm text-gray mb-2">
                            {userProfile?.bio}
                        </div>
                        <div className='flex gap-3 h-fit mt-4 items-center'>
                            <div className='flex-grow h-fit'>
                                <SendMessageToFanButton disabled={true}/>
                            </div>
                            {/*<PublicProfileSettingsButton creatorUserName={isAuthenticated ? currentUser?.username! : userProfile?.username!}/>*/}
                        </div>
                    </div>
                    <div className='w-full flex flex-col gap-5 overflow-hidden pt-9'>
                        <section id={NavigationProfileAnchors.BIO}>
                            <header className='font-bold text-lg'><h3>Bio</h3></header>
                            <p className='font-medium text-base text-gray text-wrap break-all'>
                                {userProfile?.longBio}
                            </p>
                        </section>
                    </div>
                </div>
            </div>
            {/*<PurchaseProductModal modalPlace="publicProfile" onSuccessBuyProduct={updateProduct}/>*/}
        </AppContentWrapper>
    )
}

export default PublicFanProfile
