import ApiService from "../../../axios/api.service";
import {CreateWithdrawRequestBody} from "../models/interfaces/create-withdraw-request-body.interface";
import {Transaction} from "../models/interfaces/transaction.interface";
import {PaymentMethodType} from "../models/enums/payment-method-type.enum";

class WalletApiService extends ApiService {

    public async getBalance(): Promise<number> {
        return await this.get<number>(`/wallet/balance`, {})
            .catch((error) => {
                throw error;
            });
    }

    public async createWithdrawRequest(body: CreateWithdrawRequestBody): Promise<void> {
        return await this.post<void>(`/wallet/withdraw-request`, body)
            .catch((error) => {
                throw error;
            });
    }

    public async getUserTransactions(page: number): Promise<Transaction[]> {
        return await this.get<Transaction[]>(`/wallet/transactions`, {
            params: {page}
        }).catch((error) => {
            throw error;
        });
    }
}

export default new WalletApiService();
