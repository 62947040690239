export class NumberFormattingService {
    /**
     * Formatowanie liczby z użyciem separatorów tysiąca w postaci spacji.
     * @param value - liczba do sformatowania
     * @param options - dodatkowe opcje formatowania (minimum i maksimum miejsc dziesiętnych)
     * @returns {string} - sformatowana liczba z separatorem spacji
     */
    formatNumberWithSpaceSeparator(
        value: number,
        options: { minimumFractionDigits?: number; maximumFractionDigits?: number , roundingMode?: 'UP' | 'DOWN' | 'NEAREST' | 'NONE'} = {}
    ): string {
        const { minimumFractionDigits = 0, maximumFractionDigits = 2 , roundingMode = "NONE"} = options;

        let roundedValue: number;
        const factor = Math.pow(10, maximumFractionDigits);

        switch (roundingMode) {
            case 'UP':
                roundedValue = Math.ceil(value * factor) / factor;
                break;
            case 'DOWN':
                roundedValue = Math.floor(value * factor) / factor;
                break;
            case 'NEAREST':
            default:
                roundedValue = Math.round(value * factor) / factor;
                break;
        }

        return roundedValue
            .toLocaleString('en-US', {
                minimumFractionDigits,
                maximumFractionDigits,
            })
            .replace(/,/g, ' ');
    }
}

export default new NumberFormattingService();
