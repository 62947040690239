import AppButton from "../../common/components/app-button";
import {PurchasedSubscription} from "../../core/models/interfaces/purchased-subscription.interface";
import {useContext} from "react";
import {PurchasesSubscriptionsContext} from "../../purchases/contexts/purchases-subscriptions-context";
import {useNavigate} from "react-router-dom";

const ReactivateCanceledSubscription = ({subscription}: { subscription: PurchasedSubscription }) => {
    const navigate = useNavigate();
    const goToProfile = async () => navigate(`/${subscription.username}`);
    const context = useContext(PurchasesSubscriptionsContext);
    if (!context) return null;

    return (
        <div className="flex justify-between gap-3 items-center">
            <AppButton
                onClick={goToProfile}
                label="Subscrbie again"
                classNameContentWrapper=""
                className="bg-primary focus:bg-primary-pastel hover:bg-primary-pastel hover:text-primary focus:text-primary transition text-white font-semibold text-sm min-h-[40px]"
            />
        </div>
    );
};

export default ReactivateCanceledSubscription;
