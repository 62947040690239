import React, {FormEvent, useEffect} from "react";
import {FormProvider} from "react-hook-form";
import {classNames} from "../../../utils/class-names";
import DescriptionFormField from "../../common/components/forms/description-form-field";
import NumberFormField from "../../common/components/forms/number-form-field";
import {useFormPost} from "../hooks/use-form-post.hook";
import CheckboxFormField from "../../common/components/forms/checkbox-form-field";
import SelectorOptions from "../../core/components/selector-options";
import {PostType} from "../../core/models/enums/post-type.enum";
import {postTypeOptions} from "../constants/post-type-options";
import Tooltip from "../../common/components/tooltip";
import PostMediaUploader from "./post-image-uploader";
import {PostMediaObject} from "../models/interfaces/post-media.interface";

const PostForm = () => {
    const {methods, loading, isSubmittingSuccessful} = useFormPost();
    const {getValues, setValue} = methods;

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => e.preventDefault();
    const handlePostMediaChange = async (file: PostMediaObject | null) => {
        const source = file?.source;
        const type = file?.type;
        if (source) {
            if (type === "image") {
                methods.setValue('video', null);
                methods.setValue('image', file?.source, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
                await methods.trigger('image');
            } else if (type === "video") {
                methods.setValue('image', null);
                methods.setValue('video', file?.source, {shouldDirty: true, shouldTouch: true, shouldValidate: true});
                await methods.trigger('video');
            }
            methods.clearErrors("description");
        }

        if (type === "empty") {
            methods.setValue('video', null);
            methods.setValue('image', null);
            await methods.trigger('image');
            await methods.trigger('video');
        }
    };

    return (
        <FormProvider {...methods}>
            <form
                onSubmit={handleSubmit}
                className={classNames("flex flex-col gap-3")}
            >
                <PostMediaUploader
                    label="Cover"
                    forceDisabled={loading}
                    maxFileSizeInMb={200}
                    isSubmittingSuccessful={isSubmittingSuccessful}
                    isSubmitting={methods.formState.isSubmitting}
                    onMediaChange={handlePostMediaChange}
                />

                <DescriptionFormField
                    shouldValidate={true}
                    forceDisabled={loading}
                    name="description"
                    maxLength={1000}
                    label="Description"
                    placeholder="Enter description"
                />


                <div className="space-y-6">
                    <header className="text-lg font-semibold">Settings</header>

                    <div className="space-y-2">
                        <span className="flex items-center gap-1 text-black font-semibold text-base leading-[100%]">
                            Post type
                            <Tooltip/>
                        </span>
                        <SelectorOptions
                            classNameOption="!px-3"
                            options={postTypeOptions}
                            selectedValue={getValues("postType")}
                            onSelect={(value: PostType) => setValue("postType", value,{shouldValidate: true})}
                        />
                    </div>

                    {methods.watch("postType") === "paid" && (
                        <div className="space-y-2">
                            <div className="space-y-2">
                                <span
                                    className="flex items-center gap-1 text-black font-semibold text-base leading-[100%]">
                                    Price
                                    <Tooltip/>
                                </span>
                                <NumberFormField
                                    forceDisabled={loading}
                                    name="price"
                                    label="Price for all fans"
                                    showCoin={true}
                                    min={0.01}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                />
                            </div>

                            <CheckboxFormField
                                name="otherPriceForSubscribers"
                                label="Other price for subscribers"
                            />

                            {methods.watch("otherPriceForSubscribers") && (
                                <NumberFormField
                                    forceDisabled={loading}
                                    name="priceForSubscribers"
                                    label="Price for subscribers"
                                    showCoin={true}
                                    min={0.01}
                                    minFractionDigits={2}
                                    maxFractionDigits={2}
                                />
                            )}
                        </div>
                    )}
                </div>
            </form>
        </FormProvider>
    );
};

export default PostForm;
