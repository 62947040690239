import WrapperCard from "../../common/components/wrapper-card";
import {classNames} from "../../../utils/class-names";
import {renderIconBaseOffer} from "../utils/render-icon-base-offer";
import {ContactType} from "../../core/models/enums/communications-type.enum";
import {useMemo, useRef} from "react";

interface CardConsumedBaseOfferProps {
    title: string,
    description: string,
    max: number,
    used: number,
    type: ContactType
}

const CardConsumedBaseOffer = (props: CardConsumedBaseOfferProps) => {
    const {
        max,
        used,
        type,
        description,
        title,
    } = props
    const contentRef = useRef(null);
    const isAllUsed = useMemo(() => used === max, [used, max])

    return (
        <WrapperCard className={classNames(
            'w-full !h-full !min-h-full !p-3 relative',
            isAllUsed ? 'bg-white !text-special-gray' : 'bg-gray-light border-0 text-black'
        )}>
            {isAllUsed && (
                <div className="absolute inset-0 pointer-events-none mix-blend-screen bg-gray-light opacity-30 rounded-3xl"></div>
            )}
            <div ref={contentRef} className='flex flex-col gap-3 h-full'>
                <div className='flex gap-2 items-center font-semibold text-[0.625rem] uppercase'>
                    {renderIconBaseOffer(type)}
                    {title}
                </div>
                <div className='flex flex-col'>
                        <span className='text-[1.4rem] flex items-center gap-2 font-bold'>
                            {used} / {max}
                        </span>
                    <div className='text-xs items-center gap-1 text-gray'>
                        <span>{description}</span>
                    </div>
                </div>
            </div>
        </WrapperCard>
    )
}

export default CardConsumedBaseOffer
