import AuthService from "../../features/auth/services/auth.service";
import useEffectOnce from "../../features/common/hooks/use-effect-once";
import useAuth from "../../features/auth/hooks/use-auth";
import RequestedNotificationTokenService from "../requested-notification-token.service";

export const useInitNotificationToken = () => {
    const {currentUser} = useAuth();
    const userId = currentUser?.id;

    const getRequestAndSendNotificationToken = async () => {
        if (!userId) return;

        try {
            await AuthService.getRequestAndSendNotificationToken();
            RequestedNotificationTokenService.setNotificationRequested(userId);
        } catch (error) {
            console.error("Failed to send notification token:", error);
        }
    };

    useEffectOnce(() => {
        const canRequest: boolean = !!userId && !RequestedNotificationTokenService.getNotificationRequested(userId)
        canRequest && getRequestAndSendNotificationToken();
    });
};
