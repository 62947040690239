import {useState} from "react";
import FeedApiService from "../services/feed-api.service";
import {CreatePostRequest} from "../models/interfaces/create-post-request.inerface";

export const useFeedApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const createPost = async (body: CreatePostRequest): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await FeedApiService.createPost(body);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const deletePost = async (postId: string): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await FeedApiService.deletePost(postId);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    return {
        loading,
        error,
        createPost,
        deletePost,
    };
}
