import React, {useContext} from "react";
import AppButton from "../../common/components/app-button";
import DescriptionFormField from "../../common/components/forms/description-form-field";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {CategoryProductFormBody} from "../models/interfaces/category-product-form-body.interface";
import {schemaAddCategoryProduct} from "../constants/schema-add-category-product";
import {ReactComponent as LinkIcon} from "@assets/icons/link.svg";
import {removeProtocol} from "../../../utils/extract-base-url";
import useAuth from "../../auth/hooks/use-auth";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import sanitizeUrlString from "../../../utils/sanitize-url-string";
import {useSidebar} from "../../../layout/hooks/use-sidebar";

const AddNewCategoryForm = () => {
    const methods = useForm<CategoryProductFormBody>({
        defaultValues: {
            categoryName: "",
        },
        resolver: yupResolver(schemaAddCategoryProduct),
        mode: 'onSubmit',
    });
    const {currentUser} = useAuth();
    const {createCategory} = useContext(SessionCreatorToolsContext)!;
    const urlToCategory = `${removeProtocol(window.location.origin)}/${currentUser?.username}/${sanitizeUrlString(methods.getValues('categoryName'))}`
    const {closeSidebar} = useSidebar();

    const handleCreateNewCategory = async (): Promise<void> => {
        await createCategory(methods.getValues("categoryName"));
        closeSidebar();
    }

    return (
        <div className="p-6 pt-10 h-full max-h-full flex flex-col">
            <header className='font-bold leading-[1.65rem] text-[1.375rem]'>Add category</header>

            <FormProvider {...methods}>
                <div className="flex flex-col justify-between h-full mt-6">
                    <div>
                        <DescriptionFormField name="categoryName" label="Name" maxLength={35}
                                              placeholder="Category name"/>
                        <span className='flex gap-1 text-special-gray mt-2'>
                            <LinkIcon className='w-3 min-w-3 h-3'/>
                            <span className='truncate flex-shrink leading-[0.9rem] text-xs font-medium'>
                                 {urlToCategory}
                            </span>
                        </span>
                    </div>
                    <AppButton
                        type="button"
                        disabled={methods.formState.disabled || !methods.formState.isDirty}
                        onClick={handleCreateNewCategory}
                        label="+ Add category"
                        className="max-h-[40px] bg-primary !rounded-2xl border-0 text-white py-[10px] w-full"
                    />
                </div>
            </FormProvider>
        </div>
    )
}

export default AddNewCategoryForm
