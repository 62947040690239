import {getDataLayerSnippet} from "./snippets/get-data-layer.snippet";
import {getIframeSnippet} from "./snippets/get-iframe.snippet";
import {getGTMScript} from "./snippets/get-gtm-script.snippet";
import {SetupGTM} from "./models/interfaces/setup-gtm.interface";
import {ConfigGTM} from "./models/interfaces/config-gtm.interface";
import { AnalyticsEventType } from "../core/models/enums/event-type-enum";

class GoogleTagManager {
    private config: ConfigGTM;

    constructor(params: ConfigGTM) {
        this.config = params;
    }

    /**
     * Creates a script element to load the DataLayer.
     * @returns {HTMLElement} - The <script> element with the DataLayer code.
     */
    private getDataLayerScript(): HTMLElement {
        const dataLayerScript = document.createElement('script')
        if (this.config.nonce) {
            dataLayerScript.setAttribute('nonce', this.config.nonce)
        }
        dataLayerScript.innerHTML = getDataLayerSnippet(this.config.dataLayer,)
        return dataLayerScript
    }

    /**
     * Creates a noscript element containing an iframe for GTM.
     * @returns {HTMLElement} - The <noscript> element with the GTM iframe.
     */
    private getIframeNoScript(): HTMLElement {
        const noScript = document.createElement('noscript')
        noScript.innerHTML = getIframeSnippet(
            this.config.id,
            this.config.environment,
            this.config.customDomain,
            this.config.dataCmpVendor,
            this.config.dataCmpPurpose,
        )
        return noScript
    }

    /**
     * Creates a script element to load GTM.
     * @returns {HTMLElement} - The <script> element with the GTM loading code.
     */
    private getGTMScript(): HTMLElement {
        const script = document.createElement('script')
        if (this.config.nonce) {
            script.setAttribute('nonce', this.config.nonce)
        }
        script.innerHTML = getGTMScript(
            this.config.id,
            this.config.environment,
            this.config.customDomain,
            this.config.customScriptName,
            this.config.dataCmpVendor,
            this.config.dataCmpPurpose,
        )
        return script
    }

    /**
     * Sets up the GTM on the page by providing functions for generating scripts and noscript elements.
     * @returns {SetupGTM} - An object containing functions to generate the GTM DataLayer script, GTM script, and noscript elements.
     */
    public setupGTM(): SetupGTM {
        return {
            getDataLayerScript: this.getDataLayerScript.bind(this),
            getIframeNoScript: this.getIframeNoScript.bind(this),
            getGTMScript: this.getGTMScript.bind(this),
        }
    }

    /**
     * Initializes the GTM by adding the appropriate script and noscript elements to the page.
     */
    public initGTM(): void {
        if (!this.config.id) {
            console.error("GTM initialization failed: Missing 'id' in configuration.");
            return;
        }

        const gtm = this.setupGTM()

        const dataLayerScript = gtm.getDataLayerScript()
        const script = gtm.getGTMScript()
        const noScript = gtm.getIframeNoScript()

        document.head.insertBefore(dataLayerScript, document.head.childNodes[0])
        document.head.insertBefore(script, document.head.childNodes[1])
        document.body.insertBefore(noScript, document.body.childNodes[0])
    }

    /**
     * Sends analytics data to the Google Tag Manager (GTM) dataLayer.
     *
     * This method pushes an event with associated data to the GTM `dataLayer`, allowing it to be used for analytics tracking.
     * If the `dataLayer` is not initialized, it will create an empty array to ensure data can be pushed.
     *
     * @param {AnalyticsEventType} eventName - The name of the event to be sent (e.g., 'register', 'view_item').
     * @param {object} data - The payload data to be sent with the event. The structure depends on the event type.
     * @returns {void} - Pushes the provided event and data to the GTM `dataLayer`.
     */
    public static sendToGTM(eventName: AnalyticsEventType | null, data: any): void {
        if (!window.dataLayer) {
            window.dataLayer = [];
        }

        if (Array.isArray(window.dataLayer)) {
            window.dataLayer.push({ ecommerce: null });
            window.dataLayer.push({
                event: eventName,
                ...data
            });
        } else {
            console.warn("dataLayer is not an array, unable to push data");
        }
    }

}

export default GoogleTagManager;
