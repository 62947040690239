import * as yup from "yup";
import {validateUsername} from "../../core/constants/validate-username";

export const schemaShortBecomeCreator = yup.object({
    profileImage: yup
        .string()
        .optional(),

    backgroundProfileImage: yup
        .string()
        .optional(),

    firstName: yup
        .string()
        .required('First name is required')
        .min(2, 'First name must be at least 2 characters long')
        .max(50, 'First name cannot exceed 50 characters'),

    lastName: yup
        .string()
        .required('Last name is required')
        .min(2, 'Last name must be at least 2 characters long')
        .max(50, 'Last name cannot exceed 50 characters'),

    bio: yup
        .string()
        .optional()
        .max(255, 'Bio cannot exceed 255 characters'),

    longBio: yup
        .string()
        .max(500, 'Bio must be at most 500 characters'),

    facebookSocialHandle: yup
        .string()
        .max(255, 'Too long facebook link'),

    instagramSocialHandle: yup
        .string()
        .max(255, 'Too long instagram link'),

    linkedinSocialHandle: yup
        .string()
        .max(255, 'Too long linkedin link'),

    twitterSocialHandle: yup
        .string()
        .max(255, 'Too long twitter link'),

    youtubeSocialHandle: yup
        .string()
        .max(255, 'Too long youtube link'),
}).test('socialMedia', 'At least one social media link is required', (values) => {
    return !!(
        values.instagramSocialHandle?.trim() ||
        values.facebookSocialHandle?.trim() ||
        values.linkedinSocialHandle?.trim() ||
        values.twitterSocialHandle?.trim() ||
        values.youtubeSocialHandle?.trim()
    );
}).required()

