import {ContactType} from "../../core/models/enums/communications-type.enum";
import {ReactComponent as ChatIcon} from "@assets/icons/chat.svg";
import {ReactComponent as VoiceIcon} from "@assets/icons/audio.svg";
import {ReactComponent as VideoIcon} from "@assets/icons/video.svg";

export const renderIconBaseOffer = (type: ContactType) => {
    switch (type) {
        case ContactType.VIDEO_CALL:
            return <VideoIcon className="w-4 h-4"/>;
        case ContactType.CHAT:
            return <ChatIcon className="w-4 h-4"/>;
        case ContactType.CALL:
            return <VoiceIcon className="w-4 h-4"/>;
        default:
            return null;
    }
}
