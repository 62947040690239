import * as yup from "yup";
import {validateUsername} from "../../core/constants/validate-username";
import {schemaSubscriptionManager} from "../../subscriptions/constants/schema-subscription-manager";

export const schemaBecomeCreator = yup.object({
    firstName: yup
        .string()
        .required('First name is required')
        .min(2, 'First name must be at least 2 characters long')
        .max(50, 'First name cannot exceed 50 characters'),
    lastName: yup
        .string()
        .required('Last name is required')
        .min(2, 'Last name must be at least 2 characters long')
        .max(50, 'Last name cannot exceed 50 characters'),
    userName: validateUsername,
    bio: yup.string().optional().max(255, 'Bio cannot exceed 255 characters'),
    isTextMessages: yup.boolean().optional(),
    textMessagePrice: yup
        .number()
        .optional()
        .min(0, 'Price cannot be negative'),
    isVoiceMessages: yup.boolean().optional(),
    voiceMessagePrice: yup
        .number()
        .optional()
        .min(0, 'Price cannot be negative'),
    isVideoMessages: yup.boolean().optional(),
    videoMessagePrice: yup
        .number()
        .optional()
        .min(0, 'Price cannot be negative'),
    freeTextMessageBlock: yup
        .number()
        .optional()
        .min(0, 'Free blocks cannot be negative'),
    freeVoiceMessagesMin: yup
        .number()
        .optional()
        .min(0, 'Free minutes cannot be negative'),
    freeVideoMessagesMin: yup
        .number()
        .optional()
        .min(0, 'Free minutes cannot be negative'),
    profileImage: yup.string().optional(),
    backgroundProfileImage: yup.string().optional(),
    occupation: yup.string().optional().max(100, 'Occupation cannot exceed 100 characters'),
    completionDate: yup.date().optional(),
    projectDetails: yup.string().optional().max(1000, 'Project details cannot exceed 1000 characters'),
    facebookSocialHandle: yup
        .string()
        .max(255, 'Too long facebook link'),

    instagramSocialHandle: yup
        .string()
        .max(255, 'Too long instagram link'),

    linkedinSocialHandle: yup
        .string()
        .max(255, 'Too long linkedin link'),

    twitterSocialHandle: yup
        .string()
        .max(255, 'Too long twitter link'),

    youtubeSocialHandle: yup
        .string()
        .max(255, 'Too long youtube link'),
})
    .required()
    .concat(schemaSubscriptionManager);
