import SubmenuTab from "../../core/components/submenu-tab";
import {NavLink} from "react-router-dom";
import {CCToolsSubmenu} from "../constants/cc-tools-submenu";
import {useContext, useEffect, useRef} from "react";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import useEffectOnce from "../../common/hooks/use-effect-once";

const CCToolsMenu = () => {
    const baseLocation = "/app/cc-tools";
    const {products} = useContext(SessionCreatorToolsContext)!;
    const activeTabRef = useRef<HTMLDivElement | null>(null);

    const MappedSubmenu = CCToolsSubmenu
        .map(item => {
            if (item.href === "/digital-products") {
                item.badge = products.length || null;
            }
            return item;
        })

    useEffectOnce(()=>{
        if (activeTabRef.current) {
            activeTabRef.current.scrollIntoView({
                behavior: "auto",
                block: "nearest",
            });
        }
    })

    return (
        <div className='overflow-y-clip overflow-x-auto flex gap-2 cursor-pointer max-w-full'>
            {MappedSubmenu.map(item => (
                <NavLink
                    key={item.href}
                    to={baseLocation + item.href}
                    end={true}
                >
                    {({ isActive }) => (
                        <div
                            ref={isActive ? activeTabRef : null}
                        >
                            <SubmenuTab
                                badge={item.badge}
                                key={item.href}
                                text={item.text}
                                isActive={isActive}
                            />
                        </div>
                    )}
                </NavLink>
            ))}
        </div>
    );
}

export default CCToolsMenu
