import React, {useCallback, useState} from "react";
import {MagnifyingGlassIcon} from "@heroicons/react/24/solid";
import {ReactComponent as SearchIcon} from "@assets/icons/search.svg";
import {debounce} from "../../../utils/debounce";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import {classNames} from "../../../utils/class-names";

interface SearchInputProps<T> {
    onSearch: (query: string) => Promise<T[]> | void; // Function to fetch data based on the search query
    onResults?: (results: T[]) => void;         // Callback to emit search results
    onClear?: () => void;
    disableEmptySearch?: boolean;
    placeholder?: string;                      // Optional placeholder text
    debounceTime?: number;                     // Optional debounce time for search
    showClear?: boolean;
    disabled?: boolean;
}

const SearchInput = <T, >(props: SearchInputProps<T>) => {
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const {
        onSearch,
        onClear,
        showClear = true,
        onResults,
        placeholder = "Search...",
        debounceTime = 300,
        disableEmptySearch = false,
        disabled = false,
    } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleSearch = useCallback(
        debounce(async (searchTerm: string) => {
            if (!searchTerm.trim() && !disableEmptySearch) {
                onResults && onResults([]);
                return;
            }

            setLoading(true);
            try {
                const results = await onSearch(searchTerm);
                if (results && onResults) onResults(results);
            } catch (error) {
                console.error("Search error:", error);
                onResults && onResults([]);
            } finally {
                setLoading(false);
            }
        }, debounceTime),
        [onSearch, onResults, debounceTime]
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!disabled) {
            const searchTerm = event.target.value;
            setQuery(searchTerm);
            handleSearch(searchTerm);
        }
    };

    const handleClear = async () => {
        setQuery("");
        onResults && onResults([]);
        onClear && onClear();
        if (!disableEmptySearch) {
            await onSearch("");
        }
    };

    return (
        <div className="relative top-0 left-0 flex items-center w-full h-fit">
            <input
                type="text"
                value={query}
                onChange={handleChange}
                placeholder={placeholder}
                className={classNames(
                    "w-full rounded-2xl pl-8 pr-4 py-[10px] text-sm text-[#7A7A7A] focus:outline-none placeholder:text-[#7A7A7A] bg-gray-light",
                    showClear && "pr-9"
                )}
            />
            <SearchIcon className="absolute left-3 top-[12px] h-[14px] w-[14px] min-w-[14px] text-[#7A7A7A]"/>
            {query && showClear && (
                <CrossIcon
                    onClick={handleClear}
                    className="absolute right-3 top-[16px] h-[8px] w-[8px] min-w-[16px] text-[#7A7A7A] cursor-pointer hover:text-black"
                />
            )}
            {loading && <span className="absolute right-3 top-2.5 h-5 w-5 spinner-border animate-spin text-gray-400"/>}
        </div>
    );
};

export default SearchInput;
