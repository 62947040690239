import {PeriodSubscription} from "../../core/models/enums/period-subscription.enum";

export const getLabelButtonBuySubscription = (period: PeriodSubscription): string => {
    switch (period) {
        case PeriodSubscription.MONTHLY:
            return "Buy monthly subscription:";
        case PeriodSubscription.YEARLY:
            return "Buy yearly subscription:"
    }
}
