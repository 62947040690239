import {TypeDiscount} from "../../core/models/enums/type-discount.enum";
import {NumberFormattingService} from "../../common/services/number-formatting.service";

class YDollarsConversionService extends NumberFormattingService {
    private readonly YDollarsToCentsRate: number = 80;

    /**
     * Converts YDollars to payout dollars formatted as currency.
     * The conversion rate must be provided dynamically.
     *
     * @param {number | null} YDollars - The amount in YDollars.
     * @returns {string} The formatted payout amount in USD currency.
     */
    convertYDollarsToPayoutDollarsWithCurrency(YDollars: number | null): string {
        let dollars: number = 0;
        if (YDollars) {
            const totalCents: number = YDollars * this.YDollarsToCentsRate;
            dollars = totalCents / 100;
        }
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(dollars);
    }

    /**
     * Converts YDollars to payout dollars without currency formatting.
     * The conversion rate must be provided dynamically.
     * Supports rounding modes: `UP` (ceil), `DOWN` (floor), `NEAREST` (round to nearest).
     *
     * @param {number | null} YDollars - The amount in YDollars.
     * @param {'UP' | 'DOWN' | 'NEAREST'} [roundingMode='NEAREST'] - The rounding mode.
     * @returns {string} The formatted payout amount after conversion.
     */
    convertYDollarsToPayoutDollars(
        YDollars: number | null,
        roundingMode: 'UP' | 'DOWN' | 'NEAREST' = 'NEAREST'
    ): string {
        let dollars: number = 0;
        if (YDollars) {
            const totalCents: number = YDollars * this.YDollarsToCentsRate;
            dollars = totalCents / 100;

            switch (roundingMode) {
                case 'UP':
                    dollars = Math.ceil(dollars * 100) / 100;
                    break;
                case 'DOWN':
                    dollars = Math.floor(dollars * 100) / 100;
                    break;
                case 'NEAREST':
                    dollars = Math.round(dollars * 100) / 100;
                    break;
            }
        }
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(dollars);
    }

    /**
     * Calculates the discounted price of a product in YDollars and its equivalent payout value in real dollars.
     * The payout amount is calculated using a dynamic conversion rate.
     * Supports both percentage and fixed amount discounts and distributes the payment over multiple periods.
     *
     * @param {number} basePricePerPeriod - The base price per period (in YDollars).
     * @param {number} discount - The discount amount (percentage or YDollars).
     * @param {TypeDiscount} discountType - The discount type (`PERCENTAGE` for percentage-based or `PRICE` for fixed amount).
     * @param {number} periods - The number of periods (e.g., subscription months).
     * @returns {{
     *  total: { yDollars: string; payoutDollars: string };
     *  perPeriod: { yDollars: string; payoutDollars: string };
     * }} An object containing the total and per-period price in YDollars and their equivalent payout value.
     */
    calculateDiscountedPrice(
        basePricePerPeriod: number,
        discount: number,
        discountType: TypeDiscount,
        periods: number,
    ): {
        total: { yDollars: string; payoutDollars: string };
        perPeriod: { yDollars: string; payoutDollars: string };
    } {
        let totalDiscountedPrice: number;

        if (discountType === TypeDiscount.PERCENTAGE) {
            totalDiscountedPrice = basePricePerPeriod * periods * (1 - (discount / 100));
        } else if (discountType === TypeDiscount.PRICE) {
            totalDiscountedPrice = (basePricePerPeriod * periods) - discount;
        } else {
            totalDiscountedPrice = basePricePerPeriod * periods;
        }

        totalDiscountedPrice = Math.max(totalDiscountedPrice, 0);

        const perPeriodDiscountedPrice = totalDiscountedPrice / periods;

        const perPeriodYDollars = this.formatNumberWithSpaceSeparator(perPeriodDiscountedPrice);
        const totalYDollars = this.formatNumberWithSpaceSeparator(totalDiscountedPrice);

        const perPeriodPayoutDollars = this.convertYDollarsToPayoutDollars(perPeriodDiscountedPrice);
        const totalPayoutDollars = this.convertYDollarsToPayoutDollars(totalDiscountedPrice);

        return {
            total: { yDollars: totalYDollars, payoutDollars: totalPayoutDollars },
            perPeriod: { yDollars: perPeriodYDollars, payoutDollars: perPeriodPayoutDollars }
        };
    }
}

export default new YDollarsConversionService();
