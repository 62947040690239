import React from "react";
import DetailsProductSection from "./ui/details-product-section";
import FilesProductSection from "./ui/files-product-section";
import {
    DigitalProductDetails as IDigitalProductDetails
} from "../../core/models/interfaces/digital-product-details.interface";
import AppBackButton from "../../../layout/components/app-back-button";
import {classNames} from "../../../utils/class-names";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import Base64Helper from "../../../utils/base64-helper";

interface DigitalProductDetailsProps {
    product: IDigitalProductDetails | null;
    backEventPath?: string;
    customEventBack?: UniversalClickHandler;
}

const DigitalProductDetails = (props: DigitalProductDetailsProps) => {
    const {product, backEventPath, customEventBack} = props;
    const canDownloadFile = !!props.product?.owned;

    if (!product) return null;

    return (
        <article className="max-w-[816px] w-full mx-auto md:p-4 space-y-6 md:space-y-10">
            <header className="relative w-full aspect-[4/3] max-h-[400px] md:h-[400px] z-[4]">
                {product.productPicture.base64 && (
                    <img src={Base64Helper.addBase64Prefix(product.productPicture.base64)} alt='image'
                         className="z-[2] absolute inset-0 w-full h-full object-cover object-center md:rounded-2xl"/>
                )}
                <div className={classNames(
                    "z-[3] absolute inset-0 bg-gradient-to-t from-[rgba(32,33,33,1)] from-20%",
                    "via-[rgba(32,33,33,0.5)] via-30% to-[rgba(32,33,33,0.3)] to-90% flex flex-col md:flex-row gap-4",
                    "justify-between md:justify-start md:items-end pb-12 p-4 md:p-6 md:rounded-2xl pt-12 md:pt-0"
                )}>
                    <AppBackButton
                        custom={true}
                        path={backEventPath}
                        customEventBack={customEventBack}
                        className="!bg-[rgba(255,255,255,0.15)] shadow-md hover:!bg-[rgba(255,255,255,0.3)] border-0 !text-white"
                    />
                    <div>
                        <span className="uppercase text-[rgba(255,255,255,0.5)] text-[0.625rem] leading-3 tracking-[1px] font-semibold ">
                            {product.category.name ?? "uncategorized"}
                        </span>
                        <h1 className="text-white text-[2rem] leading-[2.4rem] font-bold">
                            {product.name}
                        </h1>
                    </div>
                </div>
            </header>

            <div className='relative md:static -top-12 z-[5] pt-6 p-4 md:p-0 space-y-4 rounded-t-[1.5rem] bg-white '>
                <p className="text-black text-base leading-6 font-medium break-words">
                    {product.description}
                </p>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-20 gap-y-4">
                    <DetailsProductSection product={product}/>
                    <FilesProductSection files={product.files} canDownloadFile={canDownloadFile}/>
                </div>
            </div>
        </article>
    );
}

export default DigitalProductDetails
