import {useContext} from "react";
import SearchInput from "../../common/components/search-input";
import {SubscriptionFilterContext} from "../contexts/subscription-filter-context";

interface PurchasedSubscriptionSearchBarProps {
    disabled?: boolean;
}

const PurchasedSubscriptionSearchBar = (props: PurchasedSubscriptionSearchBarProps) => {
    const {
        disabled = false,
    } = props
    const context = useContext(SubscriptionFilterContext);
    if (!context) return null;

    return (
        <SearchInput disabled={disabled} disableEmptySearch={false} onSearch={context.setSearchQuery} placeholder="Search" debounceTime={300}/>
    );
};

export default PurchasedSubscriptionSearchBar;
