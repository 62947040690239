import {useState} from 'react';
import BecomeCreatorApiService from "../services/become-creator-api.service";
import {CheckStatusPromotionRequest} from "../models/interfaces/check-status-promotion-request.interface";

export const useBecomeCreatorApi = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const sendRequestToBecomeCreator = async (formData: FormData): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await BecomeCreatorApiService.submitFormAndGoToPersonalExperiences(formData);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const sendCreatorBasicData = async (formData: FormData): Promise<void> => {
        setLoading(true);
        setError(null);
        try {
            return await BecomeCreatorApiService.sendCreatorBasicData(formData);
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const checkStatusPromotionRequest = async (): Promise<CheckStatusPromotionRequest> => {
        setLoading(true);
        setError(null);
        console.log('kappa')
        try {
            return await BecomeCreatorApiService.reloadSessionOnAcceptedPromotionRequest();
        } catch (err: any) {
            setError(err.message);
            throw err;
        } finally {
            setLoading(false);
        }
    }

    return {
        loading,
        error,
        sendRequestToBecomeCreator,
        checkStatusPromotionRequest,
        sendCreatorBasicData,
    };
};
