import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";
import {DigitalProductCategory} from "../../core/models/interfaces/digital-product-category.interface";

export const getUniqueCategoriesFromProducts = (products: DigitalProduct[]): DigitalProductCategory[] => {
    const categoryMap = new Map<string | null, DigitalProductCategory>();

    products.forEach((product) => {
        const category = product.category;
        if (category && !categoryMap.has(category.id)) {
            categoryMap.set(category.id, {
                id: category.id,
                name: category.name,
                ordinalNumber: category.ordinalNumber,
            });
        }
    });

    return Array.from(categoryMap.values());
}
