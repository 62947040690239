import React, {useEffect} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import useAuth from '../../auth/hooks/use-auth';
import {useBecomeCreatorApi} from "../../core/hooks/use-become-creator-api";
import Base64Helper from "../../../utils/base64-helper";
import {yupResolver} from "@hookform/resolvers/yup";
import {UniversalClickHandler} from "../../common/models/types/universal-click-handler.type";
import ProfileImageService from "../../core/db/services/profile-image-service";
import ProfileBackgroundImageService from "../../core/db/services/profile-background-image-service";
import StepOne from "../containers/step-one";
import AppButton from "../../common/components/app-button";
import {schemaShortBecomeCreator} from "../constants/schema-short-become-creator";
import WarningMessage from "../../common/components/warning-message";
import {AuthenticatedUser} from "../../auth/models/interfaces/authenticated-user.interface";

interface ShortBecomeCreatorFormData {
    profileImage: string;
    backgroundProfileImage: string;
    firstName: string;
    lastName: string;
    bio: string;
    longBio: string;
    instagramSocialHandle?: string;
    facebookSocialHandle?: string;
    linkedinSocialHandle?: string;
    twitterSocialHandle?: string;
    youtubeSocialHandle?: string;
}

interface ShortBecomeCreatorFormProps {
    onSuccess?: UniversalClickHandler<void, AuthenticatedUser>;
}

function ShortBecomeCreatorForm({onSuccess}: ShortBecomeCreatorFormProps) {
    const {currentUser, updateAuthenticationField} = useAuth();
    const {sendCreatorBasicData} = useBecomeCreatorApi();

    const methods = useForm<ShortBecomeCreatorFormData>({
        defaultValues: {
            profileImage: currentUser?.profileImage || '',
            backgroundProfileImage: currentUser?.backgroundProfileImage || '',
            firstName: currentUser?.firstname || '',
            lastName: currentUser?.lastname || '',
            bio: currentUser?.bio || '',
            longBio: currentUser?.longBio || '',
            instagramSocialHandle: '',
            facebookSocialHandle: '',
            linkedinSocialHandle: '',
            twitterSocialHandle: '',
            youtubeSocialHandle: '',
        },
        resolver: yupResolver(schemaShortBecomeCreator) as any,
        mode: 'onSubmit'
    });

    const hasAnySocialMedia = () => {
        const values = methods.getValues();
        return Boolean(
            values.instagramSocialHandle ||
            values.facebookSocialHandle ||
            values.linkedinSocialHandle ||
            values.twitterSocialHandle ||
            values.youtubeSocialHandle
        );
    };

    const values = methods.getValues()
    const [showError, setShowError] = React.useState(false);

    const saveRequest = async () => {

        if (!hasAnySocialMedia()) {
            setShowError(true);
        } else {
            setShowError(false);
        }

        await methods.handleSubmit(async (values) => {
            try {
                const formData = new FormData();
                formData.append('image', Base64Helper.base64ToBlob(values.profileImage)!);
                formData.append('backgroundProfileImage', Base64Helper.base64ToBlob(values.backgroundProfileImage)!);
                formData.append('request', new Blob([JSON.stringify({
                    firstName: values.firstName,
                    lastName: values.lastName,
                    bio: values.bio,
                    longBio: values.longBio,
                    instagramSocialHandle: values.instagramSocialHandle,
                    facebookSocialHandle: values.facebookSocialHandle,
                    linkedinSocialHandle: values.linkedinSocialHandle,
                    twitterSocialHandle: values.twitterSocialHandle,
                    youtubeSocialHandle: values.youtubeSocialHandle,
                })], {type: "application/json"}));

                await sendCreatorBasicData(formData);

                if (values.profileImage) {
                    await ProfileImageService.saveImage(Base64Helper.removeBase64Prefix(values.profileImage));
                }
                if (values.backgroundProfileImage) {
                    await ProfileBackgroundImageService.saveImage(Base64Helper.removeBase64Prefix(values.backgroundProfileImage));
                }

                const updatedUser: AuthenticatedUser = {
                    ...currentUser!,
                    pendingPromotion: true,
                    firstname: values.firstName,
                    lastname: values.lastName,
                    bio: values.bio,
                    longBio: values.longBio,
                    backgroundProfileImage: Base64Helper.removeBase64Prefix(values.backgroundProfileImage),
                    profileImage: Base64Helper.removeBase64Prefix(values.profileImage),
                }

                updateAuthenticationField("user", updatedUser);

                onSuccess?.(updatedUser);
            } catch (error) {
                console.error('Failed to submit become creator form:', error);
            }
        })();
    };

    useEffect(() => {
        if (methods.formState.isSubmitted){
            if (!hasAnySocialMedia()) {
                setShowError(true);
            } else {
                setShowError(false);
            }
        }
    }, [values.youtubeSocialHandle, values.twitterSocialHandle, values.linkedinSocialHandle, values.facebookSocialHandle, values.instagramSocialHandle]);

    useEffect(() => {
        if (currentUser && (currentUser?.profileImage || currentUser?.backgroundProfileImage)){
            methods.setValue("profileImage", currentUser.profileImage ?? "");
            methods.setValue("backgroundProfileImage", currentUser.backgroundProfileImage ?? "");
        }
    }, [currentUser]);

    return (
        <FormProvider {...methods}>
            <form onSubmit={(e) => e.preventDefault()}
                  className="flex justify-center w-full h-full min-h-full flex-grow p-4">
                <div className="w-full max-w-md space-y-6">
                    <StepOne
                        showPrevButton={false}
                        hideStepper={true}
                        nextStep={() => {
                        }}
                        prevStep={() => {
                        }}
                    />

                    {showError && (
                        <div className="mt-2 !px-2">
                            <WarningMessage
                                text="Please add at least one social media link to continue"
                                initStateShow={true}
                                shownClassName="flex !px-[0.675rem] !mx-0 items-center h-[32px] min-w-[32px] !bg-semantic-warning-pastel"
                                disableToggleShow={true}
                            />
                        </div>
                    )}

                    <div className="flex justify-end">
                        <AppButton
                            type="button"
                            onClick={saveRequest}
                            label="Finish"
                            loading={methods.formState.isSubmitting}
                            className="w-full text-white text-sm py-[9px] h-fit font-semibold bg-primary rounded-2xl"
                        />
                    </div>
                </div>
            </form>
        </FormProvider>
    );
}

export default ShortBecomeCreatorForm;
