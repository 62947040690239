import YDollarsConversionService from "../../purchases/services/ydollars-conversion.service";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";

class SubscriptionCalculatorService {
    /**
     * Calculates subscription earnings in dollars based on the monthly price in YCredits.
     * @param {number} monthlyPrice - The monthly subscription price in YCredits.
     * @returns {string} - The equivalent amount in dollars.
     */
    static calculateSubscriptionEarning(monthlyPrice: number): string {
        return YDollarsConversionService.convertYDollarsToPayoutDollars(monthlyPrice);
    }

    /**
     * Calculates the yearly subscription price after applying a discount.
     * @param {number} monthlyPrice - The monthly subscription price in YCredits.
     * @param {number} discount - The discount value.
     * @param {TypeDiscount} discountType - The type of discount (percentage or fixed amount).
     * @returns {string} - A formatted string displaying the yearly and monthly price after discount.
     */
    static calculateDiscountPrice(monthlyPrice: number, discount: number, discountType: TypeDiscount): string {
        const { total, perPeriod } = YDollarsConversionService.calculateDiscountedPrice(
            monthlyPrice,
            discount,
            discountType,
            12
        );

        return `$${total.yDollars} yearly ($${perPeriod.yDollars} monthly)`;
    }
}

export default SubscriptionCalculatorService;
