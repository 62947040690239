import {DragDropContext, Draggable, Droppable} from "@hello-pangea/dnd";
import React, {useState, useEffect} from "react";
import CategoryProductCard from "./category-product-card";
import {DigitalProductCategory} from "../../core/models/interfaces/digital-product-category.interface";
import {DigitalProduct} from "../../core/models/interfaces/digital-product.interface";

interface CategoriesListProps {
    products: DigitalProduct[];
    categories: DigitalProductCategory[];
    onChanges: (categories: DigitalProductCategory[]) => void;
}

const CategoriesList = ({categories,onChanges,products}: CategoriesListProps) => {
    const [localCategories, setLocalCategories] = useState(categories);

    useEffect(() => {
        setLocalCategories(categories);
    }, [categories]);

    const handleDragEnd = async (result: any) => {
        if (!result.destination) return;

        const reorderedCategories = Array.from(localCategories);
        const [movedItem] = reorderedCategories.splice(result.source.index, 1);
        reorderedCategories.splice(result.destination.index, 0, movedItem);

        const updatedCategories = reorderedCategories.map((category, index) => ({
            ...category,
            ordinalNumber: index + 1,
        }));

        setLocalCategories(updatedCategories);
        onChanges(updatedCategories);
    };

    const handleCategoryUpdate = (updatedCategory: DigitalProductCategory) => {
        const updatedCategories = localCategories.map((category) =>
            category.id === updatedCategory.id ? updatedCategory : category
        );
        setLocalCategories(updatedCategories);
        onChanges(updatedCategories);
    };

    const handleCategoryDelete = (categoryId: string) => {
        const updatedCategories = localCategories.filter((category) => category.id !== categoryId);
        setLocalCategories(updatedCategories);
        onChanges(updatedCategories);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="categories-list">
                {(provided) => (
                    <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2 !mt-2">
                        {localCategories
                            .sort((cat1, cat2) => cat1.ordinalNumber - cat2.ordinalNumber)
                            .map((category, index) => (
                                <Draggable key={category.id} draggableId={category.id!} index={index}>
                                    {(provided) => (
                                        <li
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <CategoryProductCard
                                                products={products}
                                                showDraggableIcon={true}
                                                category={category}
                                                onUpdate={handleCategoryUpdate}
                                                onDelete={handleCategoryDelete}
                                            />
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                        {provided.placeholder}
                    </ul>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default CategoriesList;
