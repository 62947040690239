import React from 'react';
import {ChannelHeaderProps} from 'stream-chat-react';
import {useChatPartnerInfo} from "../hooks/use-chat-partner-info";
import {useActiveChat} from "../hooks/use-active-chat";
import {useMediaQuery} from "react-responsive";
import {ReactComponent as ChevronLeftIcon} from "@assets/icons/chevron-left.svg";
import ShopButton from './ui/shop-button';
import {ReactComponent as RightIcon} from "@assets/icons/chevron-right.svg"
import Avatar from "./ui/avatar";
import {ReactComponent as ChatsIcon} from "@assets/icons/chats.svg";
import {ReactComponent as VideoIcon} from "@assets/icons/video.svg";
import {ReactComponent as AudioIcon} from "@assets/icons/audio.svg";
import LabelUserRole from "../../core/components/ui/label-user-role";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";

const CustomChannelHeader = (props: ChannelHeaderProps) => {
    const {userAvatar, userName, lastActiveAt} = useChatPartnerInfo();
    const {setActiveChannel, isChatWithCC, hasActiveSubscription, subscriptionStats} = useActiveChat(); // Pobranie danych z kontekstu
    const isDesktop = useMediaQuery({minWidth: 768});

    const goToCCProfile = () => {
        if (isChatWithCC) window.open(`/${userName}`, '_blank');
    }

    const goToCCShop = () => {
        if (isChatWithCC) window.open(`/${userName}/digital-products`, '_blank');
    }

    const goBackOnMobileHandler = () => setActiveChannel(null);

    return (
        <div className="custom-channel-header-wrapper @container">
            <div className={`custom-channel-header-left ${isChatWithCC && "cursor-pointer"}`} onClick={goToCCProfile}>
                <div className="flex flex-col gap-1">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <div onClick={goBackOnMobileHandler} className="custom-channel-header-back-button">
                                <ChevronLeftIcon className="custom-channel-header-chevron-icon"/>
                            </div>
                            <Avatar className='mr-4' image={{name: `Avatar ${userName}`, contentBase64: userAvatar}}
                                    size={isDesktop ? "lg" : "md"}
                                    isOnline={false}/>
                        </div>
                        <div className="custom-channel-header-user-info flex flex-col">
                            <div className="custom-channel-header-user-name">
                                {userName}
                                <RightIcon className="w-[4.69px] h-[8px] inline-block text-gray ml-2"/>
                            </div>
                            <div className="custom-channel-header-last-seen w-full md:w-fit space-x-1 relative top-[-5px] space-y-4">
                                <LabelUserRole role={isChatWithCC ? UserAuthority.CONTENT_CREATOR : UserAuthority.FAN}/>

                                <div className="inline-flex gap-x-5 gap-y-2 flex-wrap">
                                    <div className='inline-block'>
                                        {lastActiveAt === "Just now" ? "Online" : `Last seen: ${lastActiveAt}`}
                                    </div>

                                    {isChatWithCC && hasActiveSubscription && (
                                        <div
                                            className="font-medium text-xs text-special-gray gap-4 md:inline-flex !hidden md:@[32rem]:!inline-flex">
                                            <span className="flex items-center gap-1">
                                                <ChatsIcon className="inline w-[16px] h-[15px] min-w-[16px]"/>
                                                {subscriptionStats?.textMessageBlockUsed} / {subscriptionStats?.textMessageBlock}
                                            </span>
                                            <span className="flex items-center gap-1">
                                                <AudioIcon className="inline w-[15.79px] h-[14px] min-w-[15.79px]"/>
                                                {subscriptionStats?.voiceMessagesUsed} / {subscriptionStats?.voiceMessages} min.
                                            </span>
                                            <span className="flex items-center gap-1">
                                                <VideoIcon className="inline w-[14px] h-[10.18px] min-w-[14px]"/>
                                                {subscriptionStats?.videoMessagesUsed} / {subscriptionStats?.videoMessages} min.
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    {isChatWithCC && hasActiveSubscription && (
                        <div className="font-medium text-xs text-special-gray ml-9 md:ml-0 mt-2 gap-4 md:hidden !inline-flex md:@[32rem]:!hidden">
                            <span className="flex items-center gap-1">
                                <ChatsIcon className="inline w-[16px] h-[15px] min-w-[16px]"/>
                                {subscriptionStats?.textMessageBlockUsed} / {subscriptionStats?.textMessageBlock}
                            </span>
                            <span className="flex items-center gap-1">
                                <AudioIcon className="inline w-[15.79px] h-[14px] min-w-[15.79px]"/>
                                {subscriptionStats?.voiceMessagesUsed} / {subscriptionStats?.voiceMessages} min.
                            </span>
                            <span className="flex items-center gap-1">
                                <VideoIcon className="inline w-[14px] h-[10.18px] min-w-[14px]"/>
                                {subscriptionStats?.videoMessagesUsed} / {subscriptionStats?.videoMessages} min.
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <div className="custom-channel-header-buttons">
                {isChatWithCC && <ShopButton onClick={goToCCShop}/>}
            </div>
        </div>
    );
};

export default CustomChannelHeader;
