export const getWaveformData = async (audioBlob: Blob, samples: number = 100): Promise<number[]> => {
    const arrayBuffer: ArrayBuffer = await audioBlob.arrayBuffer();
    const audioContext: AudioContext = new AudioContext();
    const audioBuffer: AudioBuffer = await audioContext.decodeAudioData(arrayBuffer);

    const rawData: Float32Array = audioBuffer.getChannelData(0);
    const blockSize: number = Math.floor(rawData.length / samples);

    return new Array(samples).fill(0).map((_, i) => {
        const blockStart: number = i * blockSize;
        const sum: number = rawData.slice(blockStart, blockStart + blockSize).reduce((a, b) => a + Math.abs(b), 0);
        return sum / blockSize;
    });
};
