import {Channel, Chat, MessageInput, MessageList, useChatContext, Window} from "stream-chat-react";
import {useActiveChat} from "../../hooks/use-active-chat";
import {classNames} from "../../../../utils/class-names";
import CustomChannelHeader from "../custom-channel-header";
import CustomMessageInput from "../ui/custom-chat-input";
import customRenderMessages from "../custom-render-messages";
import customReactionOptions from "../custom-reaction-options";

const ActiveChat = () => {
    const {activeChannel} = useActiveChat();
    const {client} = useChatContext();

    return (
        <div className={classNames(
            `md:static flex-grow h-full w-full bg-gray-light md:p-4 rounded-none md:rounded-3xl overflow-hidden
            ${activeChannel && "absolute left-0 top-0 z-10 max-h-svh"}`
        )}>
            {activeChannel ? (
                <Chat
                    client={client}>
                    <Channel
                        reactionOptions={customReactionOptions}
                        channel={activeChannel}>
                        <Window>
                            <CustomChannelHeader/>
                            <MessageList renderMessages={customRenderMessages}/>
                            <MessageInput asyncMessagesMultiSendEnabled audioRecordingEnabled Input={CustomMessageInput}/>
                        </Window>
                    </Channel>
                </Chat>
            ) : (
                <div className="flex items-center justify-center h-full text-gray-500">
                    Select a chat to start messaging
                </div>
            )}
        </div>
    );
};

export default ActiveChat;
