import {ItemSubmenuPurchases} from "../models/interfaces/item-submenu-purchases.interface";

export const PurchasesSubmenu: Array<ItemSubmenuPurchases> = [
    // {
    //     text: "Products",
    //     href: "",
    //     badge: null,
    // },
    {
        text: "Subscriptions",
        href: "/subscriptions",
        badge: null,
    },
    // {
    //     text: "Subscriptions",
    //     href: "",
    //     badge: null,
    // },
]
