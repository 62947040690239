import React from "react";
import DefaultProfileImg from "../../../common/components/default-profile-img";
import {ShortDataImage} from "../../../common/models/interfaces/short-data-image.interface";

interface AvatarProps {
    size?: "md" | "lg";
    isOnline?: boolean;
    className?: string;
    image: ShortDataImage;
}

const Avatar = (props: AvatarProps) => {
    const {
        className,
        size = "md",
        isOnline = false,
        image
    } = props;

    const domainRegex = /^https:\/\/backend\.yurs\.app(\/.*)?$/;

    let normalizedSrc = image.contentBase64 || "";

    if (domainRegex.test(normalizedSrc)) {
        normalizedSrc = normalizedSrc.replace(domainRegex, "https://backend.yurs.io$1");
    }

    return (
        <div className="relative rounded-2xl h-full">
            <div className={`chat-avatar-wrapper chat-avatar-${size} ${className}`}>
                {normalizedSrc ? (
                    <img
                        src={normalizedSrc}
                        alt={`Avatar image - ${image?.name}`}
                    />
                ) : (
                    <div className="placeholder-img">
                        <DefaultProfileImg/>
                    </div>
                )}
                {isOnline && <div className="user-indicator"></div>}
            </div>
        </div>
    );
};

export default Avatar;
