import React, {useEffect, useRef} from 'react';
import {useFormContext} from "react-hook-form";
import SkeletonFormField from "./skeleton-form-field";
import { classNames } from '../../../../utils/class-names';

interface TextareaFormFieldProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
    name: string;
    label: string;
    className?: string;
    isLoading?: boolean;
    rows?: number;
    maxChar?: number;
    isRequired?: boolean,
    forceDisabled?: boolean,
    placeholder?: string,
    showCount?: boolean;
}

const TextareaFormField: React.FC<TextareaFormFieldProps> = (props: TextareaFormFieldProps) => {
    const {register, formState: {errors, isSubmitting}, watch} = useFormContext();
    const {
        name,
        label,
        className = '',
        isLoading,
        rows = 4,
        maxChar = 255,
        disabled,
        isRequired = false,
        forceDisabled = false,
        placeholder,
        showCount,
        ...rest
    } = props;
    const value = watch(name) || "";
    const containerRef = useRef<HTMLDivElement | null>(null);

    function adjustTextareaHeight() {
        const textarea = containerRef.current?.children[1] as HTMLTextAreaElement;
        if (textarea) {
            textarea.style.height = "auto";
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

    const handleInput = (e: any) => {
        const textarea = e.target;
        textarea.scrollIntoView({behavior: 'auto', block: 'nearest'});
    };

    useEffect(() => {
        adjustTextareaHeight();
    }, [value]);

    return (
        <div className="relative mb-2 w-full" ref={containerRef}>
            <label htmlFor={name} className={classNames(
                        "absolute block text-xs font-medium top-2 left-[14px]",
                        (forceDisabled || disabled) && "text-gray",
                    )}>
                        {label}
                        {isRequired && <span className='text-red-500'>*</span>}
                    </label>
            {isLoading
                ? <div className='pt-6 px-3'><SkeletonFormField/></div>
                : (
                    <textarea
                        id={name}
                        rows={rows}
                        onInput={handleInput}
                        className={`p-3 pt-6 placeholder:text-gray-500 text-gray-900 text-base leading-6 font-normal border-2 border-gray-light 
                            bg-white shadow-sm rounded-2xl focus:outline-none focus:ring-2  w-full  overflow-hidden resize-none
                            ${errors[name] || (value.length > maxChar) ? "border-red-500 focus:ring-red-500" : "border-gray-300 " +
                            "focus:ring-primary"} ${className}`}
                        placeholder={`${placeholder? placeholder : 'Write'}`}
                        {...register(name)}
                        disabled={isSubmitting || disabled}
                        maxLength={maxChar}
                        {...rest}
                    ></textarea>)
            }
            {errors[name] &&
                (<div className="flex flex-wrap justify-between flex-row-reverse">
                    {errors[name] && <p className="text-red-500 text-xs">{'' + errors[name]?.message}</p>}
                    <span className={`text-sm leading-5 font-normal ${
                        errors[name] || (value.length > maxChar) ? "text-red-500" : "text-gray-500 theme-dark:text-gray-50"
                    }`}
                    >
                    {value.length}/{maxChar}
                </span>
                </div>)
            }
            {showCount && 
            <div className="absolute top-[8px] right-[12px] text-right text-gray text-xs">
                {value.length || 0} / {maxChar}
            </div>
            }
            
        </div>
    );
};

export default TextareaFormField;
