import {useLocation, useNavigate} from "react-router-dom";
import NotificationService from "../../common/services/notification.service";
import {StatusPurchasedSubscription} from "../models/enums/status-purchased-subscription.enum";
import useEffectOnce from "../../common/hooks/use-effect-once";

export const useStatusPurchasedSubscriptionHandler = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const errorMessage = "Failed to complete the subscription purchase. Please try again.";
    const successMessage = "Your subscription has been successfully activated! 🎉";

    useEffectOnce(() => {
        if (location.search === StatusPurchasedSubscription.SUCCESS) {
            setTimeout(() => {
                NotificationService.success(successMessage);
                navigate(location.pathname, {replace: true});
            }, 1);
        }
        if (location.search === StatusPurchasedSubscription.ERROR) {
            setTimeout(() => {
                NotificationService.error(errorMessage);
                navigate(location.pathname, {replace: true});
            }, 1);
        }
    });
};
