import {createContext, ReactNode, useState} from "react";

interface AnnouncementContextType {
    announcement: string;
    setAnnouncement: (message: string) => void;
    clearAnnouncement: () => void;
}

export const AnnouncementContext = createContext<AnnouncementContextType | undefined>(undefined);

export const AnnouncementProvider = ({children}: { children: ReactNode }) => {
    const [announcement, setAnnouncement] = useState("");

    const clearAnnouncement = () => setAnnouncement("");

    return (
        <AnnouncementContext.Provider value={{announcement, setAnnouncement, clearAnnouncement}}>
            {children}
        </AnnouncementContext.Provider>
    );
};
