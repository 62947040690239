import * as yup from "yup";
import {TypeDiscount} from "../../core/models/enums/type-discount.enum";

export const schemaSubscriptionManager = yup.object({
    subscriptionEnabled:
        yup.boolean(),

    textFreeMessagesCount: yup
        .number()
        .required()
        .min(0, "Text message count must be at least 0"),

    voiceFreeMessagesCount: yup
        .number()
        .required()
        .min(0, "Voice message count must be at least 0"),

    videoFreeMessagesCount: yup
        .number()
        .required()
        .min(0, "Video message count must be at least 0"),

    monthlyPrice: yup
        .number()
        .required("Monthly price is required")
        .min(1, "Price must be at least 1"),

    yearlyPrice: yup
        .number()
        .default(0)
        .min(0, "Price must be at least 0")
        .test({
            name: "yearly-price-gt-monthly-price",
            message: "Yearly price cannot be less than or equal to monthly price",
            test: function (value) {
                const monthlyPrice = this.parent.monthlyPrice;
                const subscriptionEnabled = this.parent.subscriptionEnabled;
                const yearlyDiscountEnabled = this.parent.yearlyDiscountEnabled;
                if (!subscriptionEnabled || !yearlyDiscountEnabled) return true;

                if (value <= monthlyPrice) {
                    return this.createError({
                        path: "yearlyDiscount",
                        message: "Yearly discount is not allowed because the yearly price is not greater than the monthly price"
                    });
                }
                return true;
            }
        }),

    yearlyDiscountEnabled:
        yup.boolean(),

    yearlyDiscount: yup
        .number()
        .required("Yearly discount is required")
        .when("yearlyDiscountType", {
            is: TypeDiscount.PERCENTAGE,
            then: (schema) => schema
                .min(0, "Percentage discount must be at least 0")
                .max(100, "Percentage discount cannot exceed 100"),
        }),


    yearlyDiscountType: yup
        .mixed<TypeDiscount>()
        .oneOf(
            [TypeDiscount.PERCENTAGE, TypeDiscount.PRICE],
            "Yearly discount type must be either PERCENTAGE or PRICE"
        ),

}).required();
