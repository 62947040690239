import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import React, {useRef} from "react";
import PostForm from "../components/post-form";
import AppBackButton from "../../../layout/components/app-back-button";
import AppHeader from "../../../layout/app-header";
import PostFormToolsActions from "../components/post-form-tools-actions";
import {FormPostProvider} from "../contexts/form-post-provider";
import {useNavigate} from "react-router-dom";

const AddPostFormView = () => {
    const wrapperRef = useRef(null);
    const navigate = useNavigate();

    return (
        <>
            <AppHeader customContent={true}>
                <div
                    className='flex items-center font-bold text-2xl pt-10 lg:text-[40px] text-primary-darken whitespace-nowrap'>
                    <div className='mr-3 flex'><AppBackButton/></div>
                    Add post
                </div>
            </AppHeader>
            <AppContentWrapper
                ref={wrapperRef}
                className="p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-full space-y-8">
                <FormPostProvider callbackOnSuccessSubmit={() => {
                    navigate("../");
                }}>
                    <PostForm/>
                    <PostFormToolsActions parentRef={wrapperRef}/>
                </FormPostProvider>
            </AppContentWrapper>
        </>
    )
}

export default AddPostFormView
