import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
import {Channel as StreamChannel} from "stream-chat";
import {LocalStorageKeys} from "../../common/models/enums/local-storage-keys.enum";
import {useChatContext} from "stream-chat-react";
import StreamChatService from "../services/stream-chat.service";
import useAuth from "../../auth/hooks/use-auth";
import {useUserProfileApi} from "../../core/hooks/use-user-profile-api";
import {UserAuthority} from "../../auth/models/enums/user-authority.enum";
import {useStreamChat} from "../hooks/use-stream-chat-context";
import {MessageOptionsProvider} from "./message-options-context";
import LocalStorageService from "../../common/services/local-storage.service";
import {useSubscriptionApi} from "../../core/hooks/use-subscription-api";
import {
    hasActiveSubscriptionByActiveToDate
} from "../../subscriptions/helpers/has-active-subscription-by-active-to-date";
import {CreatorSubscriptionOffer} from "../../core/models/interfaces/creator-subscription-offer.interface"; // Zakładając, że masz ten model

interface ActiveChatContextType {
    activeChannel: StreamChannel | null;
    setActiveChannel: (channel: StreamChannel | null) => void;
    setActiveChannelById: (channelId: string) => Promise<void>;
    isChatWithCC: boolean | null;
    hasActiveSubscription: boolean | null;
    subscriptionStats: SubscriptionInfo | null;
}

interface SubscriptionInfo {
    voiceMessages: number;
    textMessageBlock: number;
    videoMessages: number;
    voiceMessagesUsed: number;
    textMessageBlockUsed: number;
    videoMessagesUsed: number;
};

export const ActiveChatContext = createContext<ActiveChatContextType | undefined>(undefined);

export const ActiveChatProvider = ({children}: { children: ReactNode }) => {
    const [activeChannel, setActiveChannel] = useState<StreamChannel | null>(null);
    const [isChatWithCC, setIsChatWithCC] = useState<boolean | null>(null);
    const [hasActiveSubscription, setHasActiveSubscription] = useState<boolean | null>(null);
    const [subscriptionStats, setSubscriptionStats] = useState<SubscriptionInfo | null>(null);

    const {client} = useChatContext();
    const {currentUser} = useAuth();
    const {checkTypeUser} = useUserProfileApi();
    const {setActiveChannelID} = useStreamChat();
    const {getCreatorSubscriptionOffer} = useSubscriptionApi();

    const handleSetActiveChannel = async (channel: StreamChannel | null) => {
        if (channel) {
            await channel.stopWatching();
            setActiveChannel(channel);
            saveActiveChannelForUser(currentUser?.id!, channel.id!);
            checkIfActiveChatUserIsCC(channel);
        } else {
            setIsChatWithCC(null);
            setActiveChannel(channel);
            saveActiveChannelForUser(currentUser?.id!, null);
        }
    };

    const saveActiveChannelForUser = (userId: string, channelId: string | null) => {
        const storedData = JSON.parse(localStorage.getItem(LocalStorageKeys.ACTIVE_CHAT_DATA) || '{}');
        if (channelId) {
            setActiveChannelID(channelId);
            storedData[userId] = channelId;
        } else {
            setActiveChannelID(null);
            setActiveChannel(null);
            delete storedData[userId];
        }
        LocalStorageService.save(LocalStorageKeys.ACTIVE_CHAT_DATA, JSON.stringify(storedData));
    };

    const getActiveChannelForUser = (userId: string): string | null => {
        const storedData = JSON.parse(LocalStorageService.get(LocalStorageKeys.ACTIVE_CHAT_DATA) || '{}');
        return storedData[userId] || null;
    };

    const checkIfActiveChatUserIsCC = useCallback(async (channel: StreamChannel) => {
        const loggedInUserId = currentUser?.id;
        const otherUser = Object.values(channel.state.members).find(
            member => member.user?.id !== loggedInUserId
        )?.user;

        if (otherUser) {
            await handleUserTypeAndSubscription(otherUser);
        } else {
            console.log("No other user found in channel.");
            resetSubscriptionInfo();
        }
    }, [currentUser?.id]);

    const handleUserTypeAndSubscription = async (otherUser: any) => {
        try {
            const typeUser = await checkTypeUser(otherUser.name ?? "");
            setIsChatWithCC(typeUser === UserAuthority.CONTENT_CREATOR);

            if (typeUser === UserAuthority.CONTENT_CREATOR) {
                await checkAndSetSubscription(otherUser);
            } else {
                resetSubscriptionInfo();
            }
        } catch (error) {
            console.error("Failed to check if user is CC or get subscription:", error);
            resetSubscriptionInfo();
        }
    };

    const checkAndSetSubscription = async (user: any): Promise<void> => {
        const subscription: CreatorSubscriptionOffer = await getCreatorSubscriptionOffer(user.id);
        if (subscription) {
            const hasActiveSubscription: boolean = hasActiveSubscriptionByActiveToDate(subscription.activeTo)
            setHasActiveSubscription(hasActiveSubscription);
            setSubscriptionStats({
                textMessageBlock: subscription.textMessageBlock ?? 0,
                videoMessages: subscription.videoMessages ?? 0,
                voiceMessages: subscription.voiceMessages ?? 0,
                voiceMessagesUsed: subscription.voiceMessagesUsed ?? 0,
                textMessageBlockUsed: subscription.textMessageBlockUsed ?? 0,
                videoMessagesUsed: subscription.videoMessagesUsed ?? 0
            });
        } else {
            setHasActiveSubscription(false);
            resetSubscriptionInfo();
        }
    };

    const resetSubscriptionInfo = () => {
        setHasActiveSubscription(false);
        setSubscriptionStats(null);
    };

    const setActiveChannelById = useCallback(async (channelId: string) => {
        if (client) {
            const channel = client.channel('messaging', channelId);
            await handleSetActiveChannel(channel);
        }
    }, [client, handleSetActiveChannel]);

    useEffect(() => {
        const initializeClientAndChannel = async () => {
            StreamChatService.onClientReady(async () => {
                const storedChannelId = getActiveChannelForUser(currentUser?.id!);
                if (storedChannelId && client) {
                    const channel = client.channel('messaging', storedChannelId);
                    await channel.watch();
                    await handleSetActiveChannel(channel);
                }
            });
        };

        initializeClientAndChannel();
    }, [client]);

    return (
        <ActiveChatContext.Provider
            value={{
                activeChannel,
                setActiveChannel: handleSetActiveChannel,
                isChatWithCC,
                setActiveChannelById,
                hasActiveSubscription,
                subscriptionStats
            }}>
            <MessageOptionsProvider>
                {children}
            </MessageOptionsProvider>
        </ActiveChatContext.Provider>
    );
};
