import {UserAuthority} from "../../../auth/models/enums/user-authority.enum";
import React from "react";

interface LabelUserRoleProps {
    role?: UserAuthority;
}

const LabelUserRole = (props: LabelUserRoleProps) => {
    const {role} = props

    return (
        <>
            {role === UserAuthority.CONTENT_CREATOR && (
                <span
                    translate="no"
                    className="text-[10px]  font-semibold bg-primary-pastel text-primary p-1 leading-none uppercase rounded-lg tracking-widest w-fit">
                    CREATOR
                </span>
            )}
            {role === UserAuthority.FAN && (
                <span
                    translate="no"
                    className="text-[10px] font-semibold bg-primary-pastel text-primary p-1 leading-none uppercase rounded-lg tracking-widest w-fit">
                    FAN
                </span>
            )}
            {role === UserAuthority.SUPPORT && (
                <span
                    translate="no"
                    className="text-[10px] font-semibold bg-primary-pastel text-primary p-1 leading-none uppercase rounded-lg tracking-widest w-fit">
                    SUPPORT
                </span>
            )}
        </>
    )
}

export default LabelUserRole
