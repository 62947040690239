import useAuth from "../features/auth/hooks/use-auth";
import React, {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";
import useGuardOnRegistrationSteps from "../features/core/hooks/use-guard-registration-steps";
import AppWrapper from "../layout/components/app-wrapper";
import Logo from "../features/core/components/ui/logo";
import VerifyEmailForm from "../features/auth/components/verify-email-form";
import LogoutButton from "../features/auth/components/logout-button";
import useRegistrationSteps from "../features/auth/hooks/use-registration-steps";
import AppContentWrapper from "../layout/components/app-content-wrapper";
import SkipStepVerification from "../features/core/components/ui/skip-step-verification";
import LegacyUsernameForm from "../features/auth/components/legacy-username-form";
import ShortBecomeCreatorForm from "../features/become-creator/views/short-become-creator-form";
import {RegistrationSteps} from "../features/core/models/enums/registration-steps.enum";

const VerificationUserPage = () => {
    const {currentUser} = useAuth();
    const hasCompletedIntroduction: boolean = useGuardOnRegistrationSteps(currentUser);
    const {currentStep, nextStep, canSkipStep, isLastStep, component} = useRegistrationSteps();
    const navigate = useNavigate();
    // const {isSelectedCC} = useContext(RegistrationContext)!
    // const {sendRegisterAccInfo} = useAnalytics();

    useEffect(() => {
        if (isLastStep) navigate("/");
    }, [currentStep]);

    useEffect(() => {
        if (currentStep === RegistrationSteps.INITIAL_REGISTRATION) {
            nextStep(currentUser!);
        }
    }, [currentStep, currentUser]);

    if (hasCompletedIntroduction) {
        return <Navigate to="/" replace/>;
    }

    const renderStep = () => {
        switch (component) {
            case 'VerifyEmailForm':
                return <VerifyEmailForm onSuccess={(updatedUser) => nextStep(updatedUser!)}/>;
            case 'BecomeCreatorForm':
                return <ShortBecomeCreatorForm onSuccess={(updatedUser) => nextStep(updatedUser!)}/>
            // return <BecomeCreatorForm
            //     placeForm="registration"
            //     onEnding={() => nextStep(currentUser!)}
            //     onSkipAll={() => nextStep(currentUser!)}
            // />;
            case 'LegacyUsernameForm':
                return <LegacyUsernameForm onSuccess={(updatedUser) => nextStep(updatedUser!)}/>;
            case 'LegacyEmailVerification':
                return <VerifyEmailForm onSuccess={(updatedUser) => nextStep(updatedUser!)}/>;
            default:
                return null;
        }
    };

    return (
        <AppWrapper>
            <div className='hidden md:flex flex-col justify-between w-full flex-grow overflow-y-auto p-4'>
                <div className='flex justify-between gap-3 w-full md:mb-4'>
                    <Logo type='complete' className='w-[6rem]'/>
                    {canSkipStep() ? (
                        <SkipStepVerification onClick={() => nextStep(currentUser!)}/>
                    ) : (
                        <LogoutButton/>
                    )}
                </div>
            </div>
            <AppContentWrapper className='max-h-full h-svh md:h-full'>
                {renderStep()}
            </AppContentWrapper>
        </AppWrapper>
    )
}

// export default withAnyProvider(RegistrationContextProvider, VerificationUserPage)
export default VerificationUserPage
