import Tooltip from "../../common/components/tooltip";
import AppButton from "../../common/components/app-button";
import {classNames} from "../../../utils/class-names";
import {subscriptionPeriodsMapEnum} from "../constants/subscription-periods-map-enum";
import React from "react";
import {SubscriptionPeriodMap} from "../models/interfaces/subscription-period-map";
import {PeriodSubscription} from "../../core/models/enums/period-subscription.enum";

interface SelectorPeriodSubscriptionProps {
    yearlyDiscountPercentage: number | null,
    yearlyDiscountPrice: number | null,
    selectedPeriod: PeriodSubscription,
    onSelect: (role: PeriodSubscription) => void;
}

const SelectorPeriodSubscription = (props: SelectorPeriodSubscriptionProps) => {
    const {
        selectedPeriod,
        onSelect,
        yearlyDiscountPercentage,
        yearlyDiscountPrice,
    } = props;

    const handleOnSelect = (period: PeriodSubscription) => {
        onSelect(period);
    }

    const mapLabelButton = (period: SubscriptionPeriodMap): string => {
        switch (period.value) {
            case PeriodSubscription.YEARLY:
                if (yearlyDiscountPercentage) return `Yearly (Save ${yearlyDiscountPercentage}%)`;
                else if (yearlyDiscountPrice) return `Yearly (Save ${yearlyDiscountPrice} yC)`;
                else return `Yearly`
            default:
                return period.label;
        }
    }

    return (
        <div className='flex flex-col gap-2'>
            <span className='flex gap-1'>
                <p className='font-semibold text-black text-base leading-[1.2rem]'>Payment period</p>
                <Tooltip classNameIcon='text-xs text-special-gray relative top-[4px]'/>
            </span>
            <div className='w-full p-1 flex bg-gray-light max-h-[40px] rounded-2xl h-[40px] mb-4 gap-1'>
                {subscriptionPeriodsMapEnum.map(period =>
                    <AppButton
                        key={period.value}
                        type='button'
                        onClick={() => handleOnSelect(period.value)}
                        className={classNames(
                            'w-full border-none h-full !p-0 text-special-gray !text-[0.8135rem] focus:outline-none',
                            (selectedPeriod === period.value) && "bg-white font-semibold !text-black drop-shadow-xl"
                        )}
                        label={mapLabelButton(period)}/>
                )}
            </div>
        </div>
    )
}

export default SelectorPeriodSubscription
