import React, { useContext, useMemo, useRef } from "react";
import { SubscriptionStatus } from "../models/enums/subscription-status.enum";
import { SubscriptionFilterContext } from "../contexts/subscription-filter-context";
import { classNames } from "../../../utils/class-names";
import { PurchasedSubscription } from "../../core/models/interfaces/purchased-subscription.interface";
import { getSubscriptionStatus } from "../helpers/get-subscription-status";

interface SubscriptionStatusSelectorProps {
    subscriptions: PurchasedSubscription[];
}

const SubscriptionStatusSelector = ({ subscriptions }: SubscriptionStatusSelectorProps) => {
    const { selectedStatus, setSelectedStatus } = useContext(SubscriptionFilterContext)!;
    const buttonRefs = useRef<Map<string, HTMLButtonElement>>(new Map());
    const containerRef = useRef<HTMLDivElement>(null);

    const statusCounts = useMemo(() => {
        return subscriptions.reduce((acc, sub) => {
            const { isExpires, isActive, isCanceled } = getSubscriptionStatus(sub);

            if (isActive) {
                acc[SubscriptionStatus.ACTIVE] = (acc[SubscriptionStatus.ACTIVE] || 0) + 1;
            }
            if (isExpires) {
                acc[SubscriptionStatus.EXPIRES] = (acc[SubscriptionStatus.EXPIRES] || 0) + 1;
            }
            if (isCanceled) {
                acc[SubscriptionStatus.CANCELED] = (acc[SubscriptionStatus.CANCELED] || 0) + 1;
            }

            return acc;
        }, {} as Record<SubscriptionStatus, number>);
    }, [subscriptions]);

    const visibleStatuses = useMemo(() => {
        return Object.values(SubscriptionStatus).filter((status) =>
            status === SubscriptionStatus.ACTIVE || statusCounts[status] > 0
        );
    }, [statusCounts]);

    const handleStatusChange = (status: SubscriptionStatus) => {
        setSelectedStatus(status);

        const button = buttonRefs.current.get(status);
        const container = containerRef.current;

        if (button && container) {
            const buttonLeft = button.offsetLeft;
            const buttonWidth = button.offsetWidth;
            const containerWidth = container.offsetWidth;

            const scrollTo = buttonLeft - containerWidth / 2 + buttonWidth / 2;
            container.scrollTo({ left: scrollTo, behavior: "smooth" });
        }
    };

    return (
        <div className="my-4 mt-2 h-full min-h-full select-none">
            <div
                className="flex items-center gap-2 min-h-full border-b-2 h-[40px] border-gray-light overflow-x-auto overflow-y-hidden"
                ref={containerRef}
            >
                {visibleStatuses.map((status) => (
                    <button
                        ref={(el) => {
                            if (el) buttonRefs.current.set(status, el);
                        }}
                        key={status}
                        onClick={() => handleStatusChange(status)}
                        className={classNames(
                            "relative top-[2px] flex items-center gap-2 p-1 text-sm font-semibold transition pb-2",
                            selectedStatus === status
                                ? "text-black border-b-2 border-black"
                                : "text-special-gray"
                        )}
                    >
                        <span className="first-letter:uppercase select-none first-letter:select-none whitespace-nowrap">
                            {status.charAt(0).toUpperCase() + status.slice(1)}
                        </span>
                        {statusCounts[status] > 0 && (
                            <span
                                className={classNames(
                                    "transition flex items-center justify-center w-5 h-5 rounded-full text-xs font-semibold",
                                    selectedStatus === status
                                        ? "text-white bg-black"
                                        : "bg-gray-light text-special-gray"
                                )}
                            >
                                {statusCounts[status]}
                            </span>
                        )}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default SubscriptionStatusSelector;
