import ApiService from "../../../axios/api.service";
import {ShortStatisticsSubscription} from "../models/interfaces/short-statistics-subscription.interface";

class StatisticsApiService extends ApiService {

    public async getShortStatisticsOwnSubscription(): Promise<ShortStatisticsSubscription> {
        return await this.get<ShortStatisticsSubscription>(`/subscription/statistics`, {
            errorMessage: "Error loading subscription statistics",
        }).catch((error) => {
            throw error;
        });
    }

}

export default new StatisticsApiService();
