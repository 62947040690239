import React, {useContext} from "react";
import AppButton from "../../common/components/app-button";
import CardDigitalProduct from "../components/card-digital-product";
import AppHamburgerMenu from "../../../layout/components/app-hamburger-menu";
import CCToolsMenu from "../components/cc-tools-menu";
import AppHeader from "../../../layout/app-header";
import AppContentWrapper from "../../../layout/components/app-content-wrapper";
import OwnerDigitalProductsEmpty from "../components/owner-digital-products-empty";
import {useNavigate} from "react-router-dom";
import {DefaultDigitalProductCategory} from "../../core/models/enums/digital-product-category.enum";
import Tooltip from "../../common/components/tooltip";
import {useDigitalProductFilter} from "../hooks/use-digital-product-filter.hook";
import {SessionCreatorToolsContext} from "../contexts/session-creator-tools-context";
import SelectorCategoryProduct from "../components/selector-category-product";
import SearchInput from "../../common/components/search-input";
import ButtonGoToSettingsProducts from "../components/button-go-to-settings-products";
import {SidebarIds} from "../../../layout/types/sidebar-ids.interface";
import AppSidebarWrapper from "../../../layout/components/app-sidebar-wrapper";
import OrderSettings from "../components/order-settings";


const ListDigitalProductsView = () => {
    const {products,productCategories} = useContext(SessionCreatorToolsContext)!
    const {filteredProducts, selectedCategory, setQueryTyped} = useDigitalProductFilter();
    const navigate = useNavigate();

    const goToFormProductHandler = () => navigate("../new-product")

    return (
        <>
            <AppHeader customContent={true}>
                <div className='flex justify-between flex-wrap gap-5'>
                    <div className='flex flex-col gap-5 w-full max-w-full'>
                        <div className='flex items-center w-full'>
                            <div className='mr-3'><AppHamburgerMenu/></div>
                            <span className='font-bold text-2xl lg:text-[40px] text-primary-darken whitespace-nowrap'>
                                Creator Tools
                            </span>
                        </div>
                        <CCToolsMenu/>
                    </div>
                </div>
            </AppHeader>
            <AppContentWrapper className='!overscroll-none p-4 bg-white rounded-t-[1.5rem] sm:pt-9 h-full'>
                {!products.length
                    ? <OwnerDigitalProductsEmpty/>
                    : (
                        <div className='flex flex-col gap-3 pb-6 max-w-full'>
                            <div className="flex gap-2">
                                <SearchInput onSearch={setQueryTyped} disableEmptySearch={false}/>
                                <ButtonGoToSettingsProducts/>
                            </div>

                            <SelectorCategoryProduct/>

                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                                {selectedCategory !== DefaultDigitalProductCategory.DELETED && (
                                    <AppButton
                                        onClick={goToFormProductHandler}
                                        label="+ Add product"
                                        className="flex items-center justify-center border border-dashed border-special-gray w-full h-[86.7px]
                                    text-special-gray font-semibold hover:border-primary hover:text-primary !rounded-3xl"
                                    />
                                )}
                                {selectedCategory === DefaultDigitalProductCategory.DELETED && (
                                    <div
                                        className="w-full sm:col-span-2 font-medium leading-[0.9rem] text-xs text-special-gray mb-2">
                                        <Tooltip classNameIcon="relative top-[2px] mr-1"/>
                                        These items will be deleted automatically after 30 days from the date of delete
                                    </div>
                                )}
                                {filteredProducts.map((product) => (
                                    <CardDigitalProduct key={product.id} product={product}/>)
                                )}
                            </div>
                        </div>
                    )
                }
            </AppContentWrapper>
            <AppSidebarWrapper
                id={SidebarIds.SETTINGS_DIGITAL_PRODUCTS}
                side='right'
                forceMobile={true}
                classNameSidebar="!max-w-[300px]"
                appendTo={document.body}>
                <OrderSettings/>
            </AppSidebarWrapper>
        </>
    );
}

export default ListDigitalProductsView
