import {DigitalProductCategory} from "../models/interfaces/digital-product-category.interface";
import {DefaultDigitalProductCategory} from "../../core/models/enums/digital-product-category.enum";

export const StaticDigitalProductCategories: Array<DigitalProductCategory> = [
    {
        name: "all",
        badge: null,
        showCategory: true,
        value: DefaultDigitalProductCategory.ALL,
        ordinalNumber: -2,
    },
    // {
    //     name: "uncategorized",
    //     badge: null,
    //     showCategory: true,
    //     value: DefaultDigitalProductCategory.UNCATEGORIZED,
    //     ordinalNumber: -1,
    // },
    {
        name: "deleted",
        badge: null,
        showCategory: true,
        value: DefaultDigitalProductCategory.DELETED,
        ordinalNumber: 999,
    },
];
