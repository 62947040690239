const FooterLinks = () => {

    const linkClassNames: string = "text-xs text-special-gray cursor-pointer hover:text-black focus:text-black"

    return (
        <footer className="flex flex-wrap gap-4 p-5 max-w-md mx-auto">
            <a className={linkClassNames} href="https://about.yurs.io/" target="_blank">About</a>
            <a className={linkClassNames} href="https://intercom.help/yurs/en/" target="_blank">Support</a>
            <a className={linkClassNames} href="https://about.yurs.io/blog/" target="_blank">Blog</a>
        </footer>
    )
}

export default FooterLinks
