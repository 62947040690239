import Tooltip from "../../features/common/components/tooltip";
import {ReactComponent as CrossIcon} from "@assets/icons/cross.svg";
import {useAnnouncement} from "../hooks/use-annoucement.hook";

const AppTopBarAnnouncement = () => {
    const {announcement, clearAnnouncement} = useAnnouncement();

    return (
        <aside id="announcement" className="bg-transparent">
            {announcement && (
                <div
                    className="relative bg-primary text-white lg:rounded-b-3xl lg:mx-4 py-4 px-8 flex gap-2 justify-center text-[0.9375rem] font-semibold leading-[1.125rem]">
                    <Tooltip classNameIcon="!text-white !w-4 !min-w-4 !h-4 top-[1px]"/>
                    {announcement}
                    <div onClick={clearAnnouncement}
                         className="absolute right-0 top-0 p-5 cursor-pointer hover:scale-125 transition">
                        <CrossIcon className="w-2 h-2 min-w-2"/>
                    </div>
                </div>
            )}
        </aside>
    )
}

export default AppTopBarAnnouncement
