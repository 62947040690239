import ApiService from "../../../axios/api.service";
import {PeriodSubscription} from "../models/enums/period-subscription.enum";
import {GetPurchasedSubscriptions} from "../models/interfaces/get-purchased-subscriptions.interface";
import {CreatorSubscriptionOffer} from "../models/interfaces/creator-subscription-offer.interface";
import {UpdateSubscriptionOfferRequest} from "../models/interfaces/update-subscription-offer-request.interface";
import {ConsequencesUpdateSubOffer} from "../models/interfaces/consequences-update-sub-offer.interface";
import {OwnSubscriptionOffer} from "../models/interfaces/own-subscription-offer.interface";

class SubscriptionApiService extends ApiService {

    // Aktualizacja oferty subskrypcji
    public async updateSubscriptionOffer(data: UpdateSubscriptionOfferRequest): Promise<void> {
        return await this.put<void>(`/subscription/offer`, data, {
            errorMessage: "Error updating subscription offer",
            successMessage: "Subscription offer updated successfully",
            skipDefault404Handling:true,
        }).catch((error) => {
            throw error;
        });
    }

    // Wyświetlanie konsekwencji edytowania oferty subskrypcji
    public async showConsequencesOfEditingOffer(data: UpdateSubscriptionOfferRequest): Promise<ConsequencesUpdateSubOffer> {
        return await this.post<ConsequencesUpdateSubOffer>(`/subscription/offer/consequences`, data, {
            params: data,
            errorMessage: "Error showing consequences of editing subscription offer",
            skipDefault404Handling:true,
        }).catch((error) => {
            throw error;
        });
    }

    // Pobieranie własnej oferty subskrypcji
    public async getOwnSubscriptionOffer(): Promise<OwnSubscriptionOffer> {
        return await this.get<OwnSubscriptionOffer>(`/subscription/offer`, {
            errorMessage: "Error loading subscription offer",
            skipDefault404Handling:true,
        }).catch((error) => {
            throw error;
        });
    }

    // Pobieranie oferty subskrypcji od twórcy
    public async getCreatorSubscriptionOffer(creatorId: string): Promise<CreatorSubscriptionOffer> {
        return await this.get<CreatorSubscriptionOffer>(`/subscription/${creatorId}/offer`, {
            errorMessage: "Error loading creator subscription offer",
            skipDefault404Handling:true,
        }).catch((error) => {
            throw error;
        });
    }

    // // Kupowanie subskrypcji
    // // Return transactionId
    // public async buySubscription(subscriptionId: string, period: PeriodSubscription): Promise<number> {
    //     return await this.post<number>(`/subscription/${subscriptionId}/buy`, {}, {
    //         params: {period},
    //         errorMessage: "Failed to complete the subscription purchase. Please try again.",
    //         successMessage: "Your subscription has been successfully activated! 🎉",
    //         skipDefault404Handling:true,
    //     }).catch((error) => {
    //         throw error;
    //     });
    // }

    // Anulowanie subskrypcji
    public async cancelSubscription(subscriptionId: string, fullNameSeller: string): Promise<void> {
        return await this.put<void>(`/subscription/${subscriptionId}/cancel`, {}, {
            errorMessage: `Something went wrong while canceling the subscription sold by ${fullNameSeller}'s`,
            successMessage: `${fullNameSeller}'s subscription was canceled successfully`,
            skipDefault404Handling: true,
        }).catch((error) => {
            throw error;
        });
    }

    // Przywracanie subskrypcji
    public async restoreSubscription(subscriptionId: string, fullNameSeller: string): Promise<void> {
        return await this.put<void>(`/subscription/${subscriptionId}/restore`, {}, {
            errorMessage: `Something went wrong while restoring the subscription sold by ${fullNameSeller}'s`,
            successMessage: `${fullNameSeller}'s subscription was restored successfully`,
            skipDefault404Handling: true,
        }).catch((error) => {
            throw error;
        });
    }

    // Pobranie zakupionych subskrypcji
    public async getPurchasedSubscriptions(): Promise<GetPurchasedSubscriptions> {
        return await this.get<GetPurchasedSubscriptions>(`/subscription/purchased`, {
            errorMessage: "Error loading purchased subscriptions",
            skipDefault404Handling:true,
        }).catch((error) => {
            throw error;
        });
    }
}

export default new SubscriptionApiService();
